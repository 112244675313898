import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, of, Subject, takeUntil } from 'rxjs';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DopaService {
  _cidData: any = null;
  _addressData: any = null;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  constructor(private httpClient: HttpClient,private httpClientService:HttpClientService) {}
  
  loginLinkageV2(params:any){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/Login";
    return this.httpClientService.post(URL,params,false);
  }

  loginConfirmLinkageV2(params:any){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/LoginConfirm";
    return this.httpClientService.post(URL,params,false);
  }

  logoutLinkageV2(){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/Logout";
    return this.httpClientService.deleteLinkage(URL,false);
  }

  getJob(){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/Job";
    return this.httpClientService.getLinkage(URL,false);
  }

  getRequest(params:any){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/Request";
    return this.httpClientService.postLinkage(URL,params,false);
  }

  getRenew(){
    const URL: string = environment.apiLKUrl + environment.apiLKVersion +"LinkageCenter/Renew";
    return this.httpClientService.postLinkage(URL,{},false);
  }



  login(params:any){
    const URL: string = `https://modhrmis.kbtdevops.com/assets/images/dopa/cid.json`;
    return this.httpClient.get(URL, { headers: this.headers });
  }

  getCid() {
    const URL: string = `https://modhrmis.kbtdevops.com/assets/images/dopa/cid.json`;
    return this.httpClient.get(URL, { headers: this.headers });
  }

  getAddress() {
    const URL: string = `https://modhrmis.kbtdevops.com/assets/images/dopa/address.json`;
    return this.httpClient.get(URL, { headers: this.headers });
  }

  getData() {
    return this.getCid().pipe(
      concatMap((resp: any) => {
        if (resp) {
          this._cidData = resp[0];
        }
        return this.getAddress();
      }),
      concatMap((resp: any) => {
        if (resp) {
          this._addressData = resp[0];
        }
        return of(null);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  public get cidData() {
    return this._cidData;
  }

  public get addressData() {
    return this._addressData;
  }

  resetData(){
    this._cidData = null;
    this._addressData = null;
  }
}
