import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VwAdHocPositionService {
  constructor(private httpClientService: HttpClientService) {}

  getVwAdHocPosition(mstAdhocPositionId: any) {
    const URL: string = environment.apiMstUrl + `VwAdHocPosition/${mstAdhocPositionId}`;
    return this.httpClientService.get(URL, false);
  }

  getOrdinal(mstOrgUnitId: any) {
    const URL: string = environment.apiMstUrl + `MstOrgUnit/ordinal/${mstOrgUnitId}`;
    return this.httpClientService.get(URL, false);
  }
}
