import {
  Directive,
  HostListener,
  EventEmitter,
  Output,
  HostBinding,
  Input,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Directive({ selector: '[appUploadFile]' })
export class UploadFileDirective {
  @Input('configUploadFile') configUploadFile: any;
  @Input('imageNumber') imageNumber: any;
  @Output('file') file: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.fileover') fileOver: boolean = false;

  fileData: any[] = [];
  _fileData: any = {
    data: '',
    type: '',
    errorText: '',
    // title: 'ไฟล์ขนาดไม่เกิน 4MB',
    isFromInit: false,
    filename: '',
    isAttachment: false,
  };

  constructor(
    private sanitizer: DomSanitizer,
    private elmentRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.addClass(
      this.elmentRef.nativeElement.parentElement,
      'fileover'
    );
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(
      this.elmentRef.nativeElement.parentElement,
      'fileover'
    );
  }

  @HostListener('drop', ['$event']) public async onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(
      this.elmentRef.nativeElement.parentElement,
      'fileover'
    );
    const files = evt.dataTransfer?.files;
    await this.init(files);
  }

  @HostListener('change', ['$event']) public async inputChanged(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.target.files;
    await this.init(files);
  }

  async init(files: any) {
    await this.reInitial();
    for (let items of files) {
      this._fileData = {
        // title: 'ไฟล์ขนาดไม่เกิน 4MB',
        data: '',
        type: '',
        errorText: '',
        isFromInit: false,
        filename: '',
        isAttachment: false,
        size: 0,
      };
      this._fileData.type = items.type;
      this._fileData.filename = items.name;
      this._fileData.size = items.size;
      if (items.size > this.configUploadFile.max_size) {
        this._fileData.errorText = this.configUploadFile.error_size;
        this.fileData.push(this._fileData);
        continue;
      }

      if (
        this.configUploadFile.isAllowType &&
        !_.includes(this.configUploadFile.allowed_type, items.type)
      ) {
        this._fileData.errorText = this.configUploadFile.error_type;
        this.fileData.push(this._fileData);
        continue;
      }

      if (
        this.configUploadFile.hasOwnProperty('type') &&
        this.configUploadFile.type == 'attach'
      ) {
        if (!_.includes(this.configUploadFile.images_type, items.type)) {
          this._fileData.isAttachment = true;
        }
      }

      const readFileAsync = await this.readFileAsync(items);
      readFileAsync;
    }
    this.file.emit(this.fileData);
  }

  async readFileAsync(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        this._fileData.data = reader.result as string;
        this._fileData.isFromInit = false;
        this.fileData.push(this._fileData);
        resolve(this.fileData);
      };
      reader.readAsDataURL(file);
    });
  }

  async reInitial() {
    this.fileData = [];
    this._fileData = {
      // title: 'ไฟล์ขนาดไม่เกิน 4MB',
      data: '',
      type: '',
      errorText: '',
      isFromInit: false,
      filename: '',
      isAttachment: false,
    };
  }
}
