import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/services/utils.service';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { OrderDraftTempService } from 'src/app/services/api/temp/order-draft-temp.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { DropdownAppService } from 'src/app/services/api/app/dropdown-app.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/api/app/shared.service';
import { ActivatedRoute } from '@angular/router';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Location } from '@angular/common';
import { ReportService } from 'src/app/services/api/report.service';
import { PreviewPdfDialogComponent } from 'src/app/modules/shared/preview-pdf-dialog/preview-pdf-dialog.component';
import { PermissionUserService } from 'src/app/core/services/permission-user.service';

@Component({
  selector: 'app-add-edit-draft-approve-order-detail',
  templateUrl: './add-edit-draft-approve-order-detail.component.html',
  styleUrls: ['./add-edit-draft-approve-order-detail.component.scss'],
})
export class AddEditDraftApproveOrderDetailComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  @ViewChild('pEditor') editor: any;
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  userProfileKey: any;
  applicationId: number | null = null;
  dataForm: FormGroup;
  modalAddListName: DynamicDialogRef | undefined;
  // @Output() sendNextStep = new EventEmitter<any>();
  dataSource: any = [];
  isStep: boolean = true;
  dataDetail: any = null;
  orderId: any = null;
  id: any = null;
  selectedData: any = [];
  modalPreviewPdf: DynamicDialogRef | undefined;
  transactionTypeList: any[] = [];
  transactionSubTypeList: any[] = [];
  orderSignedList: any[] = [];
  orderSubjectList: any[] = [];
  filterOrderSigned: any[] = [];
  today: Date = new Date();
  menuName: string = '';
  reportFileNamme: any = null;
  menuCode: any = null;
  permissionGiveIn: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public dynamicDialogRef: DynamicDialogRef,
    private utils: UtilsService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private sessionStorageService: SessionStorageService,
    private dropdownAppService: DropdownAppService,
    private sharedService: SharedService,
    private router: Router,
    private _location: Location,
    private orderDraftTempService: OrderDraftTempService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private permissionUserService: PermissionUserService
  ) {
    this.dataForm = this.formBuilder.group({
      adHocPositionShortName: [{ value: null, disabled: true }],
      allocateText: [null],
      commandDate: [null, Validators.required],
      commandText: [null, Validators.required],
      content: [null],
      description: [null],
      effectiveDate: [null],
      orderDate: [null],
      orderFrom: [null],
      orderNo: [{ value: null, disabled: true }],
      orderSetNo: [{ value: null, disabled: true }],
      orderSignedCopyName: [null],
      orderSignedCopyPosition: [null],
      orderSignedId: [{ value: null, disabled: true }],
      orderSignedName: [{ value: null, disabled: true }],
      orderSignedPosition: [null],
      orderSignedPrint: [null],
      orderStatusId: [3],
      orderStatusName: [null],
      orderSubjectId: [{ value: null, disabled: true }],
      orgUnitId: [null],
      remark: [null],
      step: [4],
      subject: [null],
      transactionSubTypeId: [{ value: null, disabled: true }],
      transactionTypeId: [{ value: null, disabled: true }],
    });
  }

  ngOnInit(): void {
    this.menuName = this.utils.getMenuName(this.router?.url);
    this.applicationId = this.utils.getApplicationCodeByRouter(
      this.router?.url
    );
    this.utils.spinnerShow();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.userProfileKey = this.sessionStorageService.getUserProfile()?.userKey;
    this.chainFunctionOnInit();
  }

  chainFunctionOnInit() {
    this.getDropdown()
      .pipe(
        concatMap(() => this.setDisplay()),
        concatMap(() => this.getTransactionSubType()),
        concatMap(() => this.getOderSubject()),
        concatMap(() => this.displayOrderDetail()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.setPermission();
          // this.getDataStampValue();
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  setPermission() {
    let url = this.router?.url.substring(1).split('/').slice(0, 2).join('/');
    this.menuCode = this.permissionUserService.getMenuCodeWithPath(url);
    this.permissionGiveIn = this.permissionUserService.getAllPermission(
      this.menuCode
    );
  }

  getDropdown() {
    return this.dropdownAppService
      .getTransactionTypeId(this.applicationId)
      .pipe(
        concatMap((resp: any) => {
          if (resp?.status == 200) {
            this.transactionTypeList = resp?.data;
          }
          return this.sharedService.getOrderSignedId(this.applicationId);
        }),
        concatMap((resp: any) => {
          if (resp?.data) {
            this.orderSignedList = resp.data;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

  setDisplay() {
    if (this.id == 'create') {
      return of(null);
    } else {
      return this.orderDraftTempService.getById(this.id).pipe(
        concatMap((resp: any) => {
          if (resp?.data) {
            this.dataDetail = resp?.data;
            this.orderId = resp?.data.orderId;
            this.dataForm.patchValue(resp?.data);
            this.dataForm
              .get('draftDate')
              ?.setValue(
                resp?.data?.draftDate ? new Date(resp?.data?.draftDate) : null
              );
            this.dataForm
              .get('transactionTypeId')
              ?.setValue(resp?.data?.transactionTypeId);
            this.dataForm
              .get('transactionSubTypeId')
              ?.setValue(resp?.data?.transactionSubTypeId);
            this.dataForm
              .get('orderSubjectId')
              ?.setValue(resp?.data?.orderSubjectId);
            this.dataForm.get('orderSetNo')?.setValue(resp?.data?.orderSetNo);
            this.dataForm.get('description')?.setValue(resp?.data?.description);
            this.dataForm.get('remark')?.setValue(resp?.data?.remark);
            // const findValueOrderSignedName = this.orderSignedList.find(
            //   (item: any) => item.fullName == resp?.data.orderSignedName
            // );
            // let dataSignedName = {
            //   fullName: resp?.data.orderSignedName,
            // };
            // this.dataForm
            //   .get('orderSignedName')
            //   ?.setValue(findValueOrderSignedName ?? dataSignedName);
            this.dataForm
              .get('orderStatusName')
              ?.setValue(resp?.data.orderStatusName);
            //solution setValue p-editor
            const quillEditor = this.editor.quill;
            quillEditor.root.innerHTML = resp?.data?.content;
          }
          return of(null);
        }),
        concatMap(()=>{
          this.handleOrderSigned()
          return of(null)
        }),
        catchError((err: any) => {
          throw err;
        })
      );
    }
  }

  displayOrderDetail() {
    if (this.orderId != null) {
      this.dataForm.get('transactionTypeId')?.disable();
      this.dataForm.get('transactionSubTypeId')?.disable();
      let params = {
        orderId: this.orderId,
        orderStatusId: parseInt(this.dataDetail.orderStatusId),
        applicationId: this.applicationId,
      };
      return this.orderDraftTempService
        .createOrderDetailListPerson(params)
        .pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.dataSource = resp?.data;
            }
            return of(resp);
          }),
          catchError((err: any) => {
            throw err;
          })
        );
    } else {
      return of(null);
    }
  }

  getTransactionSubType() {
    let transactionTypeId = this.dataForm.get('transactionTypeId')?.value;
    return this.dropdownAppService
      .getTransactionSubType(this.applicationId, transactionTypeId)
      .pipe(
        concatMap((resp: any) => {
          if (resp?.data) {
            this.transactionSubTypeList = resp?.data;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

  getOderSubject() {
    let param = {
      applicationId: this.applicationId,
      transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
      transactionSubTypeId:
        this.dataForm.get('transactionSubTypeId')?.value == 0
          ? null
          : this.dataForm.get('transactionSubTypeId')?.value,
    };
    return this.sharedService.getOderSubject(param).pipe(
      concatMap((resp: any) => {
        if (resp?.data) {
          this.orderSubjectList = resp?.data;
        }
        return of(resp);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  setOderSubject() {
    const valueOrderSubject = this.dataForm.get('orderSubjectId')?.value;
    const findValueOrderSubject = this.orderSubjectList.find(
      (item: any) => item.orderSubjectId == valueOrderSubject
    );
    this.dataForm.get('subject')?.setValue(findValueOrderSubject.subject);
    this.reportFileNamme = findValueOrderSubject?.reportFileName;
  }

  // getDataStampValue() {
  //   const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;
  //   this.dataForm
  //     .get('adHocPositionShortName')
  //     ?.setValue(valueOrderSigned?.adHocPositionShortName);
  //   this.dataForm.get('orderSignedName')?.setValue(valueOrderSigned?.fullName);
  // }

  // filterOrderSignedId(event: AutoCompleteCompleteEvent) {
  //   let filtered: any[] = [];
  //   let query = event.query;

  //   for (let i = 0; i < (this.orderSignedList as any[]).length; i++) {
  //     let country = (this.orderSignedList as any[])[i];
  //     if (country.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(country);
  //     }
  //   }

  //   this.filterOrderSigned = filtered;
  //   const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;
  //   let findOrderSigned = this.filterOrderSigned.find(
  //     (data: any) => data.fullName === valueOrderSigned
  //   );
  //   this.dataForm
  //     .get('adHocPositionShortName')
  //     ?.setValue(findOrderSigned?.adHocPositionShortName);
  //   if (!findOrderSigned) {
  //     this.dataForm.get('adHocPositionShortName')?.reset();
  //   }
  // }

  handleOrderSigned(){
    if(this.dataForm.get('orderSignedId')?.value){
      let orderSigned = this.orderSignedList.find((el:any)=>{
        return el.orderSignedId == this.dataForm.get('orderSignedId')?.value
      })
      this.dataForm.get('orderSignedName')?.setValue(orderSigned?.fullName ? orderSigned?.fullName : null)
      this.dataForm.get('adHocPositionShortName')?.setValue(orderSigned?.adHocPositionShortName ? orderSigned?.adHocPositionShortName : null)
    }else{
      this.dataForm.get('orderSignedName')?.reset()
      this.dataForm.get('adHocPositionShortName')?.reset()
    }
  }

  setParam() {
    let params = this.dataForm.getRawValue();
    params.orderId = parseInt(this.id);
    params.applicationId = this.applicationId;
    params.orderSignedPosition = this.dataForm.get(
      'adHocPositionShortName'
    )?.value;
    params.transactionTypeId = this.dataForm.get('transactionTypeId')?.value != null
      ? this.dataForm.get('transactionTypeId')?.value.toString()
      : null;
    params.step = this.dataForm.get('step')?.value?.toString();
    params.commandDate = this.dataForm.get('commandDate')?.value ? this.utils.convertFormatDateToParam(this.dataForm.get('commandDate')?.value) : null

    return params;
  }

  validateForm() {
    if (this.dataForm.invalid) {
      this.utils.alertBox('warning', 'กรุณาระบุข้อมูลให้ครบ', '');
      return true;
    }
    return false;
  }

  setParamForSaveOrderDetail(data: any) {
    this.orderId = data.orderId;
    let paramSave = [];
    let param = {
      orderId: data.orderId,
      transactionId: this.id,
      status: data.status,
      step: this.dataForm.get('step')?.value,
    };
    paramSave.push(param);
    return paramSave;
  }

  close(type = false) {
    this._location.back();
  }

  isApprove(): boolean {
    if (
      !this.dataForm.get('commandText')?.value ||
      !this.dataForm.get('commandDate')?.value
    ) {
      return true;
    } else {
      return false;
    }
  }

  approve() {
    let validateForm = this.validateForm();
    if (validateForm) {
      return;
    }
    const dataListName = this.dataSource.map((data: any) => ({
      transactionId: data.transactionId,
      transactionDetailId: data.transactionDetailId,
    }));
    let params = {
      orderId: this.orderId,
      commandText: this.dataForm.get('commandText')?.value,
      commandDate: this.dataForm.get('commandDate')?.value
        ? this.utils.convertFormatDateToParam(
            this.dataForm.get('commandDate')?.value
          )
        : null,
      orderApproveDetailRequest: dataListName,
    };
    this.utils
      .confirmBox$('question', 'คุณต้องการส่งอนุมัติข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        switchMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            return this.orderDraftTempService.approve(params);
          } else {
            return of(resp);
          }
        }),
        tap(async (resp: any) => {
          this.utils.spinnerHide();
          if (resp?.status == 200) {
            await this.utils.alertBox(`success`, `ส่งอนุมัติรายการสำเร็จ`, ``);
            this._location.back();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  reject() {
    let validateForm = this.validateForm();
    if (validateForm) {
      return;
    }
    const dataListName = this.dataSource.map((data: any) => ({
      transactionId: data.transactionId,
      transactionDetailId: data.transactionDetailId,
    }));
    let params = {
      orderId: this.orderId,
      commandText: this.dataForm.get('commandText')?.value,
      commandDate: this.dataForm.get('commandDate')?.value
        ? this.utils.convertFormatDateToParam(
            this.dataForm.get('commandDate')?.value
          )
        : null,
      orderApproveDetailRequest: dataListName,
    };

    this.utils
      .confirmBox$('question', 'คุณต้องการไม่อนุมัติข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        concatMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            return this.orderDraftTempService.reject(params);
          } else {
            return EMPTY;
          }
        }),
        tap(async () => {
          this.utils.spinnerHide();
          await this.utils.alertBox(`success`, `บันทึกรายการสำเร็จ`, ``);
          this._location.back();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  print() {
    this.setOderSubject();
    let params = this.setParam();
    this.utils
      .confirmBox$('question', 'คุณต้องการพิมพ์เอกสารใช่หรือไม่ ?', '')
      .pipe(
        concatMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            return this.orderDraftTempService.update(params);
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          if (resp) {
            this.setDisplay();
            const params = {
              report: {
                P_ORDERID: this.orderId?.toString(),
                P_ISCOPY: 'N',
                P_ISONLYONE: `${this.dataSource?.length === 1 ? 'Y' : 'N'}`,
                IsMod: 'ORDER',
              },
              reportName: this.reportFileNamme,
              // reportName: 'dischargeOrderSingleBook',
            };
            return this.reportService.getPdf(params);
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          if (resp) {
            const blob = new Blob([resp], { type: 'application/pdf' });
            this.utils.spinnerHide();
            this.openPreviewPdfDialog(blob);
          }
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  openPreviewPdfDialog(blob: any) {
    this.modalPreviewPdf = this.dialogService.open(PreviewPdfDialogComponent, {
      header: 'เอกสาร',
      width: '70%',
      height: '100%',
      contentStyle: { 'max-height': '100%', overflow: 'auto' },
      baseZIndex: 10000,
      data: { menu: blob },
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
