import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClientService,
    private sessionStorage: SessionStorageService
  ) {}

  login(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/Login`;
    return this.httpClient.post(URL, data, true);
  }

  logout(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/logout`;
    return this.httpClient.post(URL, data, true);
  }

  updatePassword(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/UpdatePassword`;
    return this.httpClient.post(URL, params, true);
  }

  resetPassword(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/ResetPassword`;
    return this.httpClient.post(URL, params, true);
  }

  updatePDPAConsented(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/UpdatePDPAConsented`;
    return this.httpClient.post(URL, params, true);
  }

  getMenuByRole(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/GetMenuByRole`;
    return this.httpClient.post(URL, params, false);
  }

  refreshToken() {
    const URL: string = environment.apiAuthUrl + `Auth/refresh`;
    let data = {
      hash: this.sessionStorage.getHash(),
      refreshToken: this.sessionStorage.getRefreshToken(),
      accessToken: this.sessionStorage.getAccessToken(),
    };
    return this.httpClient.post(URL, data, true).pipe(
      tap((resp: any) => {
        this.sessionStorage.setAccessToken(resp.data.accessToken);
        this.sessionStorage.setRefreshToken(resp.data.refreshToken);
        this.sessionStorage.setExpireToken(resp.data.accessTokenExpire);
      })
    );
  }

  checkOtp(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/CheckOtp`;
    return this.httpClient.post(URL, params, false);
  }

  confirmPassword(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/ConfirmPassword`;
    return this.httpClient.post(URL, params, false);
  }

  changePassword(params:any){
    const URL: string = environment.apiAuthUrl + `Auth/ChangePassword`;
    return this.httpClient.post(URL, params, false);
  }
}
