// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { SharedRoutingModule } from './shared-routing.module';
import { CoresModule } from 'src/app/core/cores.module';
import { PrimengModule } from './primeng.module';
import { AppConfigModule } from 'src/app/layout/config/app.config.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { FieldsetModule } from 'primeng/fieldset';
// Components
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { StepBarComponent } from './step-bar/step-bar.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PreviewPdfDialogComponent } from './preview-pdf-dialog/preview-pdf-dialog.component';
import { ModalOrgUnitComponent } from './modal-org-unit/modal-org-unit.component';
import { ModalPositionComponent } from './modal-position/modal-position.component';
import { ModalHistoryImportFileComponent } from './modal-history-import-file/modal-history-import-file.component';
import { ApproveOrderComponent } from './approve-order/approve-order.component';
import { DraftOrderComponent } from './draft-order/draft-order.component';
import { SharedOrderComponent } from './shared-order/shared-order.component';
import { AddEditSharedOrderDetailComponent } from './shared-order/add-edit-shared-order-detail/add-edit-shared-order-detail.component';
import { AddEditDraftApproveOrderDetailComponent } from './approve-order/add-edit-draft-approve-order-detail/add-edit-draft-approve-order-detail.component';
import { AddEditDraftOrderDetailComponent } from './draft-order/add-edit-draft-order-detail/add-edit-draft-order-detail.component';
import { AddPersonToDraftOrderComponent } from './draft-order/add-edit-draft-order-detail/add-person-to-draft-order/add-person-to-draft-order.component';
import { ModalSelectPersonComponent } from './modal-select-person/modal-select-person.component';
import { ModalSelectPersonMultiComponent } from './modal-select-person-multi/modal-select-person-multi.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { SharedVerifyPersonalResidenceComponent} from './shared-verify-personal-residence/verify-personal-residence.component';
@NgModule({
  declarations: [
    ConfirmDialogComponent,
    InfoDialogComponent,
    StepBarComponent,
    PersonDetailsComponent,
    PreviewPdfDialogComponent,
    ModalOrgUnitComponent,
    ModalPositionComponent,
    ModalHistoryImportFileComponent,
    ApproveOrderComponent,
    DraftOrderComponent,
    SharedOrderComponent,
    AddEditSharedOrderDetailComponent,
    AddEditDraftApproveOrderDetailComponent,
    AddEditDraftOrderDetailComponent,
    AddPersonToDraftOrderComponent,
    ModalSelectPersonComponent,
    ModalSelectPersonMultiComponent,
    SharedVerifyPersonalResidenceComponent
  ],
  imports: [
    CoresModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule,
    AppConfigModule,
    PrimengModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    AutoCompleteModule,
    EditorModule,
    PanelModule,
    TableModule,
    FieldsetModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppConfigModule,
    HttpClientModule,
    ConfirmDialogComponent,
    InfoDialogComponent,
    StepBarComponent,
    PersonDetailsComponent,
    PreviewPdfDialogComponent,
    ModalOrgUnitComponent,
    ModalSelectPersonComponent,
    ModalSelectPersonMultiComponent,
    SharedVerifyPersonalResidenceComponent
  ],
})
export class SharedModule {}
