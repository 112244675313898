import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmpEmployeeInfoService {
  private baseApiUrl = `${environment.apiEmp}EmployeeInfo`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  selectPersonalId = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  search(
    roleId : number = 0,
    id : number = 0,
    mstOrgUnitId: number | undefined,
    citizenId: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    searchBy: number | undefined,
    usePaging: boolean = true,
    pageIndex: number = 0,
    pageSize: number = 10,
    sortField: string | undefined,
    sortType: string | undefined,
  ): Observable<any> {
    let str = '?';

    if (roleId) str += `roleId=${roleId}&`;
    if (id) str += `id=${id}&`;
    if (mstOrgUnitId) str += `mstOrgUnitId=${mstOrgUnitId}&`;
    if (citizenId) str += `citizenId=${citizenId}&`;
    if (firstName) str += `firstName=${firstName}&`;
    if (lastName) str += `lastName=${lastName}&`;
    if (searchBy) str += `searchBy=${searchBy}&`;
    if (sortField) str += `sortField=${sortField}&`;
    if (sortType) str += `sortType=${sortType}&`;

    str += `&usePaging=${usePaging}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.httpClient.get<any>(`${this.baseApiUrl}${str}`, {
      headers: this.headers,
    });
  }

  getById(personalInfoId?: number): Observable<any> {
    let str = '?';

    if (personalInfoId) str += `id=${personalInfoId}&`;

    return this.httpClient.get<any>(`${this.baseApiUrl}/GetById${str}`, {
      headers: this.headers,
    });
  }

  getProfileById(personalInfoId?: number): Observable<any> {
    let str = '?';

    if (personalInfoId) str += `id=${personalInfoId}&`;

    return this.httpClient.get<any>(`${this.baseApiUrl}/GetProfileById${str}`, {
      headers: this.headers,
    });
  }

  create(data: any): Observable<any> {
    return this.httpClient.post<any>(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  update(data: any): Observable<any> {
    return this.httpClient.put<any>(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  delete(id: any): Observable<any> {
    return this.httpClient.delete(`${this.baseApiUrl}/${id}`, {
      headers: this.headers,
    });
  }

  deleteMany(ids: any[]): Observable<any> {
    const deleteRequests = ids.map((id) =>
      this.httpClient.delete(`${this.baseApiUrl}/${id}`, {
        headers: this.headers,
      })
    );
    return forkJoin(deleteRequests);
  }
}
