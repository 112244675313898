import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private httpClient: HttpClientService) { }

  getApplication(parentId:any) {
    const URL: string = environment.apiUsrUrl + `dropdown/application/${parentId}`;
    return this.httpClient.get(URL, false);
  }

  getParentMenu1(parentAppId:any) {
    const URL: string = environment.apiUsrUrl + `dropdown/menu/${parentAppId}`;
    return this.httpClient.get(URL, false);
  }

  getParentMenu2(parentAppId:any, appId?:any) {
    const URL: string = environment.apiUsrUrl + `dropdown/menu/${parentAppId}/${appId}`;
    return this.httpClient.get(URL, false);
  }

  getAccessLevel() {
    const URL: string = environment.apiUsrUrl + `dropdown/AccessLevel`;
    return this.httpClient.get(URL, false);
  }
  getStatus() {
    const URL: string = environment.apiUsrUrl + `dropdown/Status`;
    return this.httpClient.get(URL, false);
  }

  getAdHocPosition() {
    const URL: string = environment.apiOrdUrl + `Dropdown/AdHocPosition`;
    return this.httpClient.get(URL, false);
  }

  getOrderType() {
    const URL: string = environment.apiOrdUrl + `OrderType`;
    return this.httpClient.get(URL, false);
  }

  getOrderFrom() {
    const URL: string = environment.apiOrdUrl + `Dropdown/OrderFrom`;
    return this.httpClient.get(URL, false);
  }

  getOrderSignedId(id: any) {
    const URL: string = environment.apiOrdUrl + `Dropdown/OrderSigned/${id}`;
    return this.httpClient.get(URL, false);
  }

  getOderSubject(data: any) {
    const URL: string = environment.apiOrdUrl + `Dropdown/OrderSubject`;
    return this.httpClient.post(URL, data, false);
  }

  getTransactionName(param: any) {
    const URL: string = environment.apiOrdUrl + `Transaction/GetTransactionDetail`;
    return this.httpClient.post(URL, param,  false);
  }
}
