import { AppMenuitemComponent } from 'src/app/layout/app.menuitem.component';
import { PersonalInfoService } from 'src/app/services/api/per/personal-info.service';
import { AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from '../core/services/session-storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AppMenuitemComponent) appMenuitemComponent!: AppMenuitemComponent;
  model: any[] = [];
  destroy$ = new Subject();
  perMenus: any[] = [];
  mainMenu: any[] = [];

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.model = this.getMenus();
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.appMenuitemComponent.updateActiveStateFromRouterLink('/per');
    // }, 0);
  }

  getMenus() {
    let userProfile = this.sessionStorageService.getUserProfile();
    this.mainMenu = userProfile?.mainMenu;
    const menu = this.mainMenu.filter(t => t.menu?.length > 0 || t.routerLink).map((x) => {
      let menuLevel2: any;
      if (x.menu?.length > 0) {
        menuLevel2 = x.menu
          .filter((menu: any) => {
            return (
              menu.menuCode != 'PER2' &&
              menu.menuCode != 'PER3' &&
              menu.menuCode != 'PER4' &&
              menu.menuCode != 'PER5' &&
              menu.menuCode != 'PER6' &&
              menu.menuCode != 'PER7' &&
              menu.menuCode != 'PER8' &&
              menu.menuCode != 'PER9' &&
              menu.menuCode != 'PER10' &&
              menu.menuCode != 'PER11' &&
              menu.menuCode != 'PER12' &&
              menu.menuCode != 'PER13'
            )
          })
          .map((y: any) => {
            let menuLevel3: any;
            if (y.subMenu?.length > 0) {
              menuLevel3 = y.subMenu.map((z: any) => {
                let menuLevel4: any;
                if (z.thirdSubMenu?.length > 0) {
                  menuLevel4 = z.thirdSubMenu.map((a: any) => {
                    return {
                      label: a.menuName ? a.menuName : null,
                      icon: a.icon ? a.icon : null,
                      routerLink: [a.path ? a.path : null],
                    };
                  });
                }
                return {
                  label: z.menuName ? z.menuName : null,
                  icon: z.icon ? z.icon : null,
                  routerLink: [z.path ? z.path : null],
                  items: menuLevel4,
                };
              });
              return {
                label: y.menuName ? y.menuName : null,
                icon: y.icon ? y.icon : null,
                routerLink: [y.path ? y.path : null],
                items: menuLevel3,
              };
            } else {
              return {
                label: y.menuName ? y.menuName : null,
                icon: y.icon ? y.icon : null,
                routerLink: [y.path ? y.path : null],
              };
            }
          });
        return {
          label: x.applicationName ? x.applicationName : x.label,
          icon:  x.icon ? x.icon : 'fa-light fa-circle text-xs',
          items: menuLevel2 ? menuLevel2 : x.items,
        };
      }else{
        return {
          label: x.applicationName ? x.applicationName : x.label,
          icon: x.icon ? x.icon : 'fa-light fa-circle text-xs',
          routerLink: x.routerLink,
        };
      }
    });

    return [
      {
        title: '',
        items: menu,
      },
    ];
  }

  closeItem() {
    this.appMenuitemComponent.closeItem();
  }
}
