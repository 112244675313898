import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpClient: HttpClientService) {}

  search(data: any) {
    const URL: string = environment.apiAsmUrl + `Order/Search`;
    return this.httpClient.post(URL, data, false);
  }
}
