import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DraftOrderService {
  constructor(private httpClient: HttpClientService) {}

  createDraftOrder(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/AddOrder`;
    return this.httpClient.post(URL, params, false);
  }

  updateDraftOrder(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/UpdateOrder`;
    return this.httpClient.put(URL, params, false);
  }

  getOrderByOrderId(orderId: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/GetOrder/${orderId}`;
    return this.httpClient.get(URL, false);
  }

  // delete Search
  deleteDraftOrder(id: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/DeleteOrder`;
    return this.httpClient.post(URL, id, false);
  }

  getTransaction(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/GetTransaction`;
    return this.httpClient.post(URL, params, false);
  }

  createDraftOrderDetail(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/AddOrderDetail`;
    return this.httpClient.post(URL, params, false);
  }

  //delete work area
  deleteDraftOrderDetail(id: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/DeleteOrderDetail`;
    return this.httpClient.post(URL, id, false);
  }

  getListOrderDetail(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/GetListOrderDetail`;
    return this.httpClient.post(URL, params, false);
  }

  sendApproveOrder(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/SendApproveOrder`;
    return this.httpClient.put(URL, params, false);
  }

  updateStatusAndStep(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/UpdateStatusAndStep`;
    return this.httpClient.put(URL, params, false);
  }

  updateMilitaryIdInTransaction(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/UpdateMilitaryIdInTransaction`;
    return this.httpClient.put(URL, params, false);
  }

  updateStatusTransaction(params: any) {
    const URL: string = environment.apiAsmUrl + `DraftOrder/UpdateStatusTransaction`;
    return this.httpClient.put(URL, params, false);
  }
}
