<!-- <form [formGroup]="dataForm">
  <p-panel header="ค้นหาหน่วยงานและตำแหน่ง" [toggleable]="true" [collapseIcon]="'pi pi-chevron-up'"
    [expandIcon]="'pi pi-chevron-down'" styleClass="mb-3">
    <div class="grid formgrid p-fluid">
      <div class="field col-12 md:col-3">
        <label>ประเภทนายทหาร</label>
        <p-dropdown [options]="personnelGroupList" optionLabel="text" optionValue="value" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="personnelGroupId">
        </p-dropdown>
      </div>
      <div class="field col-12 md:col-3">
        <label>อัตรา</label>
        <p-dropdown [options]="positionRankList" optionLabel="text" optionValue="value" [filter]="true" filterBy="name"
          [showClear]="true" placeholder="เลือก" formControlName="positionRankId">
        </p-dropdown>
      </div>
      <div class="field col-12 md:col-3">
        <label>เลขที่ตำแหน่ง</label>
        <input pInputText formControlName="numberPosition" />
      </div>
      <div class="field col-12 md:col-3">
        <label>ขื่อตำแหน่ง</label>
        <input pInputText formControlName="namePosition" />
      </div>

      <div class="field col mb-0">
        <label>สถานะตำแหน่ง</label>
        <div class="flex gap-3">
          <div class="flex align-items-center gap-1">
            <p-checkbox [binary]="true" formControlName="statusPosition1" inputId="1"></p-checkbox>
            <label for="1">ว่าง</label>
          </div>
          <div class="flex align-items-center gap-1">
            <p-checkbox [binary]="true" formControlName="statusPosition2" inputId="2"></p-checkbox>
            <label for="2">จอง</label>
          </div>
          <div class="flex align-items-center gap-1">
            <p-checkbox [binary]="true" formControlName="statusPosition3" inputId="3"></p-checkbox>
            <label for="3">ทำหน้าที่</label>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="flex justify-content-end gap-3">
          <p-button label="ค้นหา" icon="fa-light fa-magnifying-glass" iconPos="left" styleClass="bg-purple-900 w-10rem"
            (onClick)="searchAction()"></p-button>
          <p-button label="ยกเลิก" icon="fa-light fa-x" iconPos="left" [outlined]="true" severity="danger"
            styleClass="w-10rem" (onClick)="cancel()"></p-button>
        </div>
      </div>
    </div>
  </p-panel>
</form> -->

<!-- <form [formGroup]="dataForm"> -->
<div class="grid formgrid p-fluid px-3">
  <!-- <div class="col-12">
      <h3 class="text-purple-900 mb-0">โครงสร้างหน่วยงานและตำแหน่ง</h3>
    </div> -->
  <div class="col-6">
    <!-- <div class="flex gap-3 mb-2">
      <p-button label="ขยายทั้งหมด" icon="fa-light fa-plus" iconPos="left" styleClass="mr-2 w-10rem"
        (onClick)="expandAll()" />
      <p-button label="ย่อทั้งหมด" icon="fa-light fa-minus" iconPos="left" styleClass="w-10rem"
        (onClick)="collapseAll()" />
    </div> -->
    <p-tree [value]="treeSource" class="w-full tree-fullwidth" styleClass="" selectionMode="single"
      [(selection)]="selected" [filter]="true" filterPlaceholder="ค้นหา" filterBy="key,label" [draggableNodes]="false"
      [droppableNodes]="false" draggableScope="self" droppableScope="self" (onNodeExpand)="nodeExpand($event)"
      (onNodeCollapse)="nodeCollapse($event)" (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)" scrollHeight="500px" [virtualScroll]="true" [virtualScrollItemSize]="46">
      <!-- <ng-template let-item pTemplate="url">
        <span>{{ item.key }}</span>
      </ng-template> -->
      <ng-template let-item pTemplate="default">
        <div class="flex align-items-center justify-content-between">
          <span [ngClass]="{'line-through': item?.data?.isActive != 1}">{{ item?.label }}</span>
          <!-- <p-button icon="fa-light fa-magnifying-glass" [rounded]="true" size="small"
            styleClass="bg-purple-900 text-right" (onClick)="openModalPosition(item.key)" /> -->
        </div>
      </ng-template>
    </p-tree>
  </div>

  <div class="col-6">

    <div class="col-12">
      <h4 class="text-purple-900 mb-2">ข้อมูลผู้ดำรงตำแหน่ง</h4>
      <p-table [value]="personalInfoSource" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr class="white-space-nowrap">
            <th>
              <span>ชื่อ-นามสกุล</span>
            </th>
            <th>
              <span>ตำแหน่ง</span>
            </th>
            <th>
              <span>อัตรา</span>
            </th>
            <th>
              <span>วันที่มีผล</span>
            </th>
            <th>
              <span>วันที่เกษียณ</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="white-space-nowrap">
            <td>{{data.fullName}}</td> <!-- ชื่อ-นามสกุล -->
            <td>{{data.positionName}}</td> <!-- ตำแหน่ง -->
            <td>{{data.rankNameRpt}}</td> <!-- อัตรา -->
            <td></td> <!-- วันที่มีผล -->
            <td></td> <!-- วันที่เกษียณ -->
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="personalInfoSource.length <= 0">
            <tr>
              <td colspan="5" class="text-left bg-white">
                ไม่มีข้อมูล
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>

    <div class="col-12 mt-5">
      <h4 class="text-purple-900 mb-2">ข้อมูลผู้ที่จองตำแหน่ง</h4>
      <p-table [value]="adhocPositionSource" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr class="white-space-nowrap">
            <th>
              <span>ชื่อ-นามสกุล</span>
            </th>
            <th>
              <span>ตำแหน่ง</span>
            </th>
            <th>
              <span>อัตรา</span>
            </th>
            <th>
              <span>ประเภทรายการ</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="white-space-nowrap">
            <td>{{data.fullName}}</td> <!-- ชื่อ-นามสกุล -->
            <td>{{data.adHocPositionShortName}}</td> <!-- ตำแหน่ง -->
            <td>{{data.positionRankName}}</td> <!-- อัตรา -->
            <td>{{data.transactionTypename}}</td> <!-- ประเภทรายการ -->
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="adhocPositionSource.length <= 0">
            <tr>
              <td colspan="4" class="text-left bg-white">
                ไม่มีข้อมูล
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>

    <!-- ตำแหน่ง -->
    <!-- <ng-container *ngIf="treeSelectedType == 1">
      <form [formGroup]="positionRankForm">
        <div class="field col-12">
          <label>เลขที่ตำแหน่ง</label>
          <input pInputText formControlName="form1" />
        </div>
        <div class="field col-12">
          <label>รหัสตำแหน่ง อฉก.</label>
          <input pInputText formControlName="form2" />
        </div>
        <div class="field col-12">
          <label>ตำแหน่ง</label>
          <p-dropdown [options]="positionList" optionLabel="text" optionValue="value" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="เลือก" formControlName="form3"
            [virtualScroll]="positionList.length > 6 ? true : false" [virtualScrollItemSize]="30" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>อัตรา</label>
          <p-dropdown [options]="positionRankList" optionLabel="text" optionValue="value" [filter]="true"
            filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="form4" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>ตำแหน่งสายวิทยาการ</label>
          <p-dropdown [options]="positionTechnicalList" optionLabel="text" optionValue="value" [filter]="true"
            filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="form5" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>เงินประจำตำแหน่ง</label>
          <p-dropdown [options]="salaryPositionDetailList" optionLabel="text" optionValue="value" [filter]="true"
            filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="form6" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>หมายเหตุตำแหน่ง</label>
          <p-dropdown [options]="positionRemarkList" optionLabel="text" optionValue="value" [filter]="true"
            filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="form7" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <div class="flex flex-wrap gap-3">
            <label>อัตราเหล่าแพทย์</label>
            <div class="flex align-items-center">
              <p-radioButton [value]="true" inputId="form8_1" formControlName="form8"></p-radioButton>
              <label for="form8_1" class="ml-2">ใช่</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton [value]="false" inputId="form8_2" formControlName="form8"></p-radioButton>
              <label for="form8_2" class="ml-2">ไม่ใช่</label>
            </div>
          </div>
        </div>
        <div class="field col-12">
          <label>ลำดับอัตรา</label>
          <input pInputText formControlName="form9" />
        </div>
        <div class="field col-12">
          <label>ประเภทข้าราชการ</label>
          <p-dropdown [options]="officerTypeList" optionLabel="text" optionValue="value" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="เลือก" formControlName="form10" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>ประเภทงาน/ระดับ</label>
          <p-dropdown [options]="employeeJobGroupList" optionLabel="text" optionValue="value" [filter]="true"
            filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="form11" appendTo="body">
          </p-dropdown>
          <div class="mt-2">
            <p-checkbox [binary]="true" formControlName="form12" inputId="form12" class="mr-2"></p-checkbox>
            <label for="form12">อัตราเพื่อพลาง</label>
          </div>
        </div>
        <div class="field col-12">
          <div class="flex flex-wrap gap-3">
            <label>สถานะ</label>
            <div class="flex align-items-center">
              <p-radioButton [value]="true" inputId="form13_1" formControlName="form13"></p-radioButton>
              <label for="form13_1" class="ml-2">ใช้งาน</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton [value]="false" inputId="form13_2" formControlName="form13"></p-radioButton>
              <label for="form13_2" class="ml-2">ไม่ใช้งาน</label>
            </div>
          </div>
        </div>
        <div class="field col-12">
          <label>ตำแหน่งย่อ</label>
          <textarea rows="3" pInputTextarea class="w-full" formControlName="form14"></textarea>
          <input pInputText formControlName="form15" class="" readonly />
          <input pInputText formControlName="form16" class="mt-1" readonly />
          <input pInputText formControlName="form17" class="mt-1" readonly />
        </div>
        <div class="field col-12">
          <label>ตำแหน่งเต็ม</label>
          <textarea rows="3" pInputTextarea class="w-full" formControlName="form18"></textarea>
          <input pInputText formControlName="form19" class="" readonly />
          <input pInputText formControlName="form20" class="mt-1" readonly />
          <input pInputText formControlName="form21" class="mt-1" readonly />
        </div>
        <div class="field col-12">
          <label>หมายเหตุ</label>
          <textarea rows="3" pInputTextarea class="w-full" formControlName="form22"></textarea>
        </div>
      </form>
    </ng-container> -->

    <!-- หน่วยงาน -->
    <!-- <ng-container *ngIf="treeSelectedType == 2">
      <form [formGroup]="positionUnitForm">
        <div class="field col-12">
          <label>ประเภทหน่วยงาน</label>
          <p-dropdown [options]="orgUnitTypeList" optionLabel="text" optionValue="value" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="เลือก" formControlName="form1" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <label>รหัสหน่วยงาน</label>
          <input pInputText formControlName="form2" />
        </div>
        <div class="field col-12">
          <label>ชื่อเต็มหน่วยงาน</label>
          <input pInputText formControlName="form3" />
        </div>
        <div class="field col-12">
          <label>ชื่อย่อหน่วยงาน</label>
          <input pInputText formControlName="form4" />
        </div>
        <div class="field col-12">
          <label>ลำดับหน่วยงาน</label>
          <input pInputText formControlName="form5" />
        </div>
        <div class="field col-12">
          <label>เหล่าทัพ</label>
          <p-dropdown [options]="armedForceList" optionLabel="text" optionValue="value" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="เลือก" formControlName="form6" appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12">
          <div class="flex flex-wrap gap-3">
            <label>สถานะ</label>
            <div class="flex align-items-center">
              <p-radioButton [value]="true" inputId="form7_1" formControlName="form7"></p-radioButton>
              <label for="form7_1" class="ml-2">ใช้งาน</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton [value]="false" inputId="form7_2" formControlName="form7"></p-radioButton>
              <label for="form7_2" class="ml-2">ไม่ใช้งาน</label>
            </div>
          </div>
        </div>
      </form>
    </ng-container> -->

  </div>
</div>

<!-- <ng-container *ngIf="treeSelectedType == 1">
  <div class="grid formgrid p-fluid justify-content-end">
    <div class="col-8">
      <p-fieldset>
        <ng-template pTemplate="header">
          <div class="flex align-items-center text-primary">
            <span class="font-bold text-lg">การบรรจุ</span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex mb-3">
            <span class="col-3 text-right">ยศ :</span>
            <span class="col"></span>
          </div>
          <div class="flex mb-3">
            <span class="col-3 text-right">ชื่อ - นามสกุล :</span>
            <span class="col"></span>
          </div>
          <div class="flex">
            <span class="col-3 text-right">ตำแหน่ง :</span>
            <span class="col"></span>
          </div>
        </ng-template>
      </p-fieldset>
    </div>
  </div>
</ng-container> -->

<!-- </form> -->

<div class="footer py-3">
  <p-button label="เลือก" icon="fa-light fa-check" iconPos="left" styleClass="bg-purple-900 w-10rem mr-3"
    (onClick)="choose()" [disabled]="adhocPositionSource.length > 0 || disableButtonChoose ? true : false">
  </p-button>
  <p-button label="ปิด" icon="fa-light fa-x" iconPos="left" [outlined]="true" severity="danger" styleClass="w-10rem"
    (onClick)="close()"></p-button>
</div>
