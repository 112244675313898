import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderDraftTempService {
  constructor(private httpClient: HttpClientService) {}

  search(data: any) {
    const URL: string = environment.apiOrdUrl + `Order/Search`;
    return this.httpClient.post(URL, data, false);
  }

  delete(params: any) {
    const URL: string = environment.apiOrdUrl + `Order/Delete`;
    return this.httpClient.post(URL, params, false);
  }

  create(params: any) {
    const URL: string = environment.apiOrdUrl + `Order`;
    return this.httpClient.post(URL, params, false);
  }

  getById(id: any) {
    const URL: string = environment.apiOrdUrl + `Order/${id}`;
    return this.httpClient.get(URL, false);
  }

  update(params: any) {
    const URL: string = environment.apiOrdUrl + `Order`;
    return this.httpClient.put(URL, params, false);
  }

  getTransactionName(data: any) {
    let URL: string = environment.apiOrdUrl + `Dropdown/Transaction/${data.ApplicationId}/${data.transactionTypeId}`;
    if(data.transactionSubTypeId != null && data.transactionSubTypeId != 0){
      URL = URL+`/${data.transactionSubTypeId}`
    }
    return this.httpClient.get(URL, false);
  }

  createOrderDetailList(params: any) {
    const URL: string = environment.apiOrdUrl + `OrderDetail/List`;
    return this.httpClient.post(URL, params, false);
  }

  createOrderDetailListPerson(params: any) {
    const URL: string = environment.apiOrdUrl + `OrderDetail/ListPerson`;
    return this.httpClient.post(URL, params, false);
  }

  getOrderDetail(orderId:any){
    const URL: string = environment.apiOrdUrl + `OrderDetail/${orderId}`;
    return this.httpClient.get(URL, false);
  }

  deleteOrderDetailList(params: any) {
    const URL: string = environment.apiOrdUrl + `OrderDetail/Delete`;
    return this.httpClient.post(URL, params, false);
  }

  updateApprove(params: any) {
    const URL: string = environment.apiOrdUrl + `Order/SendToApprove`;
    return this.httpClient.put(URL, params, false);
  }

  approve(params: any) {
    const URL: string = environment.apiOrdUrl + `Order/Approve`;
    return this.httpClient.put(URL, params, false);
  }

  reject(params: any) {
    const URL: string = environment.apiOrdUrl + `Order/Reject`;
    return this.httpClient.put(URL, params, false);
  }

  searchHistory(params:any){
    const URL: string = environment.apiOrdUrl + `Order/SearchHistory`;
    return this.httpClient.post(URL, params, false);
  }
}
