import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { concatMap , of} from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AuthService } from 'src/app/services/api/auth.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  dataForm:FormGroup;
  submitted:boolean = false;
  constructor(
    private formBuilder:FormBuilder,
    private dynamicDialogRef:DynamicDialogRef,
    private utils:UtilsService,
    private security: SecurityService,
    private authService:AuthService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ){
    this.dataForm = this.formBuilder.group({
      oldPassword:[null,Validators.required],
      newPassword:[null,Validators.required],
      confirmNewPassword:[null,Validators.required],
    })
  }

  save(){
    this.submitted = true;
    if(this.dataForm.invalid || (this.dataForm.get("newPassword")?.value != this.dataForm.get("confirmNewPassword")?.value)){
      this.utils.alertBox('warning', 'กรุณาระบุข้อมูลให้ถูกต้อง', '');
      return;
    }

    let oldPassword = this.security.encryptAES128(
      this.dataForm.get('oldPassword')?.value
    );
    let newPassword = this.security.encryptAES128(
      this.dataForm.get('newPassword')?.value
    );

    let params = {
      oldPassword: oldPassword,
      newPassword:newPassword
    }

    this.utils.spinnerShow();
    this.authService.changePassword(params)
    .pipe(
      concatMap((resp:any)=>{
        if(resp?.status == 200){
          this.utils.alertBox("success",resp?.message,"");
          this.dynamicDialogRef.close();
        }
        return of(null);
      })
    ).subscribe({
      next:()=>{  
        this.utils.spinnerHide();
      },
      error:(err:any)=>{
        this.utils.spinnerHide();
        this.httpErrorHandlerService.handleError(err);
      }
    })
  } 

  close(data?:any){
    this.dynamicDialogRef.close(data);
  }
}
