import { Component } from '@angular/core';
import { DynamicDialogConfig  , DynamicDialogRef} from 'primeng/dynamicdialog';
import { Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-pdf-dialog',
  templateUrl: './preview-pdf-dialog.component.html',
  styleUrls: ['./preview-pdf-dialog.component.scss']
})
export class PreviewPdfDialogComponent {
  blob: any;
  cvtBlob: any;
  constructor(private dynamicDialogRef: DynamicDialogRef, private dynamicDialogConfig: DynamicDialogConfig
    , private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getDataDialog()
  }

  getDataDialog(){
    let dialogData = this.dynamicDialogConfig.data;
    if (dialogData) {
      const test = URL.createObjectURL(dialogData.menu);
      this.cvtBlob = this.sanitizer.bypassSecurityTrustResourceUrl(test);
    }
  }

}
