import { Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TableLazyLoadEvent } from 'primeng/table';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { DropdownService } from 'src/app/services/api/dropdown.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDraftTempService } from 'src/app/services/api/temp/order-draft-temp.service';
import { DropdownAppService } from 'src/app/services/api/app/dropdown-app.service';
import { DraftOrderService } from 'src/app/services/api/asm/draft-order.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SharedService } from 'src/app/services/api/app/shared.service';
import { Location } from '@angular/common';
import { ReportService } from 'src/app/services/api/report.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { PreviewPdfDialogComponent } from 'src/app/modules/shared/preview-pdf-dialog/preview-pdf-dialog.component';

@Component({
  selector: 'app-add-edit-shared-order-detail',
  templateUrl: './add-edit-shared-order-detail.component.html',
  styleUrls: ['./add-edit-shared-order-detail.component.scss'],
})
export class AddEditSharedOrderDetailComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  @ViewChild('pEditor') editor: any;
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  userProfileKey: any;
  dataForm: FormGroup;
  modalAddListName: DynamicDialogRef | undefined;
  // @Output() sendNextStep = new EventEmitter<any>();
  dataSource: any = [];
  isStep: boolean = true;
  orderId: any = null;
  dataDetail: any = null;
  reportFileNamme: any = null;
  id: any = null;
  applicationId: number | undefined;
  selectedData: any = [];
  modalPreviewPdf: DynamicDialogRef | undefined;
  transactionTypeList: any[] = [];
  transactionSubTypeList: any[] = [];
  orderSignedList: any[] = [];
  orderSubjectList: any[] = [];
  filterOrderSigned: any[] = [];
  orderSignedCopyList: any[] = [];
  orderSignedPrintList: any[] = [];
  menuName: string = '';

  constructor(
    private formBuilder: FormBuilder,
    public dynamicDialogRef: DynamicDialogRef,
    private utils: UtilsService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private draftOrderService: DraftOrderService,
    private sessionStorageService: SessionStorageService,
    private _location: Location,
    private dropdownAppService: DropdownAppService,
    private orderDraftTempService: OrderDraftTempService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private reportService: ReportService,
    private dialogService: DialogService
  ) {
    this.dataForm = this.formBuilder.group({
      orderSubjectId: [{ value: null, disabled: true }],
      transactionTypeId: [{ value: null, disabled: true }],
      transactionSubTypeId: [{ value: null, disabled: true }],
      commandText: [{ value: null, disabled: true }],
      subject: [null],
      allocateText: [null],
      content: [null],
      commandDate: [{ value: null, disabled: true }],
      effectiveDate: [null],
      description: [null],
      remark: [null],
      orderStatusId: [4],
      step: [5],
      orgUnitId: [null],
      orderFrom: [null],
      orderSignedName: [null],
      orderSignedPosition: [null],
      orderSignedCopyName: [null],
      orderSignedCopyPosition: [null],
      orderSignedPrintName: [null],
      orderStatusName: [null],
      orderSetNo: [{ value: null, disabled: true }],
      orderDate: [null],
      adHocPositionShortName: [{ value: null, disabled: true }],
      orderNo: [{ value: null, disabled: true }],
      draftDate: [{ value: null, disabled: true }],
      applicationId: [null],
      signedCopyDate: [null],
      orderId: [],
    });
  }

  ngOnInit(): void {
    this.menuName = this.utils.getMenuName(this.router?.url);
    this.applicationId = this.utils.getApplicationCodeByRouter(
      this.router?.url
    );
    this.utils.spinnerShow();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.userProfileKey = this.sessionStorageService.getUserProfile()?.userKey;
    this.chainFunctionOnInit();
  }

  chainFunctionOnInit() {
    this.getDropdown()
      .pipe(
        concatMap(() => this.setDisplay()),
        concatMap(() => this.getTransactionSubType()),
        concatMap(() => this.getOderSubject()),
        concatMap(() => this.displayOrderDetail()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.getDataStampValue();
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  getDropdown() {
    return this.dropdownAppService
      .getTransactionTypeId(this.applicationId)
      .pipe(
        concatMap((resp: any) => {
          if (resp?.status == 200) {
            this.transactionTypeList = resp?.data;
          }
          return this.sharedService.getOrderSignedId(this.applicationId);
        }),
        switchMap((resp: any) => {
          if (resp?.data) {
            this.orderSignedList = resp.data;
          }
          return this.dropdownAppService.getOrderSignedCopy(this.applicationId);
        }),
        switchMap((resp: any) => {
          if (resp?.data) {
            this.orderSignedCopyList = resp.data;
          }
          return this.dropdownAppService.getOrderSignedPrint(
            this.applicationId
          );
        }),
        switchMap((resp: any) => {
          if (resp?.data) {
            this.orderSignedPrintList = resp.data;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

  setDisplay() {
    if (this.id == 'create') {
      return of(null);
    } else {
      return this.orderDraftTempService.getById(this.id).pipe(
        concatMap((resp: any) => {
          if (resp?.data) {
            this.dataDetail = resp?.data;
            this.orderId = resp?.data.orderId;
            this.dataForm
              .get('draftDate')
              ?.setValue(
                resp?.data?.draftDate ? new Date(resp?.data?.draftDate) : null
              );
            this.dataForm.patchValue(resp?.data);
            const findValueOrderSignedName = this.orderSignedList.find(
              (item: any) => item.fullName == resp?.data.orderSignedName
            );
            let dataSignedName = {
              fullName: resp?.data.orderSignedName,
            };
            this.dataForm.get('orderSignedCopyName')?.setValue( resp?.data.orderSignedCopyName);
            this.dataForm
              .get('orderSignedName')
              ?.setValue(findValueOrderSignedName ?? dataSignedName);
            this.dataForm
              .get('orderStatusName')
              ?.setValue(resp?.data.orderStatusName);
            this.dataForm.get('description')?.setValue(resp?.data?.description);
            this.dataForm.get('remark')?.setValue(resp?.data?.remark);
            this.dataForm
              .get('effectiveDate')
              ?.setValue(
                resp?.data?.effectiveDate
                  ? new Date(resp?.data?.effectiveDate)
                  : null
              );
            //solution setValue p-editor
            const quillEditor = this.editor.quill;
            quillEditor.root.innerHTML = resp?.data?.content;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
    }
  }

  getTransactionSubType() {
    let transactionTypeId = this.dataForm.get('transactionTypeId')?.value;
    return this.dropdownAppService
      .getTransactionSubType(this.applicationId, transactionTypeId)
      .pipe(
        concatMap((resp: any) => {
          if (resp?.data) {
            this.transactionSubTypeList = resp?.data;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

  getOderSubject() {
    let param = {
      applicationId: this.applicationId,
      transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
      transactionSubTypeId: this.dataForm.get('transactionSubTypeId')?.value == 0 ? null : this.dataForm.get('transactionSubTypeId')?.value,
    };
    return this.sharedService.getOderSubject(param).pipe(
      concatMap((resp: any) => {
        if (resp?.data) {
          this.orderSubjectList = resp?.data;
        }
        return of(resp);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  setOderSubject() {
    const valueOrderSubject = this.dataForm.get('orderSubjectId')?.value;
    const findValueOrderSubject = this.orderSubjectList.find(
      (item: any) => item.orderSubjectId == valueOrderSubject
    );
    this.dataForm.get('subject')?.setValue(findValueOrderSubject.subject);
    this.reportFileNamme = findValueOrderSubject?.reportFileName;
  }

  getDataStampValue() {
    const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;

    this.dataForm
      .get('adHocPositionShortName')
      ?.setValue(valueOrderSigned?.adHocPositionShortName);
  }

  getOrderSignedCopy() {
    const valueOrdersignedCopyName = this.dataForm.get(
      'orderSignedCopyName'
    )?.value;

    this.dataForm
      .get('orderSignedCopyPosition')
      ?.setValue(valueOrdersignedCopyName?.adHocPositionShortName);
  }

  filterOrderSignedId(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.orderSignedList as any[]).length; i++) {
      let country = (this.orderSignedList as any[])[i];
      if (country.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filterOrderSigned = filtered;
    const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;
    let findOrderSigned = this.filterOrderSigned.find(
      (data: any) => data.fullName === valueOrderSigned
    );
    this.dataForm
      .get('adHocPositionShortName')
      ?.setValue(findOrderSigned?.adHocPositionShortName);
    if (!findOrderSigned) {
      this.dataForm.get('adHocPositionShortName')?.reset();
    }
  }

  displayOrderDetail() {
    if (this.orderId != null) {
      this.dataForm.get('transactionTypeId')?.disable();
      this.dataForm.get('transactionSubTypeId')?.disable();
      let params = {
        orderId: this.orderId,
        orderStatusId: parseInt(this.dataDetail.orderStatusId),
      };
      return this.orderDraftTempService
        .createOrderDetailListPerson(params)
        .pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.dataSource = resp?.data;
            }
            return of(resp);
          }),
          catchError((err: any) => {
            throw err;
          })
        );
    } else {
      return of(null);
    }
  }

  setParam() {
    let params = this.dataForm.getRawValue();
    params.orderId = parseInt(this.id);
    params.draftDate = params.draftDate
      ? this.utils.convertFormatDateToParam(params.draftDate)
      : null;
    params.signedCopyDate = params.signedCopyDate
      ? this.utils.convertFormatDateToParam(params.signedCopyDate)
      : null;
    params.effectiveDate = params.effectiveDate
      ? this.utils.convertFormatDateToParam(params.effectiveDate)
      : null;
    params.transactionTypeId = this.dataForm.get('transactionTypeId')?.value != null
      ? this.dataForm.get('transactionTypeId')?.value.toString()
      : null;
    params.applicationId = this.applicationId;
    params.step = this.dataForm.get('step')?.value?.toString();
    params.orderSignedName = this.dataForm.get('orderSignedName')?.value?.fullName;
    params.orderSignedPrintName = this.dataForm.get('orderSignedPrintName')?.value;
    params.OrderSignedCopyName = this.dataForm.get('orderSignedCopyName')?.value;
    params.orderSignedPosition = this.dataForm.get(
      'adHocPositionShortName'
    )?.value;
    return params;
  }

  validateForm() {
    if (this.dataForm.invalid) {
      this.utils.alertBox('warning', 'กรุณาระบุข้อมูลให้ครบ', '');
      return true;
    }
    return false;
  }

  setParamForOrderDetail(data: any) {
    this.orderId = data.orderId;
    let paramSave = [];
    let param = {
      orderId: data.orderId,
      transactionId: this.id,
      status: data.status,
      step: data.step,
    };
    paramSave.push(param);
    return paramSave;
  }

  sendApprove() {
    this.utils
      .confirmBox$('question', 'คุณต้องการส่งอนุมัติข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        switchMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            let param = {
              orderId: this.id || this.orderId,
              sendApproveBy: this.userProfileKey,
              status: this.dataForm.get('status')?.value,
              step: '4',
            };
            return this.draftOrderService.sendApproveOrder(param);
          } else {
            return EMPTY;
          }
        }),
        tap(async (resp: any) => {
          this.utils.spinnerHide();
          if (resp?.status == 200) {
            await this.utils.alertBox(`success`, `ส่งอนุมัติรายการสำเร็จ`, ``);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  setParamDelete() {
    let paramSave = [];
    for (let item of this.selectedData) {
      paramSave.push(item.transactionID);
    }
    return paramSave;
  }

  close() {
    this._location.back();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  create(params: any): Observable<any> {
    this.utils.spinnerShow();
    return this.orderDraftTempService.create(params).pipe(
      concatMap((resp: any) => {
        if (resp?.status == 200) {
          return this.utils.confirmBox$(
            'success',
            resp?.message,
            '',
            'backPage'
          );
        } else {
          return of(resp);
        }
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  print() {
    this.setOderSubject();
    let params = this.setParam();
    this.utils
      .confirmBox$('question', 'คุณต้องการพิมพ์เอกสารใช่หรือไม่ ?', '')
      .pipe(
        concatMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            return this.orderDraftTempService.update(params);
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          if (resp) {
            this.setDisplay();
            const params = {
              report: {
                P_ORDERID: this.dataForm.get('orderId')?.value.toString(),
                P_ISCOPY: 'Y',
                P_ISONLYONE: `${this.dataSource?.length === 1 ? 'Y' : 'N'}`,
                IsMod: 'ORDER',
              },
              reportName: this.reportFileNamme,
              // reportName: 'dischargeOrderSingleBook',
            };
            return this.reportService.getPdf(params);
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          if (resp) {
            const blob = new Blob([resp], { type: 'application/pdf' });
            this.utils.spinnerHide();
            this.openPreviewPdfDialog(blob);
          }
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  openPreviewPdfDialog(blob: any) {
    this.modalPreviewPdf = this.dialogService.open(PreviewPdfDialogComponent, {
      header: 'เอกสาร',
      width: '70%',
      height: '100%',
      contentStyle: { 'max-height': '100%', overflow: 'auto' },
      baseZIndex: 10000,
      data: { menu: blob },
    });
  }
}
