<form [formGroup]="dataForm">
    <div class="grid formgrid p-fluid">
        <div class="col-12 field">
            <label for="">รหัสผ่านเก่า</label>
            <p-password class="p-inputtext-lg" [feedback]="false" [toggleMask]="true" formControlName="oldPassword"></p-password>
        </div>
        <div class="col-12 field">
            <label for="">รหัสผ่านใหม่</label>
            <p-password class="p-inputtext-lg" [feedback]="false" [toggleMask]="true" formControlName="newPassword"></p-password>
            <small class="p-error" *ngIf="submitted && dataForm.get('newPassword')?.value != dataForm.get('confirmNewPassword')?.value">รหัสผ่านใหม่ไม่ตรงกัน</small>
        </div>
        <div class="col-12 field">
            <label for="">ยืนยันรหัสผ่านใหม่</label>
            <p-password class="p-inputtext-lg" [feedback]="false" [toggleMask]="true" formControlName="confirmNewPassword"></p-password>
            <small class="p-error" *ngIf="submitted && dataForm.get('newPassword')?.value != dataForm.get('confirmNewPassword')?.value">รหัสผ่านใหม่ไม่ตรงกัน</small>
        </div>
    </div>
</form>

<div class="flex justify-content-center gap-3 my-3">
    <p-button label="บันทึก" icon="fa-light fa-floppy-disk" iconPos="left" size="small" styleClass="bg-purple-900 w-10rem" (onClick)="save()"></p-button>
    <p-button label="ปิด" icon="fa-light fa-x" iconPos="left" size="small" [outlined]="true" severity="danger" styleClass="w-10rem" (onClick)="close(false)"></p-button>
</div>