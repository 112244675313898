import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PersonalInfoResponse } from '../../generated-api/model/personalInfoResponse';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalInfoService {
  private baseApiUrl = `${environment.apiPerUrl}PersonalInfo`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  selectPersonalId = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  search(
    id?: string | undefined,
    mstTitle?: string | undefined,
    mstOrgUnitId?: string | undefined,
    citizenId?: string | undefined,
    mstPersonnelGroupId?: any | undefined,
    firstName?: string | undefined,
    lastName?: string | undefined,
    roleId?:string | undefined,
    searchBy?: number | undefined,
    usePaging: boolean = true,
    pageIndex: number = 0,
    pageSize: number = 10,
    sortField?:string | undefined,
    sortType?:string | undefined
  ): Observable<PersonalInfoResponse> {
    let str = '?';

    if (id) str += `id=${id}&`;
    if (mstTitle) str += `mstTitle=${mstTitle}&`;
    if (mstOrgUnitId) str += `mstOrgUnitId=${mstOrgUnitId}&`;
    if (citizenId) str += `citizenId=${citizenId}&`;
    if (mstPersonnelGroupId) str += `mstPersonnelGroupId=${mstPersonnelGroupId}&`;
    if (roleId || roleId == '0') str += `roleId=${roleId}&`;
    if (firstName) str += `firstName=${firstName}&`;
    if (lastName) str += `lastName=${lastName}&`;
    if (searchBy) str += `searchBy=${searchBy}&`;
    if (sortField) str += `sortField=${sortField}&`;
    if (sortType) str += `sortType=${sortType}&`;

    str += `&usePaging=${usePaging}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.httpClient.get<PersonalInfoResponse>(
      `${this.baseApiUrl}${str}`,
      {
        headers: this.headers,
      }
    );
  }

  getById(personalInfoId?: number): Observable<any> {
    let str = '?';

    if (personalInfoId) str += `id=${personalInfoId}&`;

    return this.httpClient.get<any>(`${this.baseApiUrl}/GetById${str}`, {
      headers: this.headers,
    });
  }

  getProfileById(personalInfoId?: number): Observable<any> {
    let str = '?';

    if (personalInfoId) str += `id=${personalInfoId}&`;

    return this.httpClient.get<any>(`${this.baseApiUrl}/GetProfileById${str}`, {
      headers: this.headers,
    });
  }

  create(data: any): Observable<any> {
    return this.httpClient.post<any>(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  update(data: any): Observable<any> {
    return this.httpClient.put<any>(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  delete(data: any): Observable<any> {
    return this.httpClient.delete<any>(this.baseApiUrl, {
      headers: this.headers,
      body: data,
    });
  }

  deleteMany(items: any[]): Observable<any> {
    const deleteRequests = items.map((item) =>
      this.httpClient.delete<any>(this.baseApiUrl, {
        headers: this.headers,
        body: item,
      })
    );
    return forkJoin(deleteRequests);
  }
}
