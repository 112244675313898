import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-position',
  templateUrl: './modal-position.component.html',
  styleUrls: ['./modal-position.component.scss'],
})
export class ModalPositionComponent {
  poristionRows = environment.rows;
  positionRowsPerPageOptions = environment.rowsPerPageOptions;
  positionSource: any[] = [];

  reserveRows = environment.rows;
  reserveRowsPerPageOptions = environment.rowsPerPageOptions;
  reserveSource: any[] = [];

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private utils: UtilsService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  ngOnInit(): void {
    let dialogData = this.dynamicDialogConfig.data;
    console.log(dialogData);
    if (dialogData) {
    }
  }
}
