<div class="grid formgrid p-fluid px-3">

  <div class="col-12">
    <h4 class="text-purple-900 mb-2">ข้อมูลตำแหน่งประจำ สป.</h4>
    <p-table [value]="positionSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true"
      [showCurrentPageReport]="true" [paginator]="true" [selectionPageOnly]="true" [showCurrentPageReport]="true"
      [rows]="poristionRows" [rowsPerPageOptions]="positionRowsPerPageOptions"
      currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">

      <ng-template pTemplate="header">
        <tr class="white-space-nowrap">
          <th pSortableColumn="">
            <span>ชื่อ-นามสกุล</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>ตำแหน่ง</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>อัตรา</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>วันที่มีผล</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>วันที่เกษียณ</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr class="white-space-nowrap">
          <td></td> <!-- ชื่อ-นามสกุล -->
          <td></td> <!-- ตำแหน่ง -->
          <td></td> <!-- อัตรา -->
          <td></td> <!-- วันที่มีผล -->
          <td></td> <!-- วันที่เกษียณ -->
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <ng-container *ngIf="positionSource.length <= 0">
          <tr>
            <td colspan="5" class="text-left bg-white">
              ไม่มีข้อมูล
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>

  <div class="col-12 mt-3">
    <h4 class="text-purple-900 mb-2">ข้อมูลผู้ที่จองตำแหน่ง</h4>
    <p-table [value]="reserveSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true"
      [showCurrentPageReport]="true" [paginator]="true" [selectionPageOnly]="true" [showCurrentPageReport]="true"
      [rows]="reserveRows" [rowsPerPageOptions]="positionRowsPerPageOptions"
      currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">

      <ng-template pTemplate="header">
        <tr class="white-space-nowrap">
          <th pSortableColumn="">
            <span>ลำดับที่</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>ชื่อ-นามสกุล</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>ตำแหน่ง</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>อัตรา</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>ประเภทรายการ</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
          <th pSortableColumn="">
            <span>วันที่สิ้นสุดการจอง</span>
            <p-sortIcon field=""></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr class="white-space-nowrap">
          <td></td> <!-- ลำดับที่ -->
          <td></td> <!-- ชื่อ-นามสกุล -->
          <td></td> <!-- ตำแหน่ง -->
          <td></td> <!-- อัตรา -->
          <td></td> <!-- ประเภทรายการ -->
          <td></td> <!-- วันที่สิ้นสุดการจอง -->
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <ng-container *ngIf="reserveSource.length <= 0">
          <tr>
            <td colspan="6" class="text-left bg-white">
              ไม่มีข้อมูล
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
</div>
