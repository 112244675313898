import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputNumber]',
})
export class InputNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, '');
    if (inputValue !== onlyNumbers) {
      input.value = onlyNumbers;
      input.dispatchEvent(new Event('input'));
    }
  }
}
