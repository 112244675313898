import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  getUserProfile() {
    return JSON.parse(sessionStorage.getItem('user_profile')!);
  }

  getHash() {
    return sessionStorage.getItem('hash');
  }

  getRefreshToken() {
    return sessionStorage.getItem('refresh_token');
  }

  getAccessToken() {
    return sessionStorage.getItem('access_token');
  }

  getExpireToken() {
    return sessionStorage.getItem('access_token_expire');
  }

  getLinkageToken() {
    return sessionStorage.getItem('tokenLinkage');
  }

  getJobLinkage() {
    return sessionStorage.getItem('job_linkage');
  }

  getActiveRole() {
    return JSON.parse(sessionStorage.getItem('active_role')!);
  }

  setUserProfile(data: any) {
    sessionStorage.setItem('user_profile', JSON.stringify(data));
  }

  setHash(data: string) {
    sessionStorage.setItem('hash', data);
  }

  setRefreshToken(token: string) {
    sessionStorage.setItem('refresh_token', token);
  }

  setAccessToken(token: string) {
    sessionStorage.setItem('access_token', token);
  }

  setExpireToken(expire: string) {
    sessionStorage.setItem('access_token_expire', expire);
  }

  setJobLinkage(jobId: string) {
    sessionStorage.setItem('job_linkage', jobId);
  }

  setActiveRole(data: string) {
    sessionStorage.setItem('active_role', JSON.stringify(data));
  }

  removeRefreshToken() {
    sessionStorage.removeItem('refresh_token');
  }

  removeAccessToken() {
    sessionStorage.removeItem('access_token');
  }

  removeExpireToken() {
    sessionStorage.removeItem('access_token_expire');
  }

  removeUserProfile() {
    sessionStorage.removeItem('user_profile');
  }

  setDataToSessionStorage(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  getDataToSessionStorage(key: string) {
    let data = sessionStorage.getItem(key);
    return !data ? data : JSON.parse(data);
  }

  removeDataToSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  clearStorage() {
    sessionStorage.clear();
  }
}
