import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'formatShortDate' })
export class FormatShortDatePipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        let date = moment(value);
        let d = date.format('DD');
        let m = date.format('MM');
        let y = date.format('YYYY');
        return `${d}/${m}/${y}`;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortMonthDate' })
export class FormatShortDateMonthPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        let date = moment(value);
        let d = date.format('DD');
        let m = date.format('MMM');
        let y = date.format('YYYY');
        return `${d}-${m}-${y}`;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortMonthDate' })
export class FormatShortMonthPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        let date = moment(value);
        let d = date.format('DD');
        let m = date.format('MMM');
        let y = date.format('YY');
        return `${d} ${m} ${y}`;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortDateTime' })
export class FormatShortDateTimePipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        let date = moment(value);
        let d = date.format('DD');
        let m = date.format('MMM');
        let y = date.format('YYYY');
        let time = date.format('HH:mm');
        return `${d}-${m}-${y} ${time}`;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortDateTimeWithSecond' })
export class FormatShortDateTimeWithSecondPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        let date = moment(value);
        let d = date.format('DD');
        let m = date.format('MM');
        let y = date.format('YYYY');
        let time = date.format('HH:mm:ss');
        return `${d}/${m}/${y} ${time}`;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatDateThai' })
export class FormatDateThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let dateThai = date.format('LL');
        let year =
          parseInt(date.format('LL').substr(dateThai.length - 4)) + 543;
        dateThai = date.format('LL').substr(0, dateThai.length - 4);

        return dateThai + year;
      } else {
        return '';
      }
    } catch (ex) {
      return 'N/A';
    }
  }
}
@Pipe({ name: 'formatTimeThai' })
export class FormatTimeThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let time = date.format('HH:mm');
        return time;
      } else {
        return '';
      }
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatDateTimeThai' })
export class FormatDateTimeThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let dateThai = date.format('LL');
        let year =
          parseInt(date.format('LL').substr(dateThai.length - 4)) + 543;
        dateThai = date.format('DD MMMM YYYY').substr(0, dateThai.length - 4);
        let time = date.format('HH:mm');
        if (dateThai.length == 10) {
          dateThai += ' ';
        }
        return dateThai + ' ' + year + ' เวลา ' + time + ' น.';
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortDateThai' })
export class FormatShortDateThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let dateThai = date.format('ll');
        let year =
          parseInt(date.format('ll').substr(dateThai.length - 4)) + 543;
        dateThai = date.format('ll').substr(0, dateThai.length - 4);

        return dateThai + year;
      } else {
        return '';
      }
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortDateTimeThai' })
export class FormatShortDateTimeThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let dateThai = date.format('ll');
        let year =
          parseInt(date.format('ll').substr(dateThai.length - 4)) + 543;
        dateThai = date.format('ll').substr(0, dateThai.length - 4);
        let time = date.format('HH:mm');
        return dateThai + year + ' ' + time;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}

@Pipe({ name: 'formatShortDateTimeWithSecondThai' })
export class FormatShortDateTimeWithSecondThaiPipe implements PipeTransform {
  transform(value: any): string {
    try {
      if (value) {
        moment.locale('th');
        let date = moment(value);
        let dateThai = date.format('ll');
        let year =
          parseInt(date.format('ll').substr(dateThai.length - 4)) + 543;
        dateThai = date.format('ll').substr(0, dateThai.length - 4);
        let time = date.format('HH:mm:ss');
        return dateThai + year + ' ' + time;
      }
      return '';
    } catch (ex) {
      return 'N/A';
    }
  }
}
