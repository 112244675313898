import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
} from '@angular/core';
import { NumberWithCommaPipe } from '../pipes/number-with-comma.pipe';

@Directive({
  selector: '[appInputDecimal]',
})
export class InputDecimalDirective {
  @Input('decimals') decimals: any = 0;
  @Input('numberics') numberics: any = 0;

  private check(value: string, decimals: number, numberics: number) {
    if (numberics <= 0) {
      if (decimals <= 0) {
        return String(value).match(new RegExp(/^\d+$/));
      } else {
        var regExpString =
          '^\\s*((\\d+(\\.\\d{0,' +
          decimals +
          '})?)|((\\d*(\\.\\d{1,' +
          decimals +
          '}))))\\s*$';
        return String(value).match(new RegExp(regExpString));
      }
    } else {
      if (decimals <= 0) {
        var regExpString = '^\\d{0,' + numberics + '}$';
        return String(value).match(new RegExp(regExpString));
      } else {
        var regExpString =
          '^\\s*((\\d{0,' +
          numberics +
          '}(\\.\\d{0,' +
          decimals +
          '})?)|((\\d{1,' +
          numberics +
          '}(\\.\\d{1,' +
          decimals +
          '}))))\\s*$';
        return String(value).match(new RegExp(regExpString));
      }
    }
  }

  private specialKeys = [
    'Delete',
    'Backspace',
    'Tab',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
  ];

  numberWithCommaPipe: NumberWithCommaPipe;
  constructor(private el: ElementRef, private injector: Injector) {
    this.numberWithCommaPipe = injector.get(NumberWithCommaPipe);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    let current: string = this.el.nativeElement.value;
    let position: number = this.el.nativeElement.selectionStart;
    let next: string = [
      current.slice(0, position),
      event.key,
      current.slice(position),
    ].join('');

    if (
      !(
        // (event.keyCode >= 48 && event.keyCode <=57)
        (
          event.key === '.' ||
          event.code == 'Numpad0' ||
          event.code == 'Numpad1' ||
          event.code == 'Numpad2' ||
          event.code == 'Numpad3' ||
          event.code == 'Numpad4' ||
          event.code == 'Numpad5' ||
          event.code == 'Numpad6' ||
          event.code == 'Numpad7' ||
          event.code == 'Numpad8' ||
          event.code == 'Numpad9' ||
          event.code == 'Digit0' ||
          event.code == 'Digit1' ||
          event.code == 'Digit2' ||
          event.code == 'Digit3' ||
          event.code == 'Digit4' ||
          event.code == 'Digit5' ||
          event.code == 'Digit6' ||
          event.code == 'Digit7' ||
          event.code == 'Digit8' ||
          event.code == 'Digit9'
        )
      )
    ) {
      event.preventDefault();
    } else if (current.indexOf('.') != -1 && event.key === '.') {
      event.preventDefault();
    } else if (next && !this.check(next, this.decimals, this.numberics)) {
      event.preventDefault();
    }
  }

  // @HostListener('keyup', ['$event'])
  // onKeyUp(event: KeyboardEvent) {
  //     let current: string = this.el.nativeElement.value;
  //     this.el.nativeElement.value = this.numberWithCommaPipe.transform(current, this.decimals)
  // }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: any) {
    this.el.nativeElement.value = this.numberWithCommaPipe.parse(
      value,
      this.decimals
    );
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any) {
    this.el.nativeElement.value = this.numberWithCommaPipe.transform(
      value,
      this.decimals
    );
  }
}
