export const environment = {
  apiMstUrl: 'https://modhrmisapi.kbtdevops.com/mst/api/',
  apiPerUrl: 'https://modhrmisapi.kbtdevops.com/per/api/',
  apiAuthUrl: 'https://modhrmisapi.kbtdevops.com/auth/api/',
  apiUsrUrl: 'https://modhrmisapi.kbtdevops.com/usr/api/',
  apiAppUrl: 'https://modhrmisapi.kbtdevops.com/app/api/',
  apiAsmUrl: 'https://modhrmisapi.kbtdevops.com/asm/api/',
  apiTransUrl: 'https://modhrmisapi.kbtdevops.com/trans/api/',
  apiTempUrl: 'https://modhrmisapi.kbtdevops.com/temp/api/',
  apiDiscUrl: 'https://modhrmisapi.kbtdevops.com/ret/api/',
  apiObjStorageUrl: 'https://modhrmisapi.kbtdevops.com/object-storage/api/',
  apiPdfUrl: 'https://modhrmisapi.kbtdevops.com/rpt/api/',
  apiOrdUrl: 'https://modhrmisapi.kbtdevops.com/ord/api/',
  apiDco:'https://modhrmisapi.kbtdevops.com/dco/api/',
  apiGov:'https://modhrmisapi.kbtdevops.com/gov/api/',
  apiEmp:'https://modhrmisapi.kbtdevops.com/emp/api/',
  apiImpUrl: 'https://modhrmisapi.kbtdevops.com/imp/api/',
  apiLKUrl:'https://modhrmisapi.kbtdevops.com/lk2/api/',
  apiLKVersion:'v2/',
  appVersion: '1.0.0',
  appBuildNo: '2024121206',
  rows: 50,
  rowsPerPageOptions: [20, 50, 100],
  thaiDApi: "https://imauth.bora.dopa.go.th/api/",
  thaiDToken: "VkhseFJFVnRVMEZTV2tSdlZFTlFVVWh4ZG5ReWQydExWVmMxWWxBeFdXMDpjbnBRYTFCTlJrTklPR1ZRUzNJMWMwRkJNbGRQWWxCcmFFbGxRVzlpYUZobWVXdE9SamRHTlE=",
  thaiDClientId: "VHlxREVtU0FSWkRvVENQUUhxdnQyd2tLVVc1YlAxWW0",
  thaiDClientSecret: "cnpQa1BNRkNIOGVQS3I1c0FBMldPYlBraEllQW9iaFhmeWtORjdGNQ",
  thaiDRedirectUri: "https://modhrmis.kbtdevops.com/callback",
  thaiDScope:"pid"
};
