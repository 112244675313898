import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PermissionUserService {
  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {}

  getAllPermission(menuCode: any) {
    let userProfile = this.sessionStorageService.getUserProfile();
    let permissionFind = userProfile?.permissionMenu.find((item: any) => {
      if (item.menuId == menuCode) {
        return item.permission;
      }
    });
    return permissionFind?.permission;
  }

  getMenuCodeWithPath(path: any) {
    let userProfile = this.sessionStorageService.getUserProfile();
    let mainMenuId = userProfile?.mainMenu.flatMap((group: any) => {
      let codes: any[] = [];
      group?.menu.forEach((detail: any) => {
        if (detail.path == path) {
          codes.push(detail.menuId);
        }
        if (detail?.subMenu) {
          detail?.subMenu.forEach((subDetail: any) => {
            if (subDetail?.path == path) {
              codes.push(subDetail.menuId);
            }
          });
        }
      });
      return codes;
    });
    return mainMenuId[0];
  }
}
