import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AppLayoutComponent } from './layout/app.layout.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'callback',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/thaid/thaid.module').then((m) => m.ThaidModule),
  },

  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'asm',
        loadChildren: () =>
          import('./modules/asm/asm.module').then((m) => m.AsmModule),
      },
      {
        path: 'trans',
        loadChildren: () =>
          import('./modules/trans/trans.module').then((m) => m.TransModule),
      },
      {
        path:'ret',
        loadChildren:() =>
          import('./modules/ret/ret.module').then((m) => m.RetModule),
      },
      {
        path:'temp',
        loadChildren:() =>
          import('./modules/temp/temp.module').then((m) => m.TempModule),
      },
      {
        path: 'mst',
        loadChildren: () =>
          import('./modules/mst/mst.module').then((m) => m.MstModule),
      },
      {
        path: 'per',
        loadChildren: () =>
          import('./modules/per/per.module').then((m) => m.PerModule),
      },
      {
        path: 'usr',
        loadChildren: () =>
          import('./modules/usr/usr.module').then((m) => m.UsrModule),
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./modules/app/app.module').then((m) => m.AppModule),
      },
      {
        path: 'post',
        loadChildren: () =>
          import('./modules/post/post.module').then((m) => m.PostModule),
      },
      {
        path: 'mis',
        loadChildren: () =>
          import('./modules/mis/mis.module').then((m) => m.MisModule),
      },
    ],
  },
  {
    path: 'pagenotfound',
    loadChildren: () =>
      import('./modules/pagenotfound/pagenotfound.module').then(
        (m) => m.PagenotfoundModule
      ),
  },
  { path: '**', redirectTo: '/pagenotfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
