import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalResidenceService {

  constructor(private httpClientService: HttpClientService) {



  }

  searchPersonalResidence(param: any) {
    const url = environment.apiMstUrl + 'PersonalResidence';
    return this.httpClientService.post(url, param, false);

  }
}
