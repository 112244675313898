import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, concatMap, of, Subject, takeUntil, tap } from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownAppService } from 'src/app/services/api/app/dropdown-app.service';
import { SharedService } from 'src/app/services/api/app/shared.service';
import { OrderDraftTempService } from 'src/app/services/api/temp/order-draft-temp.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-person-to-draft-order',
  templateUrl: './add-person-to-draft-order.component.html',
  styleUrls: ['./add-person-to-draft-order.component.scss'],
})
export class AddPersonToDraftOrderComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  dataForm: FormGroup;
  dataSource: any[] = [];
  selectedData: any[] = [];
  transactionTypeList: any[] = [];
  transactionSubTypeList: any[] = [];
  transactionNameList: any[] = [];
  dataSelected: any[] = [];
  applicationId: number | undefined;
  transactionId : number | null = null;


  constructor(
    private formBuilder: FormBuilder,
    private httpErrorHandler: HttpErrorHandlerService,
    private utils: UtilsService,
    private sharedService: SharedService,
    public dynamicDialogRef: DynamicDialogRef,
    public dropdownAppService: DropdownAppService,
    public orderDraftTempService: OrderDraftTempService,
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.dataForm = this.formBuilder.group({
      transactionTypeId: [{ value: null, disabled: true }],
      transactionSubTypeId: [{ value: null, disabled: true }],
      transactionName: [null],
    });
  }

  ngOnInit() {
    this.applicationId = this.utils.getApplicationCodeByRouter(this.router?.url);
    this.transactionId = this.dynamicDialogConfig?.data?.dataSource[0]?.transactionId;
    if(this.applicationId == 9){
      if(this.transactionId != null){
        this.dataForm.get('transactionName')?.setValue(this.transactionId);
        this.dataForm.get('transactionName')?.disable();
      }
    }
    this.utils.spinnerShow();
    this.getDropdown()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.utils.spinnerHide();

        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandler.handleError(err);
        },
      });
  }

  getDropdown() {
    return this.dropdownAppService.getTransactionTypeId(this.applicationId).pipe(
      concatMap((resp: any) => {
        if (resp?.status == 200) {
          this.transactionTypeList = resp?.data;
          let findData = resp?.data.find(
            (data: any) =>
              data.value === this.dynamicDialogConfig?.data?.transactionTypeId
          );
          this.dataForm.get('transactionTypeId')?.setValue(findData?.value);
        }
        return this.dropdownAppService.getTransactionSubType(
          this.applicationId,
          this.dynamicDialogConfig?.data?.transactionTypeId
        );
      }),
      concatMap((resp: any) => {
        if (resp?.data) {
          this.transactionSubTypeList = resp?.data;
          let findData = resp?.data.find(
            (data: any) =>
              data.value ===
              this.dynamicDialogConfig?.data?.transactionSubTypeId
          );
          this.dataForm.get('transactionSubTypeId')?.setValue(findData?.value);
        }
        let params = {
          ApplicationId: this.applicationId,
          transactionTypeId: this.dynamicDialogConfig?.data?.transactionTypeId,
          transactionSubTypeId:
            this.dynamicDialogConfig?.data?.transactionSubTypeId,
        };
        return this.orderDraftTempService.getTransactionName(params);
      }),
      concatMap((resp: any) => {
        if (resp?.data) {

          this.transactionNameList = resp?.data;

        }
        return of(null);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  isDuplicate(data: any): boolean {
    return this.dynamicDialogConfig.data?.dataSource.some(
      (item: any) => item.militaryId === data.militaryId
    );
  }

  selecteValue() {
    if(this.dataForm.get('transactionName')?.value != null){
      let dataForm = {
        transactionId: this.dataForm.get('transactionName')?.value,
        transactionTypeId: this.dataForm.get('transactionTypeId')?.value != null ? this.dataForm.get('transactionTypeId')?.value : null ,
        transactionSubTypeId: this.dataForm.get('transactionSubTypeId')?.value != null ? this.dataForm.get('transactionSubTypeId')?.value : null,
        applicationId: this.applicationId,
      };
      this.selectedData = [];
      this.sharedService
        .getTransactionName(dataForm)
        .pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.dataSource = resp?.data;
            }
            return of(null);
          }),
          catchError((err: any) => {
            throw err;
          })
        )
        .subscribe();
    }else{
      return
    }
  }

  clear() {
    this.dataForm.reset();
  }

  save() {
    if (this.selectedData.length <= 0) {
      this.utils.alertBox('warning', 'กรุณาเลือกข้อมูลอย่างน้อย 1 รายการ', '');
      return;
    }

    let dataSelected = this.dynamicDialogConfig.data.dataSource;
    let dataSelected2 = this.selectedData.map((data: any) => {
      return {
        fullName: data.fullName,
        militaryId: data.militaryId,
        citizenId: data.citizenId,
        transactionDetailId: data.transactionDetailId,
        transactionId: this.dataForm.get('transactionName')?.value,
        transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
        transactionSubTypeId: this.dataForm.get('transactionSubTypeId')?.value,
      };
    });
    let filterData = dataSelected2.filter(
      (itemB) =>
        !dataSelected.some(
          (itemA: any) =>
            itemA.transactionDetailId === itemB.transactionDetailId
        )
    );
    this.close(filterData);
  }

  close(callFunction: any) {
    this.dynamicDialogRef.close(callFunction);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
