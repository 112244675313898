import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { tap } from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AuthService } from 'src/app/services/api/auth.service';

@Component({
  selector: 'app-choose-role-modal',
  templateUrl: './choose-role-modal.component.html',
  styleUrls: ['./choose-role-modal.component.scss'],
})
export class ChooseRoleModalComponent {
  userProfile: any = null;
  applicationId: any = null;
  userKey: any = null;
  roleId: any = null;
  roleList: any[] = [];
  activeRoleId: any = null;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private utils: UtilsService,
    private authService: AuthService,
    private sessionStorage: SessionStorageService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  ngOnInit() {
    let dialogData = this.dynamicDialogConfig.data;
    if (dialogData) {
      this.userProfile = dialogData?.userProfile
        ? dialogData?.userProfile
        : null;
      this.userKey = this.userProfile?.userKey
        ? this.userProfile?.userKey
        : null;
      this.applicationId = this.userProfile?.applicationId
        ? this.userProfile?.applicationId
        : null;
      this.roleList = this.userProfile?.roleId ? this.userProfile?.roleId : [];
      this.activeRoleId = dialogData.activeRoleId;
    }
  }

  chooseRole(roleId: any, role: any) {
    if (this.activeRoleId == roleId) {
      const skipReload = true;
      this.dynamicDialogRef.close(skipReload);
    } else {
      let params = {
        applicationId: this.applicationId,
        userId: this.userKey,
        roleId: roleId,
      };
      this.utils.spinnerShow();
      this.authService
        .getMenuByRole(params)
        .pipe(
          tap((resp: any) => {
            this.utils.spinnerHide();
            if (resp?.data) {
              this.userProfile.mainMenu = resp.data.mainMenu
                ? resp.data.mainMenu
                : [];
              this.userProfile.permissionMenu = resp.data.permissionMenu
                ? resp.data.permissionMenu
                : [];
              this.sessionStorage.setUserProfile(this.userProfile);
              this.sessionStorage.setActiveRole(role);
              this.dynamicDialogRef.close();
            }
          })
        )
        .subscribe({
          next: () => {},
          error: (err: any) => {
            this.utils.spinnerHide();
            this.sessionStorage.clearStorage();
            this.dynamicDialogRef.close();
            this.httpErrorHandlerService.handleError(err);
          },
          complete: () => {
            this.utils.spinnerHide();
          },
        });
    }
  }
}
