import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClientService:HttpClientService) { }

  getPdf(param : any) {
    const URL : string = environment.apiPdfUrl + `report/GetReport`;
    return this.httpClientService.getFileBlob(URL, param , false);
  }
}
