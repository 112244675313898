import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup  ,FormControl} from '@angular/forms';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { environment } from 'src/environments/environment';
import { DropdownService } from 'src/app/services/api/dropdown.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { PersonalResidenceService } from 'src/app/services/api/trans/personal-residence.service';
import { TableLazyLoadEvent } from 'primeng/table';
import { concatMap, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { ReportService } from 'src/app/services/api/report.service';
@Component({
  selector: 'app-share-verify-personal-residence',
  templateUrl: './verify-personal-residence.component.html',
  styleUrls: ['./verify-personal-residence.component.scss']
})
export class SharedVerifyPersonalResidenceComponent {
  pathName = "ตรวจสอบทำเนียบกำลังพล";
  search$ = new BehaviorSubject<any>(null);
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: any = null;
  instituteId: any = null;
  totalRecords: number = 0;
  searchForm: FormGroup;
  reportForm: FormGroup;
  countForm: FormGroup;
  dataSource: any[] = [];
  ListOfUnit: any[] = [];
  ListOfPosition: any[] = [];
  listOfTechPosition: any[] = [];
  role :any;
  instituteSepareteChck: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  // dataForm: FormGroup;
  constructor(private dropdownService : DropdownService
    , private utils: UtilsService
    , private httpError : HttpErrorHandlerService
    , private personalResidenceService : PersonalResidenceService
    , private reportService : ReportService
    , private sessionStorage: SessionStorageService
  ) {
      this.searchForm = new FormGroup({
        instituteId : new FormControl(null),
        divisionId : new FormControl(null),
        displayInActive : new FormControl(false),
        instituteSeparete : new FormControl(null),
        instituteSepareteChck : new FormControl(false),
      });
      this.reportForm = new FormGroup({
        reportType : new FormControl(null),
      });

      this.countForm = new FormGroup({
        commissionCount : new FormControl({value: 0, disabled: true}),
        nonCommissionedCount : new FormControl({value: 0, disabled: true}),
        totalCommissionedCount : new FormControl({value: 0, disabled: true}),
        realCommissionCount : new FormControl({value: 0, disabled: true}),
        realNonCommissionCount : new FormControl({value: 0, disabled: true}),
        realTotalCommissionedCount : new FormControl({value: 0, disabled: true}),
        volunteerCount : new FormControl({value: 0, disabled: true}),
        soldierCount : new FormControl({value: 0, disabled: true}),
        solVoSumCount : new FormControl({value: 0, disabled: true}),
        realSoldierCount : new FormControl({value: 0, disabled: true}),
        realVolunteerCount : new FormControl({value: 0, disabled: true}),
        realSoVoSumCount : new FormControl({value: 0, disabled: true}),
      });
     }


  ngOnInit() {
    this.role = this.sessionStorage.getActiveRole();
    this.utils.spinnerShow();
    this.getDropdown().subscribe(
      {
        next: (res) => {

          this.utils.spinnerHide();
        },
        error: (error) => {
          this.utils.spinnerHide();
          this.httpError.handleError(error);
      }
    }
    );

  }

  searchAction(event?: TableLazyLoadEvent) {
    this.first = event?.first ? event?.first : 0;
    this.sortField = event?.sortField ? event?.sortField : null;
    this.sortOrder = event?.sortOrder ? event?.sortOrder : 1;

    this.rows = event?.rows ? event?.rows : environment.rows;
    let body = this.searchForm.getRawValue();
    body.pageFirstIndex = this.first;
    body.rows = this.rows;
    body.sortField = this.sortField;
    body.sortType = this.sortOrder  === -1 ? 'DESC' : 'ASC';

    if (this.search$.observed) {
      this.search$.next(body);
    } else {
      this.search$.next(body);
      this.searchInitial();
    }
  }

  searchInitial() {
    this.search$
      .pipe(
        debounceTime(10),
        switchMap((params) => {
          this.utils.spinnerShow();
          if (params) {
            return this.personalResidenceService.searchPersonalResidence(params);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (resp) => {
          if (resp?.data) {
            console.log(resp.data);
            this.dataSource = resp.data.personalResidenceDtos;
            this.totalRecords = resp.data.total.sums;
            this.countForm.patchValue(resp.data.total);
            this.setCountData();
          } else {
            this.dataSource = [];
            this.totalRecords = 0;
          }
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpError.handleError(err);
        },
      });
  }

  setCountData(){
    this.countForm.get('totalCommissionedCount')?.setValue(this.countForm.get('commissionCount')?.value + this.countForm.get('nonCommissionedCount')?.value);
    this.countForm.get('realTotalCommissionedCount')?.setValue(this.countForm.get('realCommissionCount')?.value + this.countForm.get('realNonCommissionCount')?.value);
  }
  clearField(event : CheckboxChangeEvent){
    if(!event.checked){
      this.searchForm.get('instituteSeparete')?.reset();
    }
  }

  chainDropdown(event: any) {
    if(event.value != null){
      if(event.value != this.searchForm.get('instituteId')?.value){
        this.searchForm.get('divisionId')?.reset();
        this.ListOfPosition = [];
      }
      this.dropdownService.getMstOrgUnitByParentID(event.value).subscribe(
        {
          next: (res) => {
            if(res?.data){
              this.ListOfPosition = res.data;
            }
          },
          error: (error) => {
            this.httpError.handleError(error);
        }
      });
    }
    else {
      this.searchForm.get('divisionId')?.reset();
      this.ListOfPosition = [];
    }
  }


  getDropdown() {
    return this.dropdownService.getOrgUnitByRoleId(this.role.roleId).pipe
    (
      concatMap((res) => {
        if(res?.data){
          this.ListOfUnit = res.data;
        }
        return this.dropdownService.getMstPositionTechnical();
      }),
      concatMap((res) => {
        if(res?.data){
          this.listOfTechPosition = res.data;
        }
        return of(res);

      })
    );
  }

  printReport(){
    this.utils.spinnerShow();
    const params ={
      report: {
        divisionId:  9,
        instituteSeparete: this.searchForm.get('instituteSeparete')?.value ?? null,
        displayInActive: this.searchForm.get('displayInActive')?.value == false ? null : 1,

      },
      reportName:"verify-personal-residence"
  }

  if(this.searchForm.get('divisionId')?.value != null){
    params.report.divisionId = this.searchForm.get('divisionId')?.value;
  }
  else if(this.searchForm.get('instituteId')?.value != null){
    params.report.divisionId = this.searchForm.get('instituteId')?.value;
  }

  this.reportService.getPdf(params).subscribe({
    next: (res) => {
      if(res){
      this.utils.spinnerHide();
      const blobUrl = URL.createObjectURL(res);
      window.open(blobUrl);
      }
    },
    error: (error) => {
    this.utils.spinnerHide();
      this.httpError.handleError(error);
    }

  });
  }

  cancelSearch() {
    this.searchForm?.reset();
  }
}
