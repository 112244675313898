import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appConvertToUpperCase]',
})
export class ConvertToUpperCaseDirective {
  constructor() {}
  private regex: RegExp = /^[A-Za-z\.\s]+$/;

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    let newValue = inputElement.value.toUpperCase().replace(/[^A-Z\.\s]/g, '');
    if (inputValue !== newValue) {
      inputElement.value = newValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
