<!-- <ng-container *ngIf="type != 'order' && isStep == true">
    <form [formGroup]="dataFormSelectRecord">
        <div class="card shadow-none flex justify-content-center">
            <div class="grid formgrid p-fluid w-12 xl:w-6 md:w-8">
                <div class="col-12">
                    <div class="formgroup-inline flex align-items-end justify-content-center">
                        <div class="field-radiobutton">
                            <p-radioButton inputId="new" value="new" formControlName="addType"></p-radioButton>
                            <label for="new" class="ml-2">เพิ่มรายชื่อในชุดใหม่</label>
                        </div>
                        <div class="field-radiobutton">
                            <p-radioButton inputId="old" value="old" formControlName="addType"></p-radioButton>
                            <label for="old" class="ml-2">เพิ่มรายชื่อในชุดเดิม</label>
                        </div>
                    </div>
                </div>
                <div class="field col-12" *ngIf="dataFormSelectRecord.get('addType')?.value == 'old'">
                    <label>เลขที่ชุด</label>
                    <div class="p-inputgroup">
                        <input pInputText formControlName="orderSetNo" />
                    </div>
                    <div class="p-inputgroup">
                        <p-dropdown [options]="listMstOrder"
                            optionLabel="text" optionValue="value"
                            [filter]="true" filterBy="text"
                            appendTo="body"
                            [showClear]="true"
                            [autoDisplayFirst]="false"
                            placeholder="เลือก"
                            formControlName="orderSetNo"
                            styleClass="w-full border-noround-right"
                            class="w-full">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="flex justify-content-center gap-3 my-3">
        <p-button label="ถัดไป" icon="fa-light fa-arrow-right" iconPos="right" styleClass="bg-purple-900 w-10rem" (onClick)="getNextData()"></p-button>
        <p-button label="ยกเลิก" icon="fa-light fa-x" iconPos="left" [outlined]="true" severity="danger" styleClass="w-10rem" (onClick)="close(false)"></p-button>
    </div>
  </ng-container> -->
  <div class="text-purple-900 font-bold mb-5">
    <i class="fa-light fa-house pr-2"></i>
    <span>ระบบงานข้อมูล{{menuName}} / </span>
    <span>ยกร่างคำสั่ง /</span>
    <span> รายละเอียดยกร่างคำสั่ง </span>
  </div>

<ng-container>
<div class="card">
    <form [formGroup]="dataForm">
        <h5 class="text-purple-900">หัวเรื่องคำสั่ง</h5>
        <div class="grid formgrid p-fluid">
            <div class="field col-12 md:col-3">
                <label>ประเภทรายการ  <span class="p-error">*</span></label>
                <p-dropdown [options]="transactionTypeList" optionValue="value" optionLabel="text" [filter]="true"
                    filterBy="name" [showClear]="true" appErrorMsg placeholder="เลือก" (onChange)="getTransactionSubType()"
                    formControlName="transactionTypeId">
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-3">
                <label>ประเภทรายการย่อย  <span class="p-error" *ngIf="transactionSubTypeList.length > 0">*</span></label>
                <p-dropdown [options]="transactionSubTypeList" optionValue="value" optionLabel="text" [filter]="true"
                    filterBy="name" [showClear]="true" appErrorMsg placeholder="เลือก" (onChange)="getOderSubject()" formControlName="transactionSubTypeId">
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-6">
                <label>หัวเรื่องคำสั่ง  <span class="p-error">*</span></label>
                <p-dropdown [options]="orderSubjectList" optionLabel="subject" optionValue="orderSubjectId" appErrorMsg
                    [filter]="true" filterBy="text" appendTo="body" [showClear]="true" [autoDisplayFirst]="false"
                    placeholder="เลือก" class="w-full" styleClass="w-full"
                    [virtualScroll]="orderSubjectList.length > 6 ? true : false" [virtualScrollItemSize]="30"
                    formControlName="orderSubjectId" (onChange)="setOderSubject()">
                </p-dropdown>
            </div>

            <div class="field col-12 md:col-3">
                <label>เลขที่ชุด</label>
                <input pInputText formControlName="orderSetNo" />
            </div>
            <!-- <div class="field col-12 md:col-3">
                <label>วันที่ทำรายการ</label>
                <div class="p-inputgroup">
                    <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี" [matDatepicker]="orderDate1"
                        (click)="orderDate1.open()" formControlName="draftDate" />
                    <button type="button" pButton icon="fa-light fa-calendar" (click)="orderDate1.open()"></button>
                </div>
                <mat-datepicker #orderDate1></mat-datepicker>
            </div> -->
            <div class="field col-12 md:col-3">
                <label>สถานะ</label>
                <div class="bg-yellow-500 text-white text-center font-bold p-2 border-round w-12rem">
                    {{
                    this.dataForm.get("statusName")?.value == null
                    ? "รอยกร่างคำสั่ง"
                    : this.dataForm.get("statusName")?.value
                    }}
                </div>
            </div>
        </div>

        <hr />

        <h5 class="text-purple-900">การอนุมัติคำสั่ง</h5>
        <div class="grid formgrid p-fluid">
            <div class="field col-12 md:col-3">
                <label>ผู้มีอำนาจออกคำสั่ง</label>
                <p-autoComplete [dropdown]="true" [suggestions]="filterOrderSigned" formControlName="orderSignedName" optionValue="value"
                    [showClear]="true" (completeMethod)="filterOrderSignedId($event)" (onSelect)="getDataStampValue()"
                    (onClear)="this.dataForm.get('adHocPositionShortName')?.reset()" field="fullName">
                </p-autoComplete>
            </div>
            <div class="field col-12 md:col-3">
                <label>ตำแหน่งผู้อนุมัติ</label>
                <input pInputText formControlName="adHocPositionShortName" />
            </div>
            <div class="field col-12 md:col-3">
                <label>เลขที่คำสั่ง</label>
                <input pInputText formControlName="orderNo" />
            </div>
            <div class="field col-12 md:col-3">
                <label>ลงวันที่/เดือน/ปี</label>
                <div class="p-inputgroup">
                    <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี"
                        [matDatepicker]="commandDate1" (click)="commandDate1.open()" formControlName="commandDate" />
                    <button type="button" pButton icon="fa-light fa-calendar" (click)="commandDate1.open()"></button>
                </div>
                <mat-datepicker #commandDate1></mat-datepicker>
            </div>
        </div>

        <hr />

        <div class="grid formgrid p-fluid">
            <div class="field col-12 md:col-6">
                <label>หมายเหตุ</label>
                <input pInputText formControlName="remark" />
            </div>
        </div>

        <div class="field col-12 md:col-12 mt-2 mb-0 pb-1">
            <h5 for="uContent" class="text-purple-900">คำสั่ง</h5>
            <p-editor id="uContent" [style]="{ height: '10rem' }" formControlName="content" #pEditor>
                <ng-template pTemplate="header">
                </ng-template>
            </p-editor>
        </div>
    </form>
    <div class="flex justify-content-center gap-3 my-3">
        <p-button label="พิมพ์คำสั่งยกร่าง" icon="fa-light fa-print" iconPos="left" size="small" styleClass="bg-purple-900 w-12rem"
            (onClick)="print()"></p-button>
        <p-button label="ส่งอนุมัติ" icon="fa-light fa-paper-plane" iconPos="left" size="small" styleClass="bg-purple-700 w-10rem"
            (onClick)="sendApprove()" [disabled]="isApprove()"></p-button>
        <p-button label="บันทึก" icon="fa-light fa-floppy-disk" iconPos="left" size="small" styleClass="bg-purple-900 w-10rem"
        appFormSubmitValidation [validationControl]="dataForm" (onClick)="save()"></p-button>
        <p-button label="กลับ" icon="fa-light fa-arrow-left" iconPos="left" [outlined]="true" severity="danger" size="small"
            styleClass="w-10rem" (onClick)="close()"></p-button>
    </div>
    
    <form [formGroup]="dataDynamicForm">
        <div class="mt-6">
            <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true"
                [showCurrentPageReport]="true" [paginator]="true" [(selection)]="selectedData"
                [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" styleClass="p-datatable-sm"
                currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
                <ng-template pTemplate="caption">
                    <div class="grid my-0">
                        <div class="col-12 md:col-4">
                            <h5 class="text-purple-900 mb-0">รายชื่อยกร่างคำสั่ง</h5>
                        </div>
                        <div class="col-12 md:col-8 text-right">
                            <div class="flex justify-content-end gap-3">
                                <p-button label="เพิ่มรายชื่อยกร่างคำสั่ง" icon="fa-light fa-plus" iconPos="left" size="small" [disabled]="isCreateDraft()"
                                    styleClass="bg-purple-900" (onClick)="openModalAddListName()"></p-button>
                                <p-button label="ลบ" icon="fa-light fa-trash-can" iconPos="left" size="small" severity="danger"
                                    (onClick)="deleteAll()"></p-button>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr class="white-space-nowrap">
                        <th pFrozenColumn>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="number">
                            <span>ลำดับ</span>
                            <p-sortIcon field="number"></p-sortIcon>
                        </th>
                        <th pSortableColumn="militaryId">
                            <span>เลขประจำตัวทหาร</span>
                            <p-sortIcon field="militaryId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="citizenId">
                            <span>เลขประจำตัวประชาชน</span>
                            <p-sortIcon field="citizenId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fullName">
                            <span>ยศ-ชื่อ-นามสกุล</span>
                            <p-sortIcon field="fullName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTransactionType">
                            <span>ประเภทรายการ</span>
                            <p-sortIcon field="nameTransactionType"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTransactionSubType">
                            <span>ประเภทรายการย่อย</span>
                            <p-sortIcon field="nameTransactionSubType"></p-sortIcon>
                        </th>
                        <th pSortableColumn="unitShortName">
                            <span>หน่วยงาน</span>
                            <p-sortIcon field="unitShortName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="educational">
                            <span>คุณวุฒิการศึกษา</span>
                            <p-sortIcon field="educational"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameAppointMilitaryCourse">
                            <span>เเต่งตั้งยศเป็น</span>
                            <p-sortIcon field="nameAppointMilitaryCourse"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameCorps">
                            <span>พรรค/เหล่า</span>
                            <p-sortIcon field="nameCorps"></p-sortIcon>
                        </th>
                        <th pSortableColumn="shortNameArmedForce">
                            <span>ตำเเหน่ง</span>
                            <p-sortIcon field="shortNameArmedForce"></p-sortIcon>
                        </th>
                        <th pSortableColumn="rate">
                            <span>อัตรา</span>
                            <p-sortIcon field="rate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="salary">
                            <span>ระดับเงินเดือน</span>
                            <p-sortIcon field="salary"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTrainingAttend">
                            <span>คุณสมบัติ</span>
                            <p-sortIcon field="nameTrainingAttend"></p-sortIcon>
                        </th>
                        <th alignFrozen="right" pFrozenColumn [frozen]="true"></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-data let-i="rowIndex">
                    <tr class="white-space-nowrap">
                        <td pFrozenColumn>
                            <p-tableCheckbox [value]="data"></p-tableCheckbox>
                        </td>
                        <td>{{ i + 1 }}</td>
                        <td>{{ data.militaryId }}</td>
                        <td>{{ data.citizenId }}</td>
                        <td>{{ data.fullName }}</td>
                        <td>{{ data.nameTransactionType }}</td>
                        <td>{{ data.nameTransactionSubType }}</td>
                        <td>{{ data.unitShortName }}</td>
                        <td>{{ data.educational }}</td>
                        <td>{{ data.nameAppointMilitaryCourse }}</td>
                        <td>{{ data.nameCorps }}</td>
                        <td>{{ data.shortNameArmedForce }}</td>
                        <td>{{ data.rate }}</td>
                        <td>{{ data.salary }}</td>
                        <td>{{ data.nameTrainingAttend }}</td>
                        <td class="text-right" alignFrozen="right" pFrozenColumn [frozen]="true">
                            <p-button icon="fa-light fa-trash-can" [rounded]="true" severity="danger"
                            size="small" (onClick)="deleteData(data)" />
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                    <ng-container *ngIf="dataSource?.length === 0">
                        <tr>
                            <td colspan="15" class="text-left bg-white">ไม่มีข้อมูล</td>
                        </tr>
                    </ng-container>
                </ng-template>
            </p-table>
        </div>
    </form>
</div>
</ng-container>
