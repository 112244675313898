<div class="card">
  <form [formGroup]="searchForm">
    <p-panel header="ค้นหา{{pathName}}" [toggleable]="true" [collapseIcon]="'pi pi-chevron-up'"
      [expandIcon]="'pi pi-chevron-down'" styleClass="mb-3">
      <div class="flex w-full gap-3 justify-content-around formgrid p-fluid">
        <div class="field" [ngClass]="searchForm.get('instituteSepareteChck')?.value == false ? 'w-4': 'w-3'">
          <label>หน่วยงานระดับกรม</label>
          <p-dropdown [options]="ListOfUnit" (onChange)="chainDropdown($event)" optionLabel="text" optionValue="value" filterBy="text"
            [virtualScroll]="ListOfUnit.length > 6 ? true : false" [virtualScrollItemSize]="30" appendTo="body"
            [filter]="true" [showClear]="true" placeholder="เลือก" formControlName="instituteId">
          </p-dropdown>
        </div>
        <div class="field " [ngClass]="searchForm.get('instituteSepareteChck')?.value == false ? 'w-4': 'w-3'">
          <label>ระดับกอง</label>
          <p-dropdown [options]="ListOfPosition" optionLabel="text" optionValue="value" filterBy="text"
            [virtualScroll]="ListOfPosition.length > 6 ? true : false" [virtualScrollItemSize]="30" appendTo="body"
            [filter]="true" [showClear]="true" placeholder="เลือก" formControlName="divisionId">
          </p-dropdown>
        </div>
        <div class="flex gap-2 field align-items-center pt-4 w-auto">
          <p-checkbox inputId="instituteSeparete1" formControlName="instituteSepareteChck" (onChange)="clearField($event)" [binary]="true" />
          <label for="instituteSeparete1">ระดับกรมแยกสายวิทยาการ</label>
        </div>
        <div *ngIf="searchForm.get('instituteSepareteChck')?.value == true" class="field w-2">
          <label>ตำแหน่งสายวิทยาการ</label>
          <p-dropdown [options]="listOfTechPosition" optionLabel="text" optionValue="value" filterBy="text"
            [virtualScroll]="listOfTechPosition.length > 6 ? true : false" [virtualScrollItemSize]="30" appendTo="body"
            [filter]="true" [showClear]="true" placeholder="เลือก" formControlName="instituteSeparete">
          </p-dropdown>
        </div>
        <div class="flex align-items-center justify-content-end w-2  pt-2 gap-3">
          <p-button label="ค้นหา" icon="pi pi-search" iconPos="left" class="w-full" styleClass="bg-purple-900 w-full" (onClick)="searchAction()"></p-button>
          <p-button label="พิมพ์" icon="fa-light fa-print" iconPos="left" class="w-full" styleClass="bg-purple-900 w-full" (onClick)="printReport()"></p-button>
        </div>
      </div>
      <div class=" flex-grow-none flex gap-2 field align-items-center p-2 w-auto">
        <p-checkbox inputId="displayInActive" formControlName="displayInActive" [binary]="true"></p-checkbox>
        <label  for="displayInActive">แสดง ตำแหน่ง/หน่วย ที่ถูกยกเลิก</label>
      </div>

      <div class="grid">
        <form class="grid col-12 formgrid p-fluid" [formGroup]="countForm">
          <p-fieldset class="col-6"  legend="อัตรา">
            <div class="grid">
              <div class="col-4">
                <label>สัญญาบัตร</label>
                <input pInputText formControlName="commissionCount" />
               </div>
               <div class="col-4">
                <label>ประทวน</label>
                <input pInputText  formControlName="nonCommissionedCount" />
               </div>
               <div class="col-4">
                <label>รวม</label>
                <input pInputText  formControlName="totalCommissionedCount" />
               </div>
            </div>
          </p-fieldset>
          <p-fieldset  class="col-6"  legend="บรรจุจริง">
            <div class="grid">
              <div class="col-4">
                <label>สัญญาบัตร</label>
                <input pInputText formControlName="realCommissionCount"  />
               </div>
               <div class="col-4">
                <label>ประทวน</label>
                <input pInputText formControlName="realNonCommissionCount" />
               </div>
               <div class="col-4">
                <label>รวม</label>
                <input pInputText formControlName="realTotalCommissionedCount"/>
               </div>
            </div>
          </p-fieldset>
          <p-fieldset  class="col-6"  legend="อัตรา ทหารกองประจำการ">
            <div class="grid">
              <div class="col-4">
                <label>สต. ฯ</label>
                <input pInputText formControlName="soldierCount" />
               </div>
               <div class="col-4">
                <label>พลฯ</label>
                <input pInputText formControlName="volunteerCount" />
               </div>
               <div class="col-4">
                <label>รวม</label>
                <input pInputText formControlName="solVoSumCount"/>
               </div>
            </div>
          </p-fieldset>
          <p-fieldset  class="col-6"  legend="บรรจุจริง ทหารกองประจำการ">
            <div class="grid">
              <div class="col-4">
                <label>สต. ฯ</label>
                <input pInputText formControlName="realSoldierCount" />
               </div>
               <div class="col-4">
                <label>พลฯ</label>
                <input pInputText formControlName="realVolunteerCount"/>
               </div>
               <div class="col-4">
                <label>รวม</label>
                <input pInputText formControlName="realSoVoSumCount"/>
               </div>
            </div>
          </p-fieldset>
      </form>
      </div>
    </p-panel>



  <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" scrollHeight="400px" [scrollable]="true"
    [showCurrentPageReport]="true" [paginator]="true" [lazy]="true" [lazyLoadOnInit]="false" [selectionPageOnly]="true"
    (onLazyLoad)="searchAction($event)" [first]="first" [sortOrder]="sortOrder" [sortField]="sortField" [rows]="rows"
    [rowsPerPageOptions]="rowsPerPageOptions" [totalRecords]="totalRecords"
    currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">

    <ng-template pTemplate="caption">
      <h3 class="text-purple-900 my-0">รายการ{{pathName}}</h3>
    </ng-template>

    <ng-template pTemplate="header">
      <tr class="white-space-nowrap">
        <th>
          <span>ลำดับ</span>
        </th>
        <th pSortableColumn="adHocPositionShortName">
          <span>ตำแหน่ง</span>
          <p-sortIcon field="adHocPositionShortName"></p-sortIcon>
        </th>
        <th pSortableColumn="positionRankName">
          <span>อัตรา</span>
          <p-sortIcon field="positionRankName"></p-sortIcon>
        </th>
        <th pSortableColumn="fullName">
          <span>การบรรจุ</span>
          <p-sortIcon field="fullName"></p-sortIcon>
        </th>
        <th pSortableColumn="remarks">
          <span>หมายเหตุ</span>
          <p-sortIcon field="remarks"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-i="rowIndex">
      <tr class="white-space-nowrap">
        <td pFrozenColumn>{{i + 1}}</td>
        <td>{{data.adHocPositionShortName}}</td>
        <td>{{data.positionRankName}}</td>
        <td>{{data.fullName}}</td>
        <td>{{data.remarks == '-' || data.remarks == null ? "" : data.remarks}}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <ng-container *ngIf="dataSource.length <= 0">
        <tr>
          <td colspan="10" class="text-left bg-white">
            ไม่มีข้อมูล
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
