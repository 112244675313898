import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { GetOrgUnitResponse } from 'src/app/services/generated-api/model/getOrgUnitResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrgUnitService {
  private baseApiUrl = `${environment.apiMstUrl}MstOrgUnit`;
  private baseAdhocApiUrl = `${environment.apiMstUrl}MstOrgUnit/Adhoc`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    Authorization: 'Bearer ' + this.sessionStorage.getAccessToken(),
  });

  selectedOrgUnit = new BehaviorSubject<{
    unit?: any;
    isNew: boolean;
  }>({ isNew: false });

  updateTree = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private httpClientService: HttpClientService,
    private sessionStorage: SessionStorageService
  ) {}

  search(id: number): Observable<GetOrgUnitResponse> {
    return this.httpClient.get<GetOrgUnitResponse>(`${this.baseApiUrl}/${id}`, {
      headers: this.headers,
    });
  }

  create(data: any): Observable<any> {
    return this.httpClient.post(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  update(data: any): Observable<any> {
    return this.httpClient.put(this.baseApiUrl, data, {
      headers: this.headers,
    });
  }

  delete(id: any): Observable<any> {
    return this.httpClient.delete(`${this.baseApiUrl}/${id}`, {
      headers: this.headers,
    });
  }

  deleteMany(ids: any[]): Observable<any> {
    const deleteRequests = ids.map((id) =>
      this.httpClient.delete(`${this.baseApiUrl}/${id}`, {
        headers: this.headers,
      })
    );
    return forkJoin(deleteRequests);
  }

  createAdhoc(data: any): Observable<any> {
    return this.httpClient.post(this.baseAdhocApiUrl, data, {
      headers: this.headers,
    });
  }

  updateAdhoc(data: any): Observable<any> {
    return this.httpClient.put(this.baseAdhocApiUrl, data, {
      headers: this.headers,
    });
  }

  deleteAdhoc(id: any): Observable<any> {
    return this.httpClient.delete(`${this.baseAdhocApiUrl}/${id}`, {
      headers: this.headers,
    });
  }

  deleteManyAdhoc(ids: any[]): Observable<any> {
    const deleteRequests = ids.map((id) =>
      this.httpClient.delete(`${this.baseAdhocApiUrl}/${id}`, {
        headers: this.headers,
      })
    );
    return forkJoin(deleteRequests);
  }

  getTreeById(mstOrgUnitParentID: any) {
    const URL: string =
      environment.apiMstUrl + `MstOrgUnit/tree/${mstOrgUnitParentID}`;
    return this.httpClientService.get(URL, false);
  }

  getTree(params: any) {
    const URL: string = environment.apiMstUrl + `MstOrgUnit/tree`;
    return this.httpClientService.post(URL, params, false);
  }
}
