import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  linkageToken:any = null;
  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  // addHeaderAuthorization(options: any) {
  //   options.headers = options.headers.append(
  //     'Authorization',
  //     'Bearer ' + this.sessionStorage.getAccessToken()
  //   );
  //   return options;
  // }

  addHeaderAuthorizationThaId(options: any) {
    options.headers = options.headers.append("x-api-key", this.sessionStorage.getDataToSessionStorage('tokenLinkage'));
    options.headers = options.headers.append(
      "Authorization",
      "Bearer " + this.sessionStorage.getAccessToken()
    );
    return options;
  }

  generateOptions() {
    const OPTIONS: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Anonymous: 'false',
      }),
      withCredentials: false,
    };
    return OPTIONS;
  }

  generateOptionsUpload(data: any) {
    const OPTIONS: Object = {
      body: data,
      headers: new HttpHeaders({
        Anonymous: 'false',
      }),
      reportProgress: true,
    };
    return OPTIONS;
  }

  generateOptionsBlob() {
    const OPTIONS: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Anonymous: 'false',
      }),
      responseType: 'blob',
      observe: 'response',
    };
    return OPTIONS;
  }

  setAnonymous(options: any) {
    options.headers = options.headers.set('Anonymous', 'true');
    return options;
  }

  get(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    // if (!anonymous) {
    //   this.addHeaderAuthorization(OPTIONS);
    // }
    console.log('GET : ' + url);
    return this.httpClient.get(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  getLinkage(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    if (!anonymous) {
      this.addHeaderAuthorizationThaId(OPTIONS);
    }
    console.log('GET : ' + url);
    return this.httpClient.get(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  post(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    // if (!anonymous) {
    // this.addHeaderAuthorization(OPTIONS);
    // }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200 || response.status === 202 || response.status === 204) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  postLinkage(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    if (!anonymous) {
    this.addHeaderAuthorizationThaId(OPTIONS);
    }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200 || response.status === 202) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  put(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    // if (!anonymous) {
    // this.addHeaderAuthorization(OPTIONS);
    // }
    console.log('PUT : ' + url);
    return this.httpClient.put(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  delete(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    // if (!anonymous) {
    // this.addHeaderAuthorization(OPTIONS);
    // }
    console.log('DELETE : ' + url);
    return this.httpClient.delete(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  deleteLinkage(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    if (!anonymous) {
    this.addHeaderAuthorizationThaId(OPTIONS);
    }
    console.log('DELETE : ' + url);
    return this.httpClient.delete(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  upload(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptionsUpload(data);
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    // if (!anonymous) {
    // this.addHeaderAuthorization(OPTIONS);
    // }
    console.log('UPLOAD : ' + url);
    return this.httpClient.request('POST', url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  getFileBlob(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptionsBlob();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.body;
        } else {
          return null;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  getFileBlobById(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptionsBlob();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    console.log('GET : ' + url);
    return this.httpClient.get(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.body;
        } else {
          return null;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  getFileBlobAndData(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptionsBlob();
    if (anonymous) {
      this.setAnonymous(OPTIONS);
    }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          const contentDisposition = response.headers.get('content-disposition');
          const object = this.parseContentDisposition(contentDisposition);
          const blob = { blob: response.body };
          const result = {...blob,...object};
          return result;
        } else {
          return null;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  setTokenLinkage(token: any) {
    this.linkageToken = token;
  }

  private parseContentDisposition(contentDisposition: string): { [key: string]: string } | null {
    const result: { [key: string]: string } = {};
    const parts = contentDisposition.split(';').map(part => part.trim());
    for (const part of parts) {
      const [key, value] = part.split('=');
      if (key && value) {
        result[key.trim().toLowerCase()] = value.trim().replace(/(^['"]|['"]$)/g, '');
      }
    }
    return Object.keys(result).length ? result : null;
  }
}
