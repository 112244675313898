import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { MessageService } from 'primeng/api';
import { SessionStorageService } from './session-storage.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandlerService {
  constructor(
    private utils: UtilsService,
    private messageService: MessageService,
    private sessionStorageService:SessionStorageService,
    private localStorageService:LocalStorageService,
    private router:Router
  ) {}

  handleError(error: HttpErrorResponse) {
    if (!error) {
      return;
    }
    console.error(error);
    let title: string = '';
    let msg: string = '';
    if (error.status !== 0) {
      //server side http errors
      if (
        error.status === 100 ||
        error.status === 101 ||
        error.status === 102 ||
        error.status === 103 ||
        error.status === 104
      ) {
        // call Toast Example
        // this.messageService.add(this.utils.toastMsg('error'));
        title = `${error.message}`;
        return this.utils.alertBox('error', title, '');
      } else if (
        error.status === 400 ||
        error.status === 401 ||
        error.status === 402 ||
        error.status === 403 ||
        error.status === 404 ||
        error.status === 405 ||
        error.status === 406 ||
        error.status === 500
      ) {
        // call Toast Example
        // this.messageService.add(this.utils.toastMsg('error'));
        if(error?.error?.message){
          title = `${error?.error?.message}`;
        }else{
          title = `${error.statusText}`;
        }

        return this.utils.alertBox('error', title, '')
        .then(()=>{
          if(error.status == 401){
            this.localStorageService.clear();
            this.sessionStorageService.clearStorage();
            this.router.navigateByUrl('/login');
          }
        })
      } else {
        // call Toast Example
        // this.messageService.add(this.utils.toastMsg('error'));
        title = `Unexpected error occurred`;
        return this.utils.alertBox('error', title, '');
      }
    } else {
      //client side http errors
      title = `ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ โปรดตรวจสอบการเชื่อมต่อเครือข่ายของคุณ`;
      return this.utils.alertBox('error', title, '');
    }
  }
}
