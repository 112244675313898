import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportFileTempService {

  constructor(private httpClient: HttpClientService) { }

  importDataTemp(params: any) {
    const URL: string = environment.apiImpUrl + `ImportTemporary/ImportDataTemp`;
    return this.httpClient.upload(URL, params, false);
  }

  getMainHistoryTemp(params: any) {
    const URL: string = environment.apiImpUrl + `ImportTemporary/GetMainHistoryTemp`;
    return this.httpClient.upload(URL, params, false);
  }

  getHistoryTemp(params: any) {
    const URL: string = environment.apiImpUrl + `ImportTemporary/GetHistoryTemp`;
    return this.httpClient.upload(URL, params, false);
  }
}
