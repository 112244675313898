import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { BehaviorSubject, concatMap, debounceTime, of, Subject, takeUntil, tap } from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { PersonalListService } from 'src/app/services/api/temp/personal-list.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-select-person-multi',
  templateUrl: './modal-select-person-multi.component.html',
  styleUrls: ['./modal-select-person-multi.component.scss']
})
export class ModalSelectPersonMultiComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  id: any;
  transactionTypeId: any = null;
  transactionSubTypeId: any = null;
  dataForm: FormGroup;
  from:any = null;

  dataSource: any[] = []
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  selectedData:any[] = [];
  yearList:any[] = [];

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private httpErrorHandlerService : HttpErrorHandlerService,
    private personalListService : PersonalListService,
  ){
    this.from = this.dynamicDialogConfig?.data?.from
    this.dataForm = this.formBuilder.group({
      keyword: [null],
      year:null,
    });
  }

  ngOnInit(){
    this.utils.spinnerShow()
    this.getDataDialog().pipe(
      tap(() => {
        this.searchInitial();
      }),
    ).subscribe({
      next: () => {
        this.searchAction();
      },
      error: (err:any) => {
        this.utils.spinnerHide()
        this.httpErrorHandlerService.handleError(err)
      },
      complete: () => {
        this.utils.spinnerHide();
        takeUntil(this.unsubscribe$)
      }
    })
  }

  getDataDialog(){
    let yearList = new Date().getFullYear();
    for(let i = (yearList-30) ; i <=  (yearList+33); i++){
      this.yearList.push({
        value:i,
        text:i+ 543
      });
    }
    return of(null)
  }

  searchInitial(){
    this.search$.pipe(
        debounceTime(10),
        concatMap((params) => {
          this.utils.spinnerShow()
          if (params) {
            if(this.from == 'retire' || this.from == 'retire-military' || this.from == 'retire-military-before'){
              return this.personalListService.getVwPersonalRetireList(params);
            }else if(this.from == 'transfer-type'){
              return this.personalListService.getVwPersonalReClassifyList(params);
            }else{
              return this.personalListService.searchTempPersonalList(params);
            }
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (resp) => {
          if (resp?.data) {
            this.dataSource = resp.data;
            this.totalRecords = resp.total;
          } else {
            this.dataSource = [];
            this.totalRecords = 0;
          }
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  searchAction(event?: TableLazyLoadEvent){
    this.first = event?.first ? event?.first : 0;
    this.sortField = event?.sortField ? event?.sortField : null;
    this.sortOrder = event?.sortOrder ? event?.sortOrder : 1;
    this.rows = event?.rows ? event?.rows : environment.rows;
    let body: any = this.dataForm.getRawValue();
    body.sortField = event?.sortField ? event?.sortField : null;
    body.sortType = event?.sortOrder === -1 ? 'DESC' : 'ASC';
    body.pageFirstIndex = event?.first ? event?.first : 0;
    body.pageTake = event?.rows ? event?.rows : this.rows;

    if (this.search$.observed) {
      this.search$.next(body);
    } else {
      this.search$.next(body);
      this.searchInitial();
    }
  }

  selectPerson(){
    return this.utils.confirmBox('question','คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?','')
    .then((resp:any)=>{
      if(resp){
        this.close(this.selectedData)
      }
    })
  }

  cancel(){
    this.dataForm.reset()
    this.searchAction()
  }

  close(data: any){
    this.dynamicDialogRef.close(data);
  }
}
