import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { Router } from '@angular/router';
import { SessionStorageService } from '../core/services/session-storage.service';
import { AuthService } from '../services/api/auth.service';
import { HttpErrorHandlerService } from '../core/services/http-error-handler.service';
import { UtilsService } from '../core/services/utils.service';
import { PersonalInfoService } from '../services/api/per/personal-info.service';
import { concatMap, Observable, Subject, takeUntil, tap } from 'rxjs';
import { DopaService } from '../services/api/dopa.service';
import { DcoOfficerInfoService } from '../services/api/dco/dco-officer-info.service';
import { GovOfficerInfoService } from '../services/api/gov/gov-officer-info.service';
import { EmpEmployeeInfoService } from '../services/api/emp/emp-employee-info.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChooseRoleModalComponent } from '../modules/login/choose-role-modal/choose-role-modal.component';
import { ChangePasswordComponent } from '../modules/login/change-password/change-password.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  userProfile: any = null;
  fullName: string = '';
  role: any = null;
  roleId: any = null;
  roleName: string = '';
  roleList: any = [];
  modalChooseRole: DynamicDialogRef | undefined;
  modalChoosePassword: DynamicDialogRef | undefined;

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private utils: UtilsService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private authService: AuthService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private dcoOfficerInfoService:DcoOfficerInfoService,
    private govOfficerInfoService:GovOfficerInfoService,
    private empEmployeeInfoService:EmpEmployeeInfoService,
    private personalInfoService: PersonalInfoService,
    private dopaService:DopaService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.userProfile = this.sessionStorage.getUserProfile();
    this.fullName = this.userProfile?.name ? this.userProfile?.name : this.userProfile?.userName;
    this.role = this.sessionStorage.getActiveRole();
    if(this.role) {
      this.roleId = this.role.roleId;
      this.roleName = this.role.roleName ? this.role.roleName : '';
    }
    this.roleList = this.userProfile.roleId;
  }

  chooseRole() {
    const obj = {
      userProfile: this.userProfile,
      activeRoleId: this.roleId
    }
    this.openChooseRole$(obj)
      .pipe(
        tap((skipReload: any) => {
          if (skipReload !== true) {
            this.userProfile = this.sessionStorage.getUserProfile();
            this.fullName = this.userProfile?.name ? this.userProfile?.name : this.userProfile?.userName;
            this.role = this.sessionStorage.getActiveRole();
            if(this.role) {
              this.roleId = this.role.roleId;
              this.roleName = this.role.roleName ? this.role.roleName : '';
            }
            this.roleList = this.userProfile.roleId;
            // let url = this.router.url;
            this.router
              .navigateByUrl(`/`, { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl('/home');
                // this.router.navigateByUrl(url);
              });
          }
        })
      )
      .subscribe();
  }

  changePassword(){
    this.openChangePassword$()
    .pipe(
    ).subscribe();
  }

  openChooseRole$(data: any): Observable<any> {
    // console.log('openChooseRole:', data);
    return new Observable<any>((observer) => {
      this.modalChooseRole = this.dialogService.open(ChooseRoleModalComponent, {
        header: 'เลือกสิทธิ์การใช้งาน',
        width: '75%',
        height: 'auto',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        closable: false,
        closeOnEscape: false,
        styleClass: '',
        data: data,
      });
      this.modalChooseRole.onClose.subscribe((skipReload: any) => {
        observer.next(skipReload);
        observer.complete();
      });
    });
  }

  openChangePassword$(): Observable<any>{
    return new Observable<any>((observer) => {
      this.modalChoosePassword = this.dialogService.open(ChangePasswordComponent, {
        header: 'เปลี่ยนรหัสผ่านใหม่',
        width: '50%',
        height: 'auto',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        closable: false,
        closeOnEscape: false,
        styleClass: '',
      });
      this.modalChoosePassword.onClose.subscribe((skipReload: any) => {
        observer.next(skipReload);
        observer.complete();
      });
    });
  }



  anchor() {
    this.appSidebar.anchor();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  onRightMenuClick() {
    this.layoutService.showRightMenu();
  }

  get logo() {
    const logo =
      this.layoutService.config.menuTheme === 'white' ||
      this.layoutService.config.menuTheme === 'orange'
        ? 'dark'
        : 'white';
    return logo;
  }

  goToProfile() {
    let userPofile = this.sessionStorage.getUserProfile();
    
    this.sessionStorage.setDataToSessionStorage(
      'personal_id',
      userPofile?.personalInfoId ? userPofile?.personalInfoId : null
    );

    this.sessionStorage.setDataToSessionStorage(
      'govemployeeinfo_id',
      userPofile?.govEmployeeInfoId ? userPofile?.govEmployeeInfoId : null
    );
    this.sessionStorage.setDataToSessionStorage(
      'employeeinfo_id',
      userPofile?.employeeInfoId ? userPofile?.employeeInfoId : null
    );
    this.sessionStorage.setDataToSessionStorage(
      'employeetempinfo_id',
      userPofile?.employeeTempInfoId ? userPofile?.employeeTempInfoId : null
    );
    this.sessionStorage.setDataToSessionStorage(
      'dcofficerinfo_id',
      userPofile?.dcOfficerInfoId ? userPofile?.dcOfficerInfoId : null
    );

    this.sessionStorage.setDataToSessionStorage('personal_name', this.fullName);

    if(userPofile?.personalInfoId){
      this.personalInfoService.selectPersonalId.next(userPofile?.personalInfoId);
      this.router
      .navigateByUrl(`/home`, { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl('/per');
        // this.router.navigateByUrl(url);
      });
    }

    if(userPofile?.dcOfficerInfoId){
      this.dcoOfficerInfoService.selectPersonalId.next(userPofile?.dcOfficerInfoId);
      this.router
      .navigateByUrl(`/home`, { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl('/per/per-dco');
        // this.router.navigateByUrl(url);
      });
    }

    if(userPofile?.govEmployeeInfoId){
      this.govOfficerInfoService.selectPersonalId.next(userPofile?.govEmployeeInfoId);
      this.router
      .navigateByUrl(`/home`, { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl('/per/per-gov');
        // this.router.navigateByUrl(url);
      });
    }

    if(userPofile?.employeeInfoId){
      this.empEmployeeInfoService.selectPersonalId.next(userPofile?.employeeInfoId);
      this.router
      .navigateByUrl(`/home`, { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl('/per/per-employee');
        // this.router.navigateByUrl(url);
      });
      
    }
    
  }

  goToPost() {
    this.router.navigateByUrl('/post');
  }

  logout() {
    let hash = this.sessionStorage?.getHash();
    let user = this.sessionStorage.getUserProfile();
    let linkageToken : any = this.sessionStorage.getLinkageToken();
    let params = {
      hash: hash ? hash : '',
      userKey: user?.userKey,
    };
    this.utils.spinnerShow();

    if(linkageToken){
      return this.dopaService.logoutLinkageV2().pipe(
        concatMap((resp:any)=>{
          return this.authService.logout(params)
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (resp: any) => {
          if (resp && resp?.status && resp?.status == 200) {
            this.sessionStorage.clearStorage();
            this.router.navigateByUrl('/login');
          }
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
        complete: () => {
          this.utils.spinnerHide();
        },
      });
    }else{
      return this.authService.logout(params).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (resp: any) => {
          if (resp && resp?.status && resp?.status == 200) {
            this.sessionStorage.clearStorage();
            this.router.navigateByUrl('/login');
          }
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
        complete: () => {
          this.utils.spinnerHide();
        },
      });
    }
  }
}
