import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';
import { DropdownItem } from '../generated-api/model/dropdownItem';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private baseApiUrl = `${environment.apiMstUrl}`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private httpClientService: HttpClientService,
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  getRole() {
    const URL: string = environment.apiAuthUrl + `Dropdown/GetRole`;
    return this.httpClientService.get(URL, false);
  }

  getApplication() {
    const URL: string = environment.apiAuthUrl + `Dropdown/GetApplication`;
    return this.httpClientService.get(URL, false);
  }

  getMstByKey(tableName?: any) {
    const URL: string =
      environment.apiAuthUrl + `Dropdown/GetMstByTableName/${tableName}`;
    return this.httpClientService.get(URL, false);
  }

  getPersonalGroup() {
    const URL: string = this.baseApiUrl + `Dropdown/PersonalGroup`;
    return this.httpClientService.get(URL, false);
  }

  getPersonalGroupByRole(roleId: any) {
    const URL: string = this.baseApiUrl + `Dropdown/MstPersonalGroupByRole/${roleId}`;
    return this.httpClientService.get(URL, false);
  }

  getPositionTechnical(){
    const URL: string = this.baseApiUrl + `Dropdown/MstPositionTechnical`;
    return this.httpClientService.get(URL, false);
  }

  getArmedForce() {
    const URL: string = this.baseApiUrl + `Dropdown/MstArmedForce`;
    return this.httpClientService.get(URL, false);
  }

  getMstTransactionType(key: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstTransactionType/${key}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrigin() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrigin`;
    return this.httpClientService.get(URL, false);
  }

  getMstOriginShortName() {
    const URL :string = environment.apiMstUrl + `Dropdown/MstOriginShortName`
    return this.httpClientService.get(URL, false);
  }

  getMstPersonnelType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPersonnelType`;
    return this.httpClientService.get(URL, false);
  }

  getMstOfficerType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOfficerType`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnitType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgUnitType`;
    return this.httpClientService.get(URL, false);
  }

  getMstPosition() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPosition`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionRank() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionRank`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionTechnical() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionTechnical`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionAllowance() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionAllowance`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionAllowanceDetail(id: string) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstPositionAllowanceDetail/${id}`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionRemark() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionRemark`;
    return this.httpClientService.get(URL, false);
  }

  getMstEmployeeJobGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEmployeeJobGroup`;
    return this.httpClientService.get(URL, false);
  }

  getDepartmentType() {
    const URL: string = environment.apiMstUrl + `Dropdown/DepartmentType`;
    return this.httpClientService.get(URL, false);
  }

  getMstDropdownByOrgType(orgUnit: any, mstOrgUnitParentID: any) {
    let URL: string =
      environment.apiMstUrl + `MstOrgUnit/DropdownByOrgType?orgUnit=${orgUnit}`;
    mstOrgUnitParentID
      ? (URL += `&mstOrgUnitParentID=${mstOrgUnitParentID}`)
      : '';
    return this.httpClientService.get(URL, false);
  }

  getMstDropdownByOrgTypeWithNoAccessLevel(orgUnit: any, mstOrgUnitParentID: any) {
    let URL: string =
      environment.apiMstUrl + `MstOrgUnit/DropdownByOrgTypeWithNoAccessLevel?orgUnit=${orgUnit}`;
    mstOrgUnitParentID
      ? (URL += `&mstOrgUnitParentID=${mstOrgUnitParentID}`)
      : '';
    return this.httpClientService.get(URL, false);
  }

  getMstTitleType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstTitleType`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionActing() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionActing`;
    return this.httpClientService.get(URL, false);
  }

  getMstPositionStatus() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPositionStatus`;
    return this.httpClientService.get(URL , false)
  }

  getMstTransactionTypeDetail(key: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstTransactionTypeDetail/${key}`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryRank() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstMilitaryRank`;
    return this.httpClientService.get(URL, false);
  }

  getMstTitle() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstTitle`;
    return this.httpClientService.get(URL, false);
  }

  getMstRace() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstRace`;
    return this.httpClientService.get(URL, false);
  }

  getMstNationality() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstNationality`;
    return this.httpClientService.get(URL, false);
  }

  getMstNationalityBy() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstNationalityBy`;
    return this.httpClientService.get(URL, false);
  }

  getMstProvince() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstProvince`;
    return this.httpClientService.get(URL, false);
  }

  getMstDistrict(key: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstDistrict/${key}`;
    return this.httpClientService.get(URL, false);
  }

  getMstSubDistrict(key: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstSubDistrict/${key}`;
    return this.httpClientService.get(URL, false);
  }
  getBudgetYear() {
    const URL: string = environment.apiMstUrl + `Dropdown/BudgetYear`;
    return this.httpClientService.get(URL, false);
  }

  getMstAppointment() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAppointment`;
    return this.httpClientService.get(URL, false);
  }

  getMstCadetType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCadetType`;
    return this.httpClientService.get(URL, false);
  }

  getMstArmedForceShortName() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstArmedForceShortName`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstCorps() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCorps`;
    return this.httpClientService.get(URL, false);
  }

  getMstCorpsByArmedForcePartyId(mstArmedForceId: any, mstPartyId:any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCorps/${mstArmedForceId}/${mstPartyId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstCorpsById(mstArmedForceId: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCorps/${mstArmedForceId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstGender() {
    const URL: string = environment.apiMstUrl + `Dropdown/Gender`;
    return this.httpClientService.get(URL, false);
  }

  getMstAcademyType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAcademyType`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstRegulation() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstRegulation`;
    return this.httpClientService.get(URL, false);
  }

  getMstRegulationById(key: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstRegulation/${key}`;
    return this.httpClientService.get(URL, false);
  }

  getMstAcademy() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAcademy`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstCountry() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCountry`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstCountryForPer() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCountry`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduLevel() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduLevel`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduQualification() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduQualification`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduSubject(param: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstEduSubject/${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduSubjectMinorAll() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduSubjectMinor`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduSubjectMinor(param: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstEduSubjectMinor/${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstArmedForce() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstArmedForce`;
    return this.httpClientService.get(URL, false);
  }
  getMstEduSubjectGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduSubjectGroup`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstTrainingAttend() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstTrainingAttend`;
    return this.httpClientService.get(URL, false);
  }

  getGender() {
    const URL: string = environment.apiMstUrl + `Dropdown/Gender`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryCourse() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstMilitaryCourse`;
    return this.httpClientService.get(URL, false);
  }

  getMstProvinceReturnCode() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstProvinceReturnCode`;
    return this.httpClientService.get(URL, false);
  }

  getMstForeignCadetSalary(key: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstForeignCadetSalary/${key}`;
    return this.httpClientService.get(URL, false);
  }
  getMstSalaryLevel() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstSalaryLevel`;
    return this.httpClientService.get(URL, false);
  }

  getMstSalaryDetail(param: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstSalaryDetail/${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderStatus(transactionTypeId: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstOrderStatus/${transactionTypeId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstTransactionStatus(transactionTypeId: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstTransactionStatus/${transactionTypeId}`;
    return this.httpClientService.get(URL, false);
  }

  // Step 3 - 4 -5
  geMstOrder() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrder`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderSubject(param: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstOrderSubject/${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderSignedCopy(param: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstOrderSignedCopy?mstTransactionTypeId=${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderPrintReview(param: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstOrderPrintReview?mstTransactionTypeId=${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderEnding() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrderEnding`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderAuthorized() {
    const URL: string =
      environment.apiMstUrl + `MstOrderAuthorized/GetMstOrderAuthorized`;
    return this.httpClientService.get(URL, false);
  }

  getMstTransactionTypeAll() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstTransactionType`;
    return this.httpClientService.get(URL, false);
  }

  getMstCadetGen() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCadetGen`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnit() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgUnit`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnitAeccess() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgUnitAeccess`;
    return this.httpClientService.get(URL, false);
  }

  getMstMarriageStatus() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstMarriageStatus`;
    return this.httpClientService.get(URL, false);
  }

  getMstAdHocPosition(param: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstAdHocPosition/${param}`;
    return this.httpClientService.get(URL, false);
  }

  getMstAdHocPositionWithFullName(mstOrgUnitID: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstAdHocPositionWithFullName/${mstOrgUnitID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstInsignia(insigniaType: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstInsignia/${insigniaType}`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrderType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrderType`;
    return this.httpClientService.get(URL, false);
  }

  getPositionRemark() {
    const URL: string = environment.apiMstUrl + `Dropdown/PositionRemark`;
    return this.httpClientService.get(URL, false);
  }

  getMstParty() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstParty`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnitByOrgUnitTypeID(mstOrgUnitTypeID: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstOrgUnit/${mstOrgUnitTypeID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduLevelGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduLevelGroup`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduQualificationByEduLevelID(mstEduLevelID: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstEduQualification/${mstEduLevelID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduSubjectByQualificationId(mstEduQualificationId: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstEduSubject/${mstEduQualificationId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstProfessionalLicense() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstProfessionalLicense`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstEduRemark() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduRemark`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstEduRightness() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduRightness`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstReward() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstReward`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstRewardRemark() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstRewardRemark`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getRewardMonth() {
    const URL: string = environment.apiMstUrl + `Dropdown/RewardMonth`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstPeriod(mstTransactionTypeID: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstPeriod/${mstTransactionTypeID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstSalaryPositionDetail() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstSalaryPositionDetail`;
    return this.httpClientService.get(URL, false);
  }

  getMstRetirementType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstRetirementType`;
    return this.httpClientService.get(URL, false);
  }

  getMstResignedMilitaryType() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstResignedMilitaryType`;
    return this.httpClientService.get(URL, false);
  }

  getMstResignedProfitType() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstResignedProfitType`;
    return this.httpClientService.get(URL, false);
  }

  getMstDischargeYear() {
    const URL: string = environment.apiMstUrl + `Dropdown/DischargeYear`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnitByParentID(parentID: any) {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstOrgUnitByParentID/${parentID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstTransactionSubType(transactionTypeID: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstTransactionSubType/${transactionTypeID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstTransactionTypeId(applicationCode: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstTransactionTypeId/${applicationCode}`;
    return this.httpClientService.get(URL, false);
  }

  getMstWeaponType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstWeaponType`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstWeaponCourse() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstWeaponCourse`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getAssistUnit() {
    const URL: string = environment.apiMstUrl + `Dropdown/AssistUnit`;
    return this.httpClient.get<DropdownItem[]>(URL, { headers: this.headers });
  }

  getMstUnitExternalMOD() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstUnitExternalMOD`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryAcademy() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstMilitaryAcademy`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryCourseGroup() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstMilitaryCourseGroup`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryCourseType() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstMilitaryCourseType`;
    return this.httpClientService.get(URL, false);
  }

  getMstEmployeeSalaryLevel() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstEmployeeSalaryLevel`;
    return this.httpClientService.get(URL, false);
  }

  getApplicationType() {
    const URL: string = environment.apiUsrUrl + `Dropdown/GetApplicationType`;
    return this.httpClientService.get(URL, false);
  }

  getApplicationID(id: any) {
    const URL: string = environment.apiUsrUrl + `Dropdown/Application/${id}`;
    return this.httpClientService.get(URL, false);
  }

  getMstVacationType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstVacationType`;
    return this.httpClientService.get(URL, false);
  }

  getMstReligion() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstReligion`;
    return this.httpClientService.get(URL, false);
  }

  getMstCareer() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstCareer`;
    return this.httpClientService.get(URL, false);
  }

  getMstBloodGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstBloodGroup`;
    return this.httpClientService.get(URL, false);
  }

  getCheckWith() {
    const URL: string = environment.apiMstUrl + `Dropdown/CheckWith`;
    return this.httpClientService.get(URL, false);
  }

  getMstGovEmpJobGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstGovEmpJobGroup`;
    return this.httpClientService.get(URL, false);
  }

  getMstWageFactorType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstWageFactorType`;
    return this.httpClientService.get(URL, false);
  }

  getMstOrgUnitExt() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgUnitExt`;
    return this.httpClientService.get(URL, false);
  }

  getMstMedalType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstMedalType`;
    return this.httpClientService.get(URL, false);
  }

  getMstSalarySpecial() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstSalarySpecial`;
    return this.httpClientService.get(URL, false);
  }

  getMstEmployeeType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEmployeeType`;
    return this.httpClientService.get(URL, false);
  }

  getMstEmployeeGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEmployeeGroup`;
    return this.httpClientService.get(URL, false);
  }

  getMstEmployeeSalary() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEmployeeSalary`;
    return this.httpClientService.get(URL, false);
  }
  getVwAdHocPosition() {
    const URL: string = environment.apiMstUrl + `Dropdown/VwAdHocPosition`;
    return this.httpClientService.get(URL, false);
  }

  getMstPersonnelGroup() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPersonnelGroup`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryRankId(mstArmedForceID: any, mstPersonnelGroupID: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstMilitaryRank/${mstArmedForceID}/${mstPersonnelGroupID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstMilitaryRankIdArm(mstArmedForceID: any) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstMilitaryRank/${mstArmedForceID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstAcademicPosition() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAcademicPosition`;
    return this.httpClientService.get(URL, false);
  }

  getMstGovEmpPrecedence() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstGovEmpPrecedence`;
    return this.httpClientService.get(URL, false);
  }

  getMstGovEmpSource() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstGovEmpSource`;
    return this.httpClientService.get(URL, false);
  }

  getMstGovEmpOrigin() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstGovEmpOrigin`;
    return this.httpClientService.get(URL, false);
  }

  getMstRetaliatePromotion() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstRetaliatePromotion`;
    return this.httpClientService.get(URL, false);
  }

  getMstSpecialRetaliate() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstSpecialRetaliate`;
    return this.httpClientService.get(URL, false);
  }

  getMstAppointType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAppointType`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptSoldierAppointType() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstAptSoldierAppointType`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptReturnAppointType() {
    const URL: string =
      environment.apiMstUrl + `Dropdown/MstAptReturnAppointType`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptPromoteType() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAptPromoteType`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptEduSalaryId() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAptEduSalaryId`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptForeigncadetSalary(courseTypeId: number) {
    const URL: string =
      environment.apiMstUrl +
      `Dropdown/MstAptForeigncadetSalary/${courseTypeId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstAptRegulationPmra() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAptRegulationPmra`;
    return this.httpClientService.get(URL, false);
  }

  getMstAdHocPositionWithPositionRank(mstOrgUnitID: number) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstAdHocPositionWithPositionRank/${mstOrgUnitID}`;
    return this.httpClientService.get(URL, false);
  }

  getMstPersonelGroupAccess(data?: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstPersonalGroupAccess`;
    return this.httpClientService.get(URL, false);
  }

  getMstSalaryRemark(data?: any) {
    const URL: string = environment.apiMstUrl + `Dropdown/MstSalaryRemark`;
    return this.httpClientService.get(URL, false);
  }

  getOrgUnitByRoleId(id:any){
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgUnitByRoleOrgUnit/${id}`;
    return this.httpClientService.get(URL, false);
  }

  getChildOrgUnitByRoleId(roleId:any,mstOrgUnitId:any){
    const URL: string = environment.apiMstUrl + `Dropdown/MstOrgChildUnitByRole/${roleId}/${mstOrgUnitId}`;
    return this.httpClientService.get(URL, false);
  }

  getMstEduEvidenceComment() {
    const URL: string = environment.apiMstUrl + `Dropdown/MstEduEvidenceComment`;
    return this.httpClientService.get(URL, false);
  }
}
