<div class="text-purple-900 font-bold mb-5">
    <i class="fa-light fa-house pr-2"></i>
    <span>ระบบงานข้อมูล{{menuName}} / </span>
    <span>สำเนาแจกจ่าย /</span>
    <span> รายละเอียดสำเนาแจกจ่าย </span>
</div>

<div class="card">
    <ng-container>
        <form [formGroup]="dataForm">
            <h5 class="text-purple-900">หัวเรื่องคำสั่ง</h5>
            <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-3">
                    <label>ประเภทรายการ</label>
                    <p-dropdown [options]="transactionTypeList" optionValue="value" optionLabel="text" [filter]="true"
                        filterBy="name" [showClear]="true" placeholder="เลือก" (onChange)="getTransactionSubType()"
                        formControlName="transactionTypeId">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-3">
                    <label>ประเภทรายการย่อย</label>
                    <p-dropdown [options]="transactionSubTypeList" optionValue="value" optionLabel="text"
                        [filter]="true" filterBy="name" [showClear]="true" placeholder="เลือก"
                        (onChange)="getOderSubject()" formControlName="transactionSubTypeId">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label>หัวเรื่องคำสั่ง</label>
                    <p-dropdown [options]="orderSubjectList" optionLabel="subject" optionValue="orderSubjectId"
                        [filter]="true" filterBy="text" appendTo="body" [showClear]="true" [autoDisplayFirst]="false"
                        placeholder="เลือก" class="w-full" styleClass="w-full"
                        [virtualScroll]="orderSubjectList.length > 6 ? true : false" [virtualScrollItemSize]="30"
                        formControlName="orderSubjectId" (onChange)="setOderSubject()">
                    </p-dropdown>
                </div>

                <div class="field col-12 md:col-3">
                    <label>เลขที่ชุด</label>
                    <input pInputText formControlName="orderSetNo" />
                </div>
                <div class="field col-12 md:col-3">
                    <label>สถานะ</label>
                    <div class="bg-green-500 text-white text-center font-bold p-2 border-round w-12rem">
                        {{
                        this.dataForm.get("orderStatusName")?.value == null
                        ? "รออนุมัติ"
                        : this.dataForm.get("orderStatusName")?.value
                        }}
                    </div>
                </div>
            </div>

            <hr />

            <h5 class="text-purple-900">การอนุมัติคำสั่ง</h5>
            <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-3">
                    <label>ผู้มีอำนาจออกคำสั่ง</label>
                    <p-autoComplete [dropdown]="true" [suggestions]="filterOrderSigned"
                        formControlName="orderSignedName" optionValue="value" [showClear]="true"
                        (completeMethod)="filterOrderSignedId($event)" (onSelect)="getDataStampValue()"
                        (onClear)="this.dataForm.get('adHocPositionShortName')?.reset()" field="fullName">
                    </p-autoComplete>
                </div>
                <div class="field col-12 md:col-3">
                    <label>ตำแหน่งผู้อนุมัติ</label>
                    <input pInputText formControlName="adHocPositionShortName" />
                </div>
                <div class="field col-12 md:col-3">
                    <label>เลขที่คำสั่ง</label>
                    <input pInputText formControlName="commandText" />
                </div>
                <div class="field col-12 md:col-3">
                    <label>ลงวันที่/เดือน/ปี</label>
                    <div class="p-inputgroup">
                        <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี"
                            [matDatepicker]="commandDate1" (click)="commandDate1.open()"
                            formControlName="commandDate" />
                        <button type="button" pButton icon="fa-light fa-calendar"
                            (click)="commandDate1.open()"></button>
                    </div>
                    <mat-datepicker #commandDate1></mat-datepicker>
                </div>
            </div>

            <h5 class="text-purple-900">รายละเอียดสำเนาเเจกจ่าย</h5>
            <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-3">
                    <label>ผู้ลงนามในสำเนา</label>
                    <p-dropdown [options]="orderSignedCopyList" optionLabel="fullName" optionValue="fullName" [filter]="true"
                        filterBy="fullName" appendTo="body" [showClear]="true" [autoDisplayFirst]="false"
                        placeholder="เลือก" class="w-full" styleClass="w-full"
                        [virtualScroll]="orderSignedCopyList.length > 6 ? true : false" [virtualScrollItemSize]="30"
                        formControlName="orderSignedCopyName" (onChange)="getOrderSignedCopy()">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-3">
                    <label>ผู้พิมพ์ทาน</label>
                    <p-dropdown [options]="orderSignedPrintList" optionLabel="fullName" optionValue="fullName"[filter]="true"
                        filterBy="fullName" appendTo="body" [showClear]="true" [autoDisplayFirst]="false"
                        placeholder="เลือก" class="w-full" styleClass="w-full"
                        [virtualScroll]="orderSignedPrintList.length > 6 ? true : false" [virtualScrollItemSize]="30"
                        formControlName="orderSignedPrintName">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-3">
                    <label>วันที่</label>
                    <div class="p-inputgroup">
                        <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี"
                            [matDatepicker]="copyDate1" (click)="copyDate1.open()" formControlName="signedCopyDate" />
                        <button type="button" pButton icon="fa-light fa-calendar" (click)="copyDate1.open()"></button>
                    </div>
                    <mat-datepicker #copyDate1></mat-datepicker>
                </div>
                <div class="field col-12 md:col-3">
                    <label>ทั้งนี้ตั้งเเต่</label>
                    <div class="p-inputgroup">
                        <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี"
                            [matDatepicker]="effectiveDate" (click)="effectiveDate.open()"
                            formControlName="effectiveDate" />
                        <button type="button" pButton icon="fa-light fa-calendar"
                            (click)="effectiveDate.open()"></button>
                    </div>
                    <mat-datepicker #effectiveDate></mat-datepicker>
                </div>
            </div>
            <hr />

            <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-6">
                    <label>หมายเหตุ</label>
                    <input pInputText formControlName="remark" />
                </div>
            </div>

            <div class="field col-12 md:col-12 mt-2 mb-0 pb-1">
                <h5 for="uContent" class="text-purple-900">คำสั่ง</h5>
                <p-editor id="uContent" [style]="{ height: '10rem' }" formControlName="content" #pEditor>
                    <ng-template pTemplate="header">
                        <span class="ql-formats">
                        </span>
                    </ng-template>
                </p-editor>
            </div>
        </form>
        <div class="flex justify-content-center gap-3 my-3">
            <p-button label="พิมพ์คำสั่งยกร่าง" icon="fa-light fa-print" iconPos="left" size="small"
                styleClass="bg-purple-900 w-12rem" (onClick)="print()"></p-button>
            <p-button label="กลับ" icon="fa-light fa-arrow-left" iconPos="left" [outlined]="true" severity="danger"
            size="small" styleClass="w-10rem" (onClick)="close()"></p-button>
        </div>
        <div class="mt-6">
            <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true"
                [showCurrentPageReport]="true" [paginator]="true" [(selection)]="selectedData" [rows]="rows"
                [rowsPerPageOptions]="rowsPerPageOptions" styleClass="p-datatable-sm"
                currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
                <ng-template pTemplate="caption">
                    <div class="grid my-0">
                        <div class="col-12 md:col-4">
                            <h5 class="text-purple-900 mb-0">รายชื่อยกร่างคำสั่ง</h5>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr class="white-space-nowrap">
                        <th pSortableColumn="number">
                            <span>ลำดับ</span>
                            <p-sortIcon field="number"></p-sortIcon>
                        </th>
                        <th pSortableColumn="militaryID">
                            <span>เลขประจำตัวทหาร</span>
                            <p-sortIcon field="militaryID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="citizenID">
                            <span>เลขประจำตัวประชาชน</span>
                            <p-sortIcon field="citizenID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fullName">
                            <span>ยศ-ชื่อ-นามสกุล</span>
                            <p-sortIcon field="fullName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTransactionType">
                            <span>ประเภทรายการ</span>
                            <p-sortIcon field="nameTransactionType"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTransactionSubType">
                            <span>ประเภทรายการย่อย</span>
                            <p-sortIcon field="nameTransactionSubType"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameArmedForce">
                            <span>หน่วยงาน</span>
                            <p-sortIcon field="nameArmedForce"></p-sortIcon>
                        </th>
                        <th pSortableColumn="educational">
                            <span>คุณวุฒิการศึกษา</span>
                            <p-sortIcon field="educational"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameAppointMilitaryCourse">
                            <span>เเต่งตั้งยศเป็น</span>
                            <p-sortIcon field="nameAppointMilitaryCourse"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameCorps">
                            <span>พรรค/เหล่า</span>
                            <p-sortIcon field="nameCorps"></p-sortIcon>
                        </th>
                        <th pSortableColumn="shortNameArmedForce">
                            <span>ตำเเหน่ง</span>
                            <p-sortIcon field="shortNameArmedForce"></p-sortIcon>
                        </th>
                        <th pSortableColumn="rate">
                            <span>อัตรา</span>
                            <p-sortIcon field="rate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="salary">
                            <span>ระดับเงินเดือน</span>
                            <p-sortIcon field="salary"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nameTrainingAttend">
                            <span>คุณสมบัติ</span>
                            <p-sortIcon field="nameTrainingAttend"></p-sortIcon>
                        </th>
                        <th alignFrozen="right" pFrozenColumn [frozen]="true"></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-data let-i="rowIndex">
                    <tr class="white-space-nowrap">
                        <td>{{ i + 1 }}</td>
                        <td>{{ data.militaryId }}</td>
                        <td>{{ data.citizenId }}</td>
                        <td>{{ data.fullName }}</td>
                        <td>{{ data.nameTransactionType }}</td>
                        <td>{{ data.nameTransactionSubType }}</td>
                        <td>{{ data.unitShortName }}</td>
                        <td>{{ data.educational }}</td>
                        <td>{{ data.nameAppointMilitaryCourse }}</td>
                        <td>{{ data.nameCorps }}</td>
                        <td>{{ data.shortNameArmedForce }}</td>
                        <td>{{ data.rate }}</td>
                        <td>{{ data.salary }}</td>
                        <td>{{ data.nameTrainingAttend }}</td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                    <ng-container *ngIf="dataSource.length <= 0">
                        <tr>
                            <td colspan="14" class="text-left bg-white">ไม่มีข้อมูล</td>
                        </tr>
                    </ng-container>
                </ng-template>
            </p-table>
        </div>
    </ng-container>
</div>
