import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormatDateThaiPipe,
  FormatDateTimeThaiPipe,
  FormatShortDateMonthPipe,
  FormatShortDatePipe,
  FormatShortDateThaiPipe,
  FormatShortDateTimePipe,
  FormatShortDateTimeThaiPipe,
  FormatShortDateTimeWithSecondPipe,
  FormatShortDateTimeWithSecondThaiPipe,
  FormatShortMonthPipe,
  FormatTimeThaiPipe,
  FormatMonthThaiPipe
} from './pipes/formatdate.pipe';
import { NewlinePipe } from './pipes/newline.pipe';
import {DecimalWithCommaPipe,NumberWithCommaPipe,} from './pipes/number-with-comma.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { InputDecimalDirective } from './directive/input-decimal.directive';
import { InputNumberDirective } from './directive/input-number.directive';
import { UploadFileDirective } from './directive/upload-file.directive';
import { ConvertToUpperCaseDirective } from './directive/convert-to-upper-case.directive';
import { ConvertToUpperCaseWithNumberDirective } from './directive/convert-to-upper-case-with-number.directive';
import { ConvetToEngDirective } from './directive/convet-to-eng.directive';
import { ErrorMsgDirective } from './directive/error-msg.directive';
import { FormSubmitValidationDirective } from './directive/form-submit-validation.directive';

@NgModule({
  declarations: [
    FormatShortDatePipe,
    FormatShortDateMonthPipe,
    FormatShortDateTimePipe,
    FormatShortDateTimeWithSecondPipe,
    FormatDateThaiPipe,
    FormatTimeThaiPipe,
    FormatShortMonthPipe,
    FormatDateTimeThaiPipe,
    FormatShortDateThaiPipe,
    FormatShortDateTimeThaiPipe,
    FormatShortDateTimeWithSecondThaiPipe,
    FormatMonthThaiPipe,
    NewlinePipe,
    SafePipe,
    NumberWithCommaPipe,
    DecimalWithCommaPipe,
    InputDecimalDirective,
    InputNumberDirective,
    UploadFileDirective,
    ConvertToUpperCaseDirective,
    ConvertToUpperCaseWithNumberDirective,
    ConvetToEngDirective,
    ErrorMsgDirective,
    FormSubmitValidationDirective,
  ],
  imports: [CommonModule],
  exports: [
    FormatShortDatePipe,
    FormatShortDateMonthPipe,
    FormatShortDateTimePipe,
    FormatShortDateTimeWithSecondPipe,
    FormatDateThaiPipe,
    FormatTimeThaiPipe,
    FormatShortMonthPipe,
    FormatDateTimeThaiPipe,
    FormatShortDateThaiPipe,
    FormatShortDateTimeThaiPipe,
    FormatShortDateTimeWithSecondThaiPipe,
    FormatMonthThaiPipe,
    NewlinePipe,
    SafePipe,
    NumberWithCommaPipe,
    DecimalWithCommaPipe,
    InputDecimalDirective,
    InputNumberDirective,
    UploadFileDirective,
    ConvertToUpperCaseDirective,
    ConvertToUpperCaseWithNumberDirective,
    ConvetToEngDirective,
    ErrorMsgDirective,
    FormSubmitValidationDirective,
  ],
  providers: [
    FormatShortDatePipe,
    FormatShortDateTimePipe,
    FormatShortDateTimeWithSecondPipe,
    FormatDateThaiPipe,
    FormatTimeThaiPipe,
    FormatShortMonthPipe,
    FormatDateTimeThaiPipe,
    FormatShortDateThaiPipe,
    FormatShortDateTimeThaiPipe,
    FormatShortDateTimeWithSecondThaiPipe,
    FormatMonthThaiPipe,
    NewlinePipe,
    SafePipe,
    NumberWithCommaPipe,
    DecimalWithCommaPipe,
  ],
})
export class CoresModule {}
