import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewlinePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/(?:\r\n|\r|\n|,)/g, '<br/>');
  }
}
