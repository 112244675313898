import { ContextMenuModule } from 'primeng/contextmenu';
import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { StyleClassModule } from 'primeng/styleclass';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { ChartModule } from 'primeng/chart';
import { KnobModule } from 'primeng/knob';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { TabMenuModule } from 'primeng/tabmenu';
import { StepsModule } from 'primeng/steps';
import { SkeletonModule } from 'primeng/skeleton';
import { PickListModule } from 'primeng/picklist';
import { FileUploadModule } from 'primeng/fileupload';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { TreeDragDropService } from 'primeng/api';

@NgModule({
  imports: [
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    ButtonModule,
    TooltipModule,
    RippleModule,
    MenuModule,
    DropdownModule,
    DividerModule,
    DialogModule,
    DynamicDialogModule,
    StyleClassModule,
    CardModule,
    TableModule,
    PanelModule,
    TreeTableModule,
    CheckboxModule,
    TagModule,
    TabMenuModule,
    StepsModule,
    SkeletonModule,
    PickListModule,
    FileUploadModule,
    SpeedDialModule,
    SplitButtonModule,
    ChipsModule,
    FieldsetModule,
    ChartModule,
    KnobModule,
    ToastModule,
    ContextMenuModule,
  ],
  exports: [
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    ButtonModule,
    TooltipModule,
    RippleModule,
    MenuModule,
    DropdownModule,
    DividerModule,
    DialogModule,
    DynamicDialogModule,
    StyleClassModule,
    CardModule,
    TableModule,
    PanelModule,
    TreeTableModule,
    CheckboxModule,
    TagModule,
    TabMenuModule,
    StepsModule,
    SkeletonModule,
    PickListModule,
    FileUploadModule,
    SpeedDialModule,
    SplitButtonModule,
    ChipsModule,
    FieldsetModule,
    ChartModule,
    KnobModule,
    ToastModule,
    TreeModule,
    ContextMenuModule,
  ],
  providers: [
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    TreeDragDropService,
  ],
})
export class PrimengModule {}
