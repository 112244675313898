import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DropdownAppService {
  constructor(private httpClient: HttpClientService) {}

  getTransactionType() {
    const URL: string = environment.apiAppUrl + `Dropdown/TransactionType`;
    return this.httpClient.get(URL, false);
  }

  getTransactionTypeId(id: any) {
    const URL: string =
      environment.apiAppUrl + `Dropdown/TransactionType/${id}`;
    return this.httpClient.get(URL, false);
  }

  getTransactionSubType(applicationId: any, transactionTypeId: any) {
    const URL: string =
      environment.apiAppUrl +
      `Dropdown/TransactionSubType/${applicationId}/${transactionTypeId}`;
    return this.httpClient.get(URL, false);
  }

  getMstPersonnelGroup() {
    const URL: string = environment.apiOrdUrl + `Dropdown/MstPersonalGroup`;
    return this.httpClient.get(URL, false);
  }

  getOrderStatus() {
    const URL: string = environment.apiOrdUrl + `Dropdown/OrderStatus`;
    return this.httpClient.get(URL, false);
  }

  getOrderSignedCopy(id: any) {
    const URL: string =
      environment.apiOrdUrl + `Dropdown/OrderSignedCopy/${id}`;
    return this.httpClient.get(URL, false);
  }

  getOrderSignedPrint(id: any) {
    const URL: string =
      environment.apiOrdUrl + `Dropdown/OrderSignedPrint/${id}`;
    return this.httpClient.get(URL, false);
  }
}
