import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalListService {

  constructor(private httpClient: HttpClientService) { }

  searchTempPersonalList(params: any) {
    const URL: string = environment.apiTempUrl + `PersonalList/Search`;
    return this.httpClient.post(URL, params, false);
  }

  searchTransPersonalList(params: any) {
    const URL: string = environment.apiTransUrl + `TransferDetail/SearchPersonalList`;
    return this.httpClient.post(URL, params, false);
  }

  searchPernonalTemp(params: any) {
    const URL: string = environment.apiTempUrl + `PersonalList/SearchPersonalTemporary`;
    return this.httpClient.post(URL, params, false);
  }

  getVwPersonalRetireList(params:any){
    const URL: string = environment.apiDiscUrl + `RetirementDetail/VwPersonalRetireList`;
    return this.httpClient.post(URL, params, false);
  }

  getVwPersonalReClassifyList(params:any){
    const URL: string = environment.apiDiscUrl + `RetirementDetail/VwPersonalReClassifyList`;
    return this.httpClient.post(URL, params, false);
  }
}
