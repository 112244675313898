import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, concatMap, of, Subject, takeUntil } from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ImportFileTempService } from 'src/app/services/api/temp/import-file-temp.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-history-import-file',
  templateUrl: './modal-history-import-file.component.html',
  styleUrls: ['./modal-history-import-file.component.scss']
})
export class ModalHistoryImportFileComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  dataForm: FormGroup;
  applicationId: any;
  transactionId: any;
  transactionTypeId: any;

  dataMain: any[] = [];
  dataSourceDetail: any[] = [];
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private httpErrorHandlerService : HttpErrorHandlerService,
    private importFileTempService : ImportFileTempService
    
  ){
    this.dataForm = this.formBuilder.group({
      applicationId: [],
      transactionId: [],
      transactionTypeId: [],
    });
  }

  ngOnInit(){
    this.utils.spinnerShow()
    this.getDataDialog().pipe(
      concatMap(()=>{
        return this.searchInitial();
      }),
    ).subscribe({
      next: () => {
        this.utils.spinnerHide()
      },
      error: (err:any) => {
        this.utils.spinnerHide()
        this.httpErrorHandlerService.handleError(err)
      },
      complete: () => {
        takeUntil(this.unsubscribe$)
      }
    })
  }

  getDataDialog(){
    let dialogData = this.dynamicDialogConfig.data;
    if (dialogData) {
      this.applicationId = dialogData?.applicationId;
      this.transactionId = dialogData?.transactionId;
      this.transactionTypeId = dialogData?.transactionTypeId;
      this.dataForm.get('applicationId')?.setValue(dialogData?.applicationId ? dialogData?.applicationId : null)
      this.dataForm.get('transactionId')?.setValue(dialogData?.transactionId ? dialogData?.transactionId : null)
      this.dataForm.get('transactionTypeId')?.setValue(dialogData?.transactionTypeId ? dialogData?.transactionTypeId : null)
    }
    return of(null)
  }

  searchInitial(){
    let params = this.dataForm.getRawValue()
    return this.importFileTempService.getMainHistoryTemp(params).pipe(
      concatMap((resp:any)=>{
        if (resp?.data) {
          this.dataMain = resp.data;
        }else{
          this.dataMain = [];
        }
        return of(null)
      })
    )
  }

  getDetail(impTransactionDetailId:any){
    this.utils.spinnerShow()
    let params = {
      impTransactionDetailId : impTransactionDetailId
    }
    this.importFileTempService.getHistoryTemp(params).subscribe({
      next: (resp:any) => {
        if(resp){
          this.dataSourceDetail = resp?.data
        }else{
          this.dataSourceDetail = []
        }
      },
      error: (err:any) => {
        this.utils.spinnerHide()
        this.httpErrorHandlerService.handleError(err)
      },
      complete: () => {
        this.utils.spinnerHide()
        takeUntil(this.unsubscribe$)
      }
    })
  }

  close(type: any){
    this.dynamicDialogRef.close(type);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
