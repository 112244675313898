import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
// import { ModalTemporaryComponent } from '../modal-temporary/modal-temporary.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  debounceTime,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TableLazyLoadEvent } from 'primeng/table';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { DropdownService } from 'src/app/services/api/dropdown.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { OrderService } from 'src/app/services/api/asm/order.service';
import { Router } from '@angular/router';
import { OrderDraftTempService } from 'src/app/services/api/temp/order-draft-temp.service';
import { DropdownAppService } from 'src/app/services/api/app/dropdown-app.service';
import { SharedService } from 'src/app/services/api/app/shared.service';
import { ActivatedRoute } from '@angular/router';
import { PermissionUserService } from 'src/app/core/services/permission-user.service';

@Component({
  selector: 'app-approve-order',
  templateUrl: './approve-order.component.html',
  styleUrls: ['./approve-order.component.scss']
})
export class ApproveOrderComponent {
  pathName: string = 'อนุมัติคำสั่ง';
  search$ = new BehaviorSubject<any>(null);
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  applicationId: number | undefined;
  step: string = '4';
  dataSource: any[] = [];
  transactionTypeList: any[] = [];
  orgUnitId1List: any[] = [];
  statusList: any[] = [];
  subjectList: any[] = [];
  selectedData: any = null;
  dataForm: FormGroup;
  transactionSubTypeList: any[] = [];
  orderSubjectList: any[] = [];
  modalTemporary: DynamicDialogRef | undefined;
  menuName: string = '';
  menuCode: any = null;
  permissionGiveIn: any = null;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private orderService: OrderService,
    private utils: UtilsService,
    private dropdownService: DropdownService,
    private router: Router,
    private orderDraftTempService: OrderDraftTempService,
    private dropdownAppService: DropdownAppService,
    private sharedService: SharedService,
    private permissionUserService : PermissionUserService,
    private activatedRoute: ActivatedRoute,

  ) {
    this.dataForm = this.formBuilder.group({
      commandDate: [], // วันที่สั่งF
      effectiveDate: [], // วันที่มีผล
      orderSetNo: [],
      orgUnitId: [],
      transactionTypeId: [],
      transactionSubTypeId: [],
      citizenId: [],
      militaryId: [],
      firstName: [],
      lastName: [],
      orderSubjectId: [],
      orderStatusId: [3],
    });
  }

  ngOnInit() {
    this.menuName = this.utils.getMenuName(this.router?.url);
    this.applicationId = this.utils.getApplicationCodeByRouter(this.router?.url);
    this.utils.spinnerShow();
    this.getDropdown()
      .pipe(
        tap(() => {
          this.searchInitial();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.setPermission();
          this.searchAction();
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPermission(){
    let url = this.router?.url.substring(1);
    this.menuCode = this.permissionUserService.getMenuCodeWithPath(url);    
    this.permissionGiveIn = this.permissionUserService.getAllPermission(this.menuCode);
  }


  searchInitial() {
    this.search$
      .pipe(
        debounceTime(10),
        switchMap((params) => {
          this.utils.spinnerShow();
          if (params) {
            return this.orderDraftTempService.search(params);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (resp: any) => {
          if (resp?.data) {
            this.dataSource = resp.data;
            this.totalRecords = resp.total;
          } else {
            this.dataSource = [];
            this.totalRecords = 0;
          }
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  searchAction(event?: TableLazyLoadEvent) {
    this.first = event?.first ? event?.first : 0;
    this.sortField = event?.sortField ? event?.sortField : null;
    this.sortOrder = event?.sortOrder ? event?.sortOrder : 1;
    this.rows = event?.rows ? event?.rows : environment.rows;
    let body: any = this.dataForm.getRawValue();
    body.sortField = event?.sortField ? event?.sortField : null;
    body.sortType = event?.sortOrder === -1 ? 'DESC' : 'ASC';
    body.pageFirstIndex = event?.first ? event?.first : 0;
    body.pageTake = event?.rows ? event?.rows : this.rows;
    body.subject = body.subject ? body.subject.toString() : null;
    body.step = this.step;
    body.orderSetNo = body.orderSetNo ? body.orderSetNo : null;
    body.militaryId = body.militaryId ? body.militaryId : null;
    body.citizenId = body.citizenId ? body.citizenId : null;
    body.firstName = body.firstName ? body.firstName : null;
    body.lastName = body.lastName ? body.lastName : null;
    body.applicationId = this.applicationId;
    body.transactionTypeId = body.transactionTypeId != null ? body.transactionTypeId.toString() : null;
    body.orgUnitId = body.orgUnitId ? body.orgUnitId.toString() : null;
    if (body.commandDate) {
      body.commandDate = this.utils.convertFormatDateToParam(body.commandDate);
    }
    if (body.effectiveDate) {
      body.effectiveDate = this.utils.convertFormatDateToParam(
        body.effectiveDate
      );
    }
    if (this.search$.observed) {
      this.search$.next(body);
    } else {
      this.search$.next(body);
      this.searchInitial();
    }
  }

  getDropdown() {
    return this.dropdownAppService.getTransactionTypeId(this.applicationId).pipe(
      concatMap((resp: any) => {
        if (resp?.status == 200) {
          this.transactionTypeList = resp?.data;
        }
        let orgUnit = '';
        let mstOrgUnitParentID: any = '';
        return this.dropdownService.getMstDropdownByOrgType(
          orgUnit,
          mstOrgUnitParentID
        );
      }),
      switchMap((resp: any) => {
        if (resp?.data) {
          this.orgUnitId1List = resp.data;
        }
        return this.dropdownAppService.getOrderStatus();
      }),
      switchMap((resp: any) => {
        if (resp?.data) {
          this.statusList = resp.data;
        }
        return of(null);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  getTransactionSubType() {
    if (this.dataForm.get('transactionTypeId')?.value) {
      let transactionTypeId = this.dataForm.get('transactionTypeId')?.value;
      this.dropdownAppService
        .getTransactionSubType(this.applicationId, transactionTypeId)
        .subscribe({
          next: (resp: any) => {
            if (resp) {
              this.transactionSubTypeList = resp?.data;
            }
          },
          error: (err: any) => {
            this.utils.spinnerHide();
            this.httpErrorHandlerService.handleError(err);
          },
          complete: () => {
            takeUntil(this.unsubscribe$);
          },
        });
    } else {
      this.transactionSubTypeList = [];
      this.orderSubjectList = [];
      this.dataForm.get('transactionSubTypeId')?.reset();
      this.dataForm.get('orderSubjectId')?.reset();
    }
  }

  getOderSubject() {
    if (this.dataForm.get('transactionSubTypeId')?.value) {
      let param = {
        applicationId: this.applicationId,
        transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
        transactionSubTypeId: this.dataForm.get('transactionSubTypeId')?.value,
      };
      this.sharedService.getOderSubject(param).subscribe({
        next: (resp: any) => {
          if (resp?.data) {
            this.orderSubjectList = resp?.data;
          }
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
        complete: () => {
          takeUntil(this.unsubscribe$);
        },
      });
    } else {
      this.orderSubjectList = [];
      this.dataForm.get('orderSubjectId')?.reset();
    }
  }

  setOderSubject() {
    const valueOrderSubject = this.dataForm.get('orderSubjectId')?.value;
    const findValueOrderSubject = this.orderSubjectList.find(
      (item: any) => item.orderSubjectId == valueOrderSubject
    );
    this.dataForm.get('subject')?.setValue(findValueOrderSubject.subject);
  }

  cancel() {
    this.subjectList = [];
    this.dataForm.reset();
  }

  onChangeTransactionType(ev: DropdownChangeEvent) {
    this.dataForm.get('subject')?.reset();
    if (ev.value) {
      this.dropdownService
        .getMstOrderSubject(ev.value)
        .pipe(
          switchMap((resp: any) => {
            this.subjectList = resp.data;
            return this.dropdownService.getMstOrderStatus(ev.value);
          }),
          switchMap((resp: any) => {
            this.statusList = resp.data;
            return of(null);
          })
        )
        .subscribe({
          next: () => {},
          error: (err: any) => {
            this.httpErrorHandlerService.handleError(err);
          },
        });
    } else {
      this.subjectList = [];
      this.statusList = [];
    }
  }

  openModalDetail(id: any) {
    const baseUrl = this.router.url;
    const url = `${baseUrl}/${id}`;
    this.router.navigate([url]);
  }
}
