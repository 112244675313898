import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { BehaviorSubject, concatMap, debounceTime, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownService } from 'src/app/services/api/dropdown.service';
import { PersonalListService } from 'src/app/services/api/temp/personal-list.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-select-person-multi',
  templateUrl: './modal-select-person-multi.component.html',
  styleUrls: ['./modal-select-person-multi.component.scss']
})
export class ModalSelectPersonMultiComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  id: any;
  transactionTypeId: any = null;
  transactionSubTypeId: any = null;
  applicationId:any = null;
  dataForm: FormGroup;
  from:any = null;
  orgUnitId1List:any[] = [];
  orgUnitId2List:any[] = [];
  mstPositionRankList:any[] = [];
  roleData:any = null;

  dataSource: any[] = []
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  selectedData:any[] = [];
  yearList:any[] = [];
  mstPersonnelGroupList:any[] = [];

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private httpErrorHandlerService : HttpErrorHandlerService,
    private personalListService : PersonalListService,
    private dropdownService:DropdownService,
    private sessionStorageService:SessionStorageService
  ){
    this.dataForm = this.formBuilder.group({
      keyword: [null],
      year:null,
      mstOrgUnitId:null,
      mstOrgUnitParentId:null,
      mstPositionRankId:null,
      mstPersonnelGroupId:null,
      roleId:null,
    });
    this.roleData = this.sessionStorageService.getActiveRole();
    this.from = this.dynamicDialogConfig?.data?.from
    this.applicationId = this.dynamicDialogConfig?.data?.applicationId;
    if(this.dynamicDialogConfig?.data?.mstOrgUnitId){
      this.dataForm.get("mstOrgUnitId")?.setValue(this.dynamicDialogConfig?.data?.mstOrgUnitId);
      this.dataForm.get("mstOrgUnitId")?.disable();
    }
    if(this.dynamicDialogConfig?.data?.mstOrgUnitParentId){
      this.dataForm.get("mstOrgUnitParentId")?.setValue(this.dynamicDialogConfig?.data?.mstOrgUnitParentId);
      this.dataForm.get("mstOrgUnitParentId")?.disable();
    }
    if(this.dynamicDialogConfig?.data?.mstPersonnelGroupId){
      this.dataForm.get("mstPersonnelGroupId")?.setValue(this.dynamicDialogConfig?.data?.mstPersonnelGroupId);
      this.dataForm.get("mstPersonnelGroupId")?.disable();
    }
  }

  ngOnInit(){
    this.utils.spinnerShow()
    this.getDataDialog().pipe(
      tap(() => {
        this.searchInitial();
      }),
    ).subscribe({
      next: () => {
        this.searchAction();
      },
      error: (err:any) => {
        this.utils.spinnerHide()
        this.httpErrorHandlerService.handleError(err)
      },
      complete: () => {
        this.utils.spinnerHide();
        takeUntil(this.unsubscribe$)
      }
    })
  }

  onChangeOrgUnitId1(ev:DropdownChangeEvent){
    if (ev.value) {
      this.utils.spinnerShow();
      let mstOrgUnitParentID = ev.value;
      this.dropdownService
        .getChildOrgUnitByRoleId(this.roleData?.roleId, mstOrgUnitParentID)
        .pipe(
          switchMap((resp: any) => {
            this.orgUnitId2List = resp.data;
            return of(null);
          })
        )
        .subscribe({
          next: () => {},
          error: (err: any) => {
            this.utils.spinnerHide();
            this.httpErrorHandlerService.handleError(err);
          },
          complete: () => {
            this.utils.spinnerHide();
            takeUntil(this.unsubscribe$)
          }
        });
    } else {
      this.orgUnitId2List = [];
      this.dataForm.get('mstOrgUnitParentId')?.reset();
    }
  }

  getDataDialog(){
    let yearList = new Date().getFullYear();
    for(let i = (yearList-30) ; i <=  (yearList+33); i++){
      this.yearList.push({
        value:i,
        text:i+ 543
      });
    }
    return  this.dropdownService.getOrgUnitByRoleId(this.roleData?.roleId).pipe(
      concatMap((resp:any)=>{
        if(resp?.data){
          this.orgUnitId1List = resp.data;
        }
        return this.dropdownService.getMstMilitaryRank();
      }),
      concatMap((resp:any)=>{
        if(resp?.data){
          this.mstPositionRankList = resp.data;
        }
        return of(null);
      }),
      concatMap((resp:any)=>{
        if(resp?.data){
          this.orgUnitId2List = resp.data;
        }
        return this.dropdownService.getPersonalGroupByRole(this.roleData?.roleId);
      }),
      concatMap((resp:any)=>{
        if(resp?.data){
          this.mstPersonnelGroupList = resp.data;
        }
        return of(null);
      })
    )
  }

  searchInitial(){
    this.search$.pipe(
        debounceTime(10),
        concatMap((params) => {
          this.utils.spinnerShow()
          if (params) {
            if(this.applicationId == 7){
              return this.personalListService.searchTransPersonalList(params);
            }else{
              if(this.from == 'retire' || this.from == 'retire-military' || this.from == 'retire-military-before'){
                return this.personalListService.getVwPersonalRetireList(params);
              }else if(this.from == 'transfer-type'){
                return this.personalListService.getVwPersonalReClassifyList(params);
              }else{
                return this.personalListService.searchTempPersonalList(params);
              }
            }
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (resp) => {
          if (resp?.data) {
            this.dataSource = resp.data;
            this.totalRecords = resp.total;
          } else {
            this.dataSource = [];
            this.totalRecords = 0;
          }
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  searchAction(event?: TableLazyLoadEvent){
    this.first = event?.first ? event?.first : 0;
    this.sortField = event?.sortField ? event?.sortField : null;
    this.sortOrder = event?.sortOrder ? event?.sortOrder : 1;
    this.rows = event?.rows ? event?.rows : environment.rows;
    let body: any = this.dataForm.getRawValue();
    body.roleId = this.roleData?.roleId;
    body.sortField = event?.sortField ? event?.sortField : null;
    body.sortType = event?.sortOrder === -1 ? 'DESC' : 'ASC';
    body.pageFirstIndex = event?.first ? event?.first : 0;
    body.pageTake = event?.rows ? event?.rows : this.rows;

    if (this.search$.observed) {
      this.search$.next(body);
    } else {
      this.search$.next(body);
      this.searchInitial();
    }
  }

  selectPerson(){
    return this.utils.confirmBox('question','คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?','')
    .then((resp:any)=>{
      if(resp){
        this.close(this.selectedData)
      }
    })
  }

  cancel(){
    this.dataForm.reset()
    this.searchAction()
  }

  close(data: any){
    this.dynamicDialogRef.close(data);
  }
}
