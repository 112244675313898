import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
// import { ModalAddListNameTempComponent } from './modal-add-list-name-temp/modal-add-list-name-temp.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { DraftOrderService } from 'src/app/services/api/asm/draft-order.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { PreviewPdfDialogComponent } from 'src/app/modules/shared/preview-pdf-dialog/preview-pdf-dialog.component';
import { ReportService } from 'src/app/services/api/report.service';
import { Location } from '@angular/common';
import { DropdownAppService } from 'src/app/services/api/app/dropdown-app.service';
import { OrderDraftTempService } from 'src/app/services/api/temp/order-draft-temp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/api/app/shared.service';
import { AutoCompleteCompleteEvent, AutoCompleteOnSelectEvent } from 'primeng/autocomplete';
import { AddPersonToDraftOrderComponent } from './add-person-to-draft-order/add-person-to-draft-order.component';
@Component({
  selector: 'app-add-edit-draft-order-detail',
  templateUrl: './add-edit-draft-order-detail.component.html',
  styleUrls: ['./add-edit-draft-order-detail.component.scss'],
})
export class AddEditDraftOrderDetailComponent {
  private unsubscribe$: Subject<void> = new Subject<void>();
  search$ = new BehaviorSubject<any>(null);
  @ViewChild('pEditor') editor: any;
  rows = environment.rows;
  rowsPerPageOptions = environment.rowsPerPageOptions;
  first: number = 0;
  sortField: any = null;
  sortOrder: number = 0;
  totalRecords: number = 0;
  userProfileKey: any;
  dataForm: FormGroup;
  dataDynamicForm: FormGroup;
  modalAddListName: DynamicDialogRef | undefined;
  // @Output() sendNextStep = new EventEmitter<any>();
  dataSource: any[] = [];
  filterOrder: any[] = [];
  isStep: boolean = true;
  orderId: any = null;
  text: any = '';
  id: any = null;
  applicationId: number | undefined;
  dataDetail: any = null;
  selectedData: any = [];
  modalPreviewPdf: DynamicDialogRef | undefined;
  transactionTypeList: any[] = [];
  transactionSubTypeList: any[] = [];
  orderSignedList: any[] = [];
  orderSubjectList: any[] = [];
  filterOrderSigned: any[] = [];
  listName: any[] = [];
  dataConcatListName: any[] = [];
  menuName: string = '';
  reportFileNamme: any = null;
  dataGetById: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private utils: UtilsService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private draftOrderService: DraftOrderService,
    private sessionStorageService: SessionStorageService,
    private _location: Location,
    private dropdownAppService: DropdownAppService,
    private orderDraftTempService: OrderDraftTempService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private reportService: ReportService
  ) {
    this.dataForm = this.formBuilder.group({
      orderSubjectId: [null, Validators.required],
      transactionTypeId: [null, Validators.required],
      transactionSubTypeId: [null, Validators.required],
      commandText: [null],
      subject: [null],
      allocateText: [null],
      content: [null],
      commandDate: [{ value: null, disabled: true }],
      effectiveDate: [null],
      description: [null],
      remark: [null],
      orderStatusId: [2],
      orderSignedId: [null],
      step: [3],
      orgUnitId: [null],
      orderFrom: [null],
      statusName: [null],
      orderSignedName: [null],
      orderSignedPosition: [null],
      orderSignedCopyName: [null],
      orderSignedCopyPosition: [null],
      orderSignedPrint: [null],
      orderSetNo: [{ value: null, disabled: true }],
      orderDate: [null],
      adHocPositionShortName: [{ value: null, disabled: true }],
      orderNo: [{ value: null, disabled: true }],
      draftDate: [null],
    });
    this.dataDynamicForm = this.formBuilder.group({
      selectAll: null,
    });
  }

  ngOnInit(): void {
    this.applicationId = this.utils.getApplicationCodeByRouter(
      this.router?.url
    );
    this.menuName = this.utils.getMenuName(this.router?.url);
    this.utils.spinnerShow();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.userProfileKey = this.sessionStorageService.getUserProfile()?.userKey;
    this.chainFunctionOnInit();
  }

  chainFunctionOnInit() {
    this.getDropdown()
      .pipe(
        concatMap(() => this.setDisplay()),
        concatMap(() => this.displayOrderDetail()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          if (this.id != 'create') {
            this.getTransactionSubType();
            this.getOderSubject();
          }
          // this.getDataStampValue(null);
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  getDropdown() {
    return this.dropdownAppService
      .getTransactionTypeId(this.applicationId)
      .pipe(
        concatMap((resp: any) => {
          if (resp?.status == 200) {
            this.transactionTypeList = resp?.data;
          }
          return this.sharedService.getOrderSignedId(this.applicationId);
        }),
        switchMap((resp: any) => {
          if (resp?.data) {
            this.orderSignedList = resp.data;
          }
          return of(null);
        }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

  setDisplay() {
    if (this.id == 'create') {
      return of(null);
    } else {
      return this.orderDraftTempService.getById(this.id).pipe(
        concatMap((resp: any) => {
          if (resp?.data) {
            this.dataDetail = resp?.data;
            this.orderId = resp?.data.orderId;
            this.dataForm
              .get('draftDate')
              ?.setValue(
                resp?.data?.draftDate ? new Date(resp?.data?.draftDate) : null
              );
            this.dataForm.patchValue(resp?.data);
            this.dataGetById = resp?.data
            this.dataForm.get('orderStatusId')?.setValue(this.dataGetById?.orderStatusId ? parseInt(this.dataGetById?.orderStatusId) : null)

            //solution setValue p-editor
            const quillEditor = this.editor.quill;
            quillEditor.root.innerHTML = resp?.data?.content;

            // const findValueOrderSignedName = this.orderSignedList.find(
            //   (item: any) => item.fullName == resp?.data.orderSignedName
            // );
            // let dataSignedName = {
            //   fullName: resp?.data.orderSignedName,
            // };
            // this.dataForm
            //   .get('orderSignedName')
            //   ?.setValue(findValueOrderSignedName ?? dataSignedName);
          }
          return of(null);
        }),
        concatMap(()=>{
          this.handleOrderSigned()
          return of(null)
        }),
        catchError((err: any) => {
          throw err;
        })
      );
    }
  }

  getTransactionSubType() {
    if (this.dataForm.get('transactionTypeId')?.value >= 0) {
      if (this.id === 'create') {
        this.dataForm.get('transactionSubTypeId')?.reset();
        this.dataForm.get('orderSubjectId')?.reset();
      }
      this.transactionSubTypeList = [];
      this.orderSubjectList = [];
      let transactionTypeId = this.dataForm.get('transactionTypeId')?.value;
      this.dropdownAppService.getTransactionSubType(this.applicationId, transactionTypeId).pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.transactionSubTypeList = resp?.data;
            }
            if(this.transactionSubTypeList.length == 0){
              this.dataForm.get('transactionSubTypeId')?.clearValidators();
              this.dataForm.get('transactionSubTypeId')?.updateValueAndValidity();
              let param = {
                applicationId: this.applicationId,
                transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
                transactionSubTypeId: null,
              };
              return this.sharedService.getOderSubject(param)
            }else{
              this.dataForm.get('transactionSubTypeId')?.setValidators(Validators.required);
              this.dataForm.get('transactionSubTypeId')?.updateValueAndValidity();
              return of(null);
            }
          }),
          concatMap((resp:any)=>{
            if(resp?.data){
              if (resp?.data) {
                this.orderSubjectList = resp?.data;
                this.dataForm.get('orderSubjectId')?.setValue(this.dataGetById?.orderSubjectId)
              }
            }
            return of(null);
          }),
          takeUntil(this.unsubscribe$),
          catchError((err: any) => {
            throw err;
          })
        )
        .subscribe();
    } else {
      this.dataForm.get('transactionSubTypeId')?.reset();
      this.dataForm.get('orderSubjectId')?.reset();
      this.transactionSubTypeList = [];
      this.orderSubjectList = [];
    }
  }

  getOderSubject() {
    if (this.dataForm.get('transactionSubTypeId')?.value) {
      let param = {
        applicationId: this.applicationId,
        transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
        transactionSubTypeId: this.dataForm.get('transactionSubTypeId')?.value,
      };
      this.sharedService
        .getOderSubject(param)
        .pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.orderSubjectList = resp?.data;
            }
            return of(resp);
          }),
          takeUntil(this.unsubscribe$),
          catchError((err: any) => {
            throw err;
          })
        )
        .subscribe();
    } else {
      this.orderSubjectList = [];
      this.dataForm.get('orderSubjectId')?.reset();
    }
  }

  setOderSubject() {
    const valueOrderSubject = this.dataForm.get('orderSubjectId')?.value;
    const findValueOrderSubject = this.orderSubjectList.find(
      (item: any) => item.orderSubjectId == valueOrderSubject
    );
    this.dataForm.get('subject')?.setValue(findValueOrderSubject?.subject);
    this.dataForm.get('description')?.setValue(findValueOrderSubject?.content);
    this.dataForm.get('allocateText')?.setValue(findValueOrderSubject?.allocateText);this.reportFileNamme = findValueOrderSubject?.reportFileName;

    //solution setValue p-editor
    const quillEditor = this.editor.quill;
    quillEditor.root.innerHTML = findValueOrderSubject?.content;
  }

  // getDataStampValue(event : AutoCompleteOnSelectEvent | any) {
  //   this.dataForm.get('orderSignedId')?.setValue(event?.value?.orderSignedId);
  //   const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;
  //   this.dataForm
  //     .get('adHocPositionShortName')
  //     ?.setValue(valueOrderSigned?.adHocPositionShortName);
  //   this.dataForm.get('orderSignedName')?.setValue(valueOrderSigned?.fullName);
  // }

  // filterOrderSignedId(event: AutoCompleteCompleteEvent) {
  //   let filtered: any[] = [];
  //   let query = event.query;

  //   for (let i = 0; i < (this.orderSignedList as any[]).length; i++) {
  //     let country = (this.orderSignedList as any[])[i];
  //     if (country.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(country);
  //     }
  //   }

  //   this.filterOrderSigned = filtered;
  //   const valueOrderSigned = this.dataForm.get('orderSignedName')?.value;
  //   let findOrderSigned = this.filterOrderSigned.find(
  //     (data: any) => data.fullName === valueOrderSigned
  //   );
  //   this.dataForm
  //     .get('adHocPositionShortName')
  //     ?.setValue(findOrderSigned?.adHocPositionShortName);
  //   if (!findOrderSigned) {
  //     this.dataForm.get('adHocPositionShortName')?.reset();
  //   }
  // }

  handleOrderSigned(){
    if(this.dataForm.get('orderSignedId')?.value){
      let orderSigned = this.orderSignedList.find((el:any)=>{
        return el.orderSignedId == this.dataForm.get('orderSignedId')?.value
      })
      this.dataForm.get('orderSignedName')?.setValue(orderSigned?.fullName ? orderSigned?.fullName : null)
      this.dataForm.get('adHocPositionShortName')?.setValue(orderSigned?.adHocPositionShortName ? orderSigned?.adHocPositionShortName : null)
    }else{
      this.dataForm.get('orderSignedName')?.reset()
      this.dataForm.get('adHocPositionShortName')?.reset()
    }
  }

  openModalAddListName() {
    this.modalAddListName = this.dialogService.open(
      AddPersonToDraftOrderComponent,
      {
        header: 'เพิ่มรายชื่อ',
        width: '70%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        closable: true,
        data: {
          transactionTypeId: this.dataForm.get('transactionTypeId')?.value,
          transactionSubTypeId: this.dataForm.get('transactionSubTypeId')
            ?.value,
          dataSource: this.dataSource,
          applicationId: this.applicationId,
        },
      }
    );
    this.modalAddListName.onClose.subscribe((resp: any) => {
      if (resp) {
        this.listName = resp;
        this.dataSource = this.dataSource.concat(this.listName);
      }
    });
  }

  saveOrderDetail() {
    this.filterOrder = this.dataSource.filter(
      (data: any) => data.orderId !== this.orderId
    );
    const params = this.filterOrder.map((data) => ({
      applicationId: this.applicationId,
      orderId: this.orderId?.orderId ? this.orderId?.orderId : this.orderId,
      transactionId: data.transactionId,
      transactionDetailId: data.transactionDetailId,
      transactionTypeId: data.transactionTypeId,
      transactionSubTypeId: data.transactionSubTypeId,
      orderStatusId: 2,
    }));

    return this.orderDraftTempService.createOrderDetailList(params).pipe(
      switchMap((resp: any) => {
        return this.displayOrderDetail();
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  displayOrderDetail() {
    if (this.orderId != null) {
      this.dataForm.get('transactionTypeId')?.disable();
      this.dataForm.get('transactionSubTypeId')?.disable();
      let params = {
        orderId: this.orderId?.orderId ? this.orderId?.orderId : this.orderId,
        orderStatusId: 2,
        applicationId: this.applicationId,
      };
      return this.orderDraftTempService
        .createOrderDetailListPerson(params)
        .pipe(
          concatMap((resp: any) => {
            if (resp?.data) {
              this.dataSource = resp?.data;
            }
            return of(resp);
          }),
          catchError((err: any) => {
            throw err;
          })
        );
    } else {
      return of(null);
    }
  }

  setParam() {
    let params = this.dataForm.getRawValue();
    if (this.id == 'create') {
      params.draftDate = this.utils.convertFormatDateToParam(params.draftDate);
    } else {
      params.orderId = parseInt(this.id);
    }
    params.applicationId = this.applicationId;
    params.orderSignedPosition = this.dataForm.get(
      'adHocPositionShortName'
    )?.value;
    params.transactionTypeId = this.dataForm.get('transactionTypeId')?.value ||  this.dataForm.get('transactionTypeId')?.value == 0
      ? this.dataForm.get('transactionTypeId')?.value.toString()
      : null;
    params.step = this.dataForm.get('step')?.value?.toString();
    return params;
  }

  validateForm() {
    if (this.dataForm.invalid) {
      this.utils.alertBox('warning', 'กรุณาระบุข้อมูลให้ครบ', '');
      return true;
    }
    return false;
  }

  save() {
    let validateForm = this.validateForm();
    if (validateForm) {
      return;
    }
    let params = this.setParam();
    this.utils
      .confirmBox$('question', 'คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        switchMap((resp: any): any => {
          if (resp) {
            if (this.id == 'create') {
              return this.create(params);
            } else {
              return this.update(params);
            }
          } else {
            return of(resp);
          }
        })
      )
      .subscribe({
        next: (resp: any) => {
          if (resp) {
            this._location.back();
          }
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
        complete: () => {
          takeUntil(this.unsubscribe$);
        },
      });
  }

  create(params: any): Observable<any> {
    this.utils.spinnerShow();
    return this.orderDraftTempService.create(params).pipe(
      concatMap((resp: any) => {
        if (resp?.status == 200) {

          if (resp?.data) {
            this.id = resp?.data.orderId;
            this.orderId = resp?.data.orderId ? resp?.data.orderId : resp?.data;
            this.dataForm.get('orderSetNo')?.setValue(resp?.data.orderSetNo);
          }

          if (this.dataSource?.length != 0) {
            return this.saveOrderDetail();
          } else {
            return of(resp);
          }
        } else {
          return of(resp);
        }
      }),
      concatMap((resp: any) => {
        if (this.text === 'approve') {
          return of(null);
        } else {
          this.utils.spinnerHide();
          return this.utils.alertBox(`success`, `สำเร็จ`, ``);
        }
      }),
      concatMap((resp: any) => {
        return of(resp);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  update(params: any): Observable<any> {
    this.utils.spinnerShow();
    return this.orderDraftTempService.update(params).pipe(
      concatMap((resp: any) => {
        if (resp?.status == 200) {
          this.orderId = resp?.data.orderId ? resp?.data.orderId : resp?.data;
          let filterOrder = this.dataSource.filter(
            (data: any) => data.orderId !== this.orderId
          );
          if (filterOrder.length > 0) {
            return this.saveOrderDetail();
          } else {
            return of(resp);
          }
        } else {
          return of(resp);
        }
      }),
      concatMap((resp: any) => {
        if (this.text === 'approve') {
          return of(null);
        } else {
          this.utils.spinnerHide();
          return this.utils.alertBox(`success`, `สำเร็จ`, ``);
        }
      }),
      concatMap((resp: any) => {
        return of(resp);
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  setParamForSaveOrderDetail(data: any) {
    this.orderId = data.orderId;
    let paramSave = [];
    let param = {
      orderId: data.orderId,
      transactionId: this.id,
      status: data.status,
      step: data.step,
    };
    paramSave.push(param);
    return paramSave;
  }

  isApprove(): boolean {
    if (
      this.dataForm.get('transactionTypeId')?.value == null ||
      !this.dataForm.get('orderSubjectId')?.value ||
      !this.dataForm.get('orderSignedName')?.value ||
      this.dataSource?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  isCreateDraft(): boolean {
    if (
      (!this.dataForm.get('transactionTypeId')?.value && this.dataForm.get('transactionTypeId')?.value != 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendApprove() {
    this.text = 'approve';
    this.utils
      .confirmBox$('question', 'คุณต้องการส่งอนุมัติข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        switchMap((resp: any) => {
          let param = this.setParam();
          if (resp) {
            if (this.id === 'create') {
              return this.create(param);
            } else {
              return this.update(param);
            }
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          this.utils.spinnerShow();
          let params = {
            orderId: this.orderId?.orderId
              ? this.orderId?.orderId
              : this.orderId,
          };
          return this.orderDraftTempService.updateApprove(params);
        }),
        tap(async (resp: any) => {
          this.utils.spinnerHide();
          if (resp?.status == 200) {
            await this.utils.alertBox(`success`, `ส่งอนุมัติรายการสำเร็จ`, ``);
            this._location.back();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  setParamDelete() {
    let paramSave = [];
    for (let item of this.selectedData) {
      paramSave.push(item.transactionID);
    }
    return paramSave;
  }

  deleteAll() {
    if (this.selectedData.length <= 0) {
      this.utils.alertBox('warning', 'กรุณาเลือกข้อมูลอย่างน้อย 1 รายการ', '');
      return;
    }
    const params = this.selectedData.filter((data2:any)=> data2.orderId !== undefined).map((data: any) => ({
      orderId: data.orderId,
      transactionId: data.transactionId,
      transactionDetailId: data.transactionDetailId,
    }));
    return this.utils
      .confirmBox$('question', 'คุณต้องการลบข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        concatMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            if(this.id == 'create' || params.length == 0){
              this.dataSource = this.dataSource.filter((item) => !this.selectedData.includes(item));
              return of(true);
            }
            return this.orderDraftTempService.deleteOrderDetailList(params);
          } else {
            return of(false);
          }
        }),
        concatMap((resp: any) => {
          if (resp.status == 200) {
            return this.displayOrderDetail();
          }
          else if(resp == true){
            return of(true);
          }
          return of(null);
        }),
        concatMap((resp: any) => {
          this.utils.spinnerHide();
          if(resp){
            this.selectedData = [];
            this.utils.alertBox('success', resp.message ?? 'สำเร็จ', '');
          }
          return of(resp);
        }),
        catchError((err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
          throw err;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  deleteData(data: any) {
    let params = [
      {
        orderId: data.orderId,
        transactionId: data.transactionId,
        transactionDetailId: data.transactionDetailId,
      },
    ];

    return this.utils
      .confirmBox$('question', 'คุณต้องการลบข้อมูลใช่หรือไม่ ?', '')
      .pipe(
        concatMap((resp: any) => {
          if (resp) {
            this.utils.spinnerShow();
            if (data.orderId) {
              return this.orderDraftTempService.deleteOrderDetailList(params);
            } else {
              return of(resp);
            }
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          if (data.orderId) {
            return this.displayOrderDetail();
          } else {
            let filterDelete = this.listName.filter(
              (value: any) =>
                value.transactionDetailId != data.transactionDetailId
            );
            this.dataSource = this.dataSource.filter(
              (item) => !this.listName.includes(item)
            );
            this.dataSource = this.dataSource.concat(filterDelete);
            this.listName = this.dataSource;
            return of(null);
          }
        }),
        concatMap((resp: any) => {
          this.utils.spinnerHide();
          this.utils.alertBox('success', 'สำเร็จ', '');
          return of(null);
        }),
        catchError((err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
          throw err;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  close() {
    this._location.back();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  print() {
    this.setOderSubject();
    this.utils
      .confirmBox$('question', 'คุณต้องการพิมพ์เอกสารใช่หรือไม่ ?', '')
      .pipe(
        switchMap((resp: any) => {
          let param = this.setParam();
          if (resp) {
            if (this.id === 'create') {
              return this.create(param);
            } else {
              return this.update(param);
            }
          } else {
            return EMPTY;
          }
        }),
        concatMap((resp: any) => {
          this.utils.spinnerShow();
          this.setDisplay();
          const paramsPdf = {
            report: {
              P_ORDERID: this.orderId?.toString(),
              P_ISCOPY: 'N',
              P_ISONLYONE: `${this.dataSource?.length === 1 ? 'Y' : 'N'}`,
              IsMod: 'ORDER',
            },
            reportName: this.reportFileNamme,
            // reportName: 'dischargeOrderSingleBook',
          };
          return this.reportService.getPdf(paramsPdf);
        }),
        concatMap((resp: any) => {
          if (resp) {
            const blob = new Blob([resp], { type: 'application/pdf' });
            this.utils.spinnerHide();
            this.openPreviewPdfDialog(blob);
          }
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  openPreviewPdfDialog(blob: any) {
    this.modalPreviewPdf = this.dialogService.open(PreviewPdfDialogComponent, {
      header: 'เอกสาร',
      width: '70%',
      height: '100%',
      contentStyle: { 'max-height': '100%', overflow: 'auto' },
      baseZIndex: 10000,
      data: { menu: blob },
    });
  }
}
