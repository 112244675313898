<div class="text-purple-900 font-bold mb-3">
  <i class="fa-light fa-house pr-2"></i>
  <span>ระบบงานข้อมูล{{menuName}} / </span>
  <span>{{pathName}}</span>
</div>

<div class="card">
  <form [formGroup]="dataForm">
    <p-panel header="ค้นหา{{pathName}}" [toggleable]="true" [collapseIcon]="'pi pi-chevron-up'"
      [expandIcon]="'pi pi-chevron-down'" styleClass="mb-3">
      <div class="grid formgrid p-fluid flex">
        <div class="flex flex-wrap w-full">
          <div class="flex col-12 md:col-3 p-0">
            <div class="field col-12">
              <label>เลขที่ชุด</label>
              <input pInputText formControlName="orderSetNo" />
            </div>
          </div>
          <div class="flex flex-wrap col-12 md:col-9 p-0">
            <div class="field col-12 md:col-4">
              <label>ประเภทรายการ</label>
              <p-dropdown [options]="transactionTypeList" optionValue="value" optionLabel="text" [filter]="true"
                filterBy="name" [showClear]="true" placeholder="เลือก" (onChange)="getTransactionSubType()"
                formControlName="transactionTypeId">
              </p-dropdown>
            </div>
            <div class="field col-12 md:col-4">
              <label>ประเภทรายการย่อย</label>
              <p-dropdown [options]="transactionSubTypeList" optionValue="value" optionLabel="text"
                  [filter]="true" filterBy="name" [showClear]="true" placeholder="เลือก"  (onChange)="getOderSubject()"
                  formControlName="transactionSubTypeId">
              </p-dropdown>
          </div>
          <div class="field col-12 md:col-4">
              <label>หัวเรื่องคำสั่ง</label>
              <p-dropdown [options]="orderSubjectList" optionLabel="subject" optionValue="orderSubjectId"
                  [filter]="true" filterBy="text" appendTo="body" [showClear]="true" [autoDisplayFirst]="false"
                  placeholder="เลือก" class="w-full" styleClass="w-full"
                  [virtualScroll]="orderSubjectList.length > 6 ? true : false" [virtualScrollItemSize]="30"
                  formControlName="orderSubjectId" (onChange)="setOderSubject()">
              </p-dropdown>
          </div>
            <!-- <div class="field col-12 md:col-3">
              <label>หน่วยงานออกคำสั่ง</label>
              <p-dropdown [options]="orgUnitId1List" formControlName="orgUnitId" optionLabel="text" optionValue="value"
                placeholder="เลือก" appendTo="body" filterBy="name" [filter]="true"
                [virtualScroll]="orgUnitId1List.length > 100 ? true : false" [virtualScrollItemSize]="50"
                [showClear]="true">
              </p-dropdown>
            </div> -->
          </div>

        </div>
        <div class="flex flex-wrap w-full">
          <div class="field col-12 md:col-3">
            <label>เลขประจำตัวประชาชน</label>
            <input pInputText appInputNumber maxlength="13" formControlName="citizenId" />
          </div>
          <div class="field col-12 md:col-3">
            <label>เลขประจำตัวทหาร</label>
            <input pInputText formControlName="militaryId" />
          </div>
          <div class="field col-12 md:col-3">
            <label>ชื่อ</label>
            <input pInputText formControlName="firstName" />
          </div>
          <div class="field col-12 md:col-3">
            <label>นามสกุล</label>
            <input pInputText formControlName="lastName" />
          </div>
        </div>
        <div class="flex flex-wrap w-full">
          <div class="field col-12 md:col-3">
            <label>วันที่แจกจ่าย</label>
            <div class="p-inputgroup">
              <input type="text" pInputText class="w-full" placeholder="วัน/เดือน/ปี" [matDatepicker]="copyDate"
                (click)="copyDate.open()" formControlName="copyDate" />
              <button type="button" pButton icon="pi pi-calendar" (click)="copyDate.open()"></button>
            </div>
            <mat-datepicker #copyDate></mat-datepicker>
          </div>
          <!-- <div class="field col-12 md:col-3">
            <label>สถานะ</label>
            <p-dropdown [options]="statusList" optionLabel="text" optionValue="value" [filter]="true" filterBy="value"
              [showClear]="true" placeholder="เลือก" formControlName="status" appendTo="body">
            </p-dropdown>
          </div> -->
          <div class="field col-12 md:col-2 ml-auto">
            <label>&nbsp;</label>
            <div class="flex justify-content-end gap-3">
              <p-button label="ค้นหา" icon="pi pi-search" iconPos="left" styleClass="bg-purple-900 w-10rem"
              size="small" (onClick)="searchAction()"></p-button>
              <p-button label="ล้างข้อมูล" icon="pi pi-times" iconPos="left" [outlined]="true" severity="danger"
              size="small" styleClass="w-10rem" (onClick)="cancel()"></p-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex justify-content-end gap-3">
            <p-button label="ค้นหา" icon="pi pi-search" iconPos="left" styleClass="bg-purple-900 w-10rem"
              (onClick)="searchAction()"></p-button>
            <p-button label="ยกเลิก" icon="pi pi-times" iconPos="left" [outlined]="true" severity="danger"
              styleClass="w-10rem" (onClick)="cancel()"></p-button>
          </div> -->
    </p-panel>
  </form>

  <p-table dataKey="orderID" [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true"
    scrollHeight="450px" [showCurrentPageReport]="true" [paginator]="true" [selectionPageOnly]="true"
    [(selection)]="selectedData" [lazy]="true" (onLazyLoad)="searchAction($event)" [lazyLoadOnInit]="false"
    [showCurrentPageReport]="true" [first]="first" [sortOrder]="sortOrder" [sortField]="sortField" [rows]="rows"
    [rowsPerPageOptions]="rowsPerPageOptions" [totalRecords]="totalRecords" styleClass="p-datatable-sm"
    currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
    <ng-template pTemplate="caption">
      <div class="grid my-0">
        <div class="col-12">
          <h5 class="text-purple-900 mb-0">รายการ</h5>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr class="white-space-nowrap">
        <th pSortableColumn="orderSetNo">
          <span>เลขที่ชุด</span>
          <p-sortIcon field="orderSetNo"></p-sortIcon>
        </th>
        <th pSortableColumn="transactionTypeName">
          <span>ประเภทรายการ</span>
          <p-sortIcon field="transactionTypeName"></p-sortIcon>
        </th>
        <th pSortableColumn="transactionSubTypeName">
          <span>ประเภทรายการย่อย</span>
          <p-sortIcon field="transactionSubTypeName"></p-sortIcon>
        </th>
        <th pSortableColumn="subject">
          <span>หัวเรื่องคำสั่ง</span>
          <p-sortIcon field="subject"></p-sortIcon>
        </th>
        <!-- <th pSortableColumn="orgUnitId">
          <span>หน่วยงานออกคำสั่ง</span>
          <p-sortIcon field="orgUnitId"></p-sortIcon>
        </th>
        <th pSortableColumn="copyDate">
          <span>วันที่แจกจ่าย</span>
          <p-sortIcon field="copyDate"></p-sortIcon>
        </th>
        <th pSortableColumn="statusName">
          <span>สถานะ</span>
          <p-sortIcon field="statusName"></p-sortIcon>
        </th> -->
        <th alignFrozen="right" pFrozenColumn [frozen]="true"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data>
      <tr class="white-space-nowrap">
        <td>{{data.orderSetNo}}</td> <!-- เลขที่ชุด -->
        <td>{{data.transactionTypeName}}</td> <!-- ประเภทรายการ -->
        <td>{{data.transactionSubTypeName}}</td>
        <td>{{data.subject}}</td> <!-- คำสั่ง -->
        <td alignFrozen="right" pFrozenColumn [frozen]="true">
          <div class="flex gap-3">
            <p-button icon="pi pi-search" [rounded]="true" styleClass="bg-purple-900" size="small"
              (onClick)="openModalDetail(data.orderId)" />
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <ng-container *ngIf="dataSource.length <= 0">
        <tr>
          <td colspan="7" class="text-left bg-white">
            ไม่มีข้อมูล
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</div>
