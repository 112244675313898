<form [formGroup]="dataForm">
  <div class="card shadow-1">
    <p-panel header="ค้นหา" [toggleable]="true" [collapseIcon]="'pi pi-chevron-up'"
      [expandIcon]="'pi pi-chevron-down'" styleClass="mb-3">
      <form [formGroup]="dataForm">
        <div class="grid formgrid p-fluid mb-3">
          <div class="field col-12 md:col-4">
            <label>ประเภทรายการ</label>
            <p-dropdown [options]="transactionTypeList" optionValue="value" optionLabel="text" [filter]="true"
              filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="transactionTypeId">
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <label>ประเภทรายการย่อย</label>
            <p-dropdown [options]="transactionSubTypeList" optionValue="value" optionLabel="text" [filter]="true"
              filterBy="name" [showClear]="true" placeholder="เลือก" formControlName="transactionSubTypeId">
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <label>ชื่อรายการ</label>
            <p-dropdown [options]="transactionNameList" optionLabel="transactionName" optionValue="transactionId" [filter]="true"
              filterBy="text" appendTo="body" [showClear]="true" [autoDisplayFirst]="false" placeholder="เลือก" (onChange)="selecteValue()"
              class="w-full" styleClass="w-full" formControlName="transactionName">
            </p-dropdown>
          </div>
        </div>
        <!-- <div class="flex justify-content-end gap-3">
          <p-button label="ค้นหา" icon="fa-light fa-magnifying-glass" iconPos="left" styleClass="bg-purple-900 w-10rem"
            (onClick)="searchAction()"></p-button>
          <p-button label="ยกเลิก" icon="fa-light fa-x" iconPos="left" [outlined]="true" severity="danger"
            styleClass="w-10rem" (onClick)="clear()"></p-button>
        </div> -->
      </form>
    </p-panel>

    <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [showCurrentPageReport]="true"
      [paginator]="true" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [selectionPageOnly]="true" styleClass="p-datatable-sm"
      [(selection)]="selectedData" currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
      <ng-template pTemplate="caption">
        <div class="grid p-fluid">
          <div class="col-12 md:col-4">
            <h5 class="text-purple-900 my-0 py-0">รายการ</h5>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr class="white-space-nowrap">
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="militaryId">
            <span>เลขประจำตัวทหาร</span>
            <p-sortIcon field="militaryId"></p-sortIcon>
          </th>
          <th pSortableColumn="fullName">
            <span>ชื่อ-นามสกุล</span>
            <p-sortIcon field="fullName"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr class="white-space-nowrap">
          <td pFrozenColumn>
            <p-tableCheckbox [value]="data" *ngIf="isDuplicate(data) === false"></p-tableCheckbox>
          </td>
          <td>{{ data.militaryId }}</td>
          <td>{{ data.fullName }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <ng-container *ngIf="dataSource?.length === 0">
          <tr>
            <td colspan="5" class="text-left bg-white">ไม่มีข้อมูล</td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-center gap-3 my-3">
    <p-button label="เพิ่ม" icon="fa-light fa-plus" iconPos="left" size="small" styleClass="bg-purple-900 w-10rem"
      (onClick)="save()"></p-button>
    <p-button label="ปิด" icon="fa-light fa-x" iconPos="left" size="small" [outlined]="true" severity="danger"
      styleClass="w-10rem" (onClick)="close(false)"></p-button>
  </div>
</form>
