import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private sessionStorage: SessionStorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isAnonymous = request.headers.get('Anonymous') === 'true';
    // console.log('request:', request);
    // console.log('anonymous:', isAnonymous);
    if (!isAnonymous) {
      const token = this.sessionStorage.getAccessToken();
      if (!token) {
        return next.handle(request);
      } else {
        const authReq = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(authReq);
      }
    } else {
      return next.handle(request);
    }
    // if (!this.isAnonymous(request.url)) {
    //   const token = this.sessionStorage.getAccessToken();
    //   if (token == null || token === undefined) {
    //     return next.handle(request);
    //   }
    //   const authReq = request.clone({
    //     headers: request.headers.set('Authorization', `Bearer ${token}`),
    //   });
    //   return next.handle(authReq);
    // } else {
    //   return next.handle(request);
    // }
  }

  // private isAnonymous(url: string) {
  //   const anonymousRoutes = this.anonymousRoutes;
  //   for (let i = 0; i < anonymousRoutes.length; i++) {
  //     if (url.match(anonymousRoutes[i]) != null) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // private anonymousRoutes: string[] = ['api/Auth/Login'];
}
